/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable declaration-no-important */
/* stylelint-disable rule-empty-line-before */
div#popup-root {
    .ReturnsForm_Popup_wrap-overlay {
        background: rgba(0, 0, 0, 0.4);
    }
    .ReturnsForm_Popup_wrap-content {
        max-height: 90%;
        overflow-y: auto;
        max-width: 720px !important;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        @include mobile {
            padding: 12px !important;
        }
        .cross_subs_popup {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            button.Header-Button_type_close {
                position: static;
                background: #111;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                padding: 3px;
                .CloseIcon {
                    fill: #fff;
                }
                &:hover {
                    background: #333;
                }
            }
        }
    }
}
.ReturnsForm {
    div.Loader {
        left: -29px;
        right: -29px;
        top: -29px;
        bottom: -29px;
        z-index: 99;
    }
    .prevStep {
        margin-left: 0;
    }
    .prevBtn, .Contact-Button {
        margin-top: 20px;
    }
    .FieldWrapMain {
        display: none;
        > p.Field-Message {
            display: block;
        }
    }
    .proTopSec {
        width: 100%;
        display: flex;
        color: #53565A;
        font-family: 'Regular-Bold';
        font-size: 16px;
        text-transform: uppercase;
        margin: 20px 0 8px;
        .qty {
            margin-left: auto;
        }
    }
    input[type="checkbox"]:checked {
        ~ .FieldWrapMain {
            display: block;
            margin-top: 20px;
        }
        ~ .prodWrap {
            opacity: 1 !important;
        }
    }
    input[type="file"] {
        border: 1px dashed var(--input-border-color);
        width: 60%;
    }
    textarea {
        border: 2px solid var(--button-border) !important;
        &#topic {
            height: 130px;
        }
    }
    .productSec {
        border: 1px solid #ddd;
        padding: 10px 15px;
        margin-bottom: 15px;
        width: 100%;
        border-radius: 5px;
        input[type="checkbox"] {
            position: absolute;
            top: 32px;
            z-index: 1;
            ~ .prodWrap {
                opacity: .5;
            }
        }
    }
    .css-b62m3t-container {
        background: #fff;
        z-index: 9;
        width: 60%;
    }
    .prod {
        &Wrap {
            display: flex;
            width: 100%;
            padding-left: 30px;
            align-items: center;
            .proQnt {
                margin-left: auto;
            }
            .pro {
                &Imag {
                    width: 80px;
                    min-width: 80px;
                    margin-right: 18px; 
                    img {
                        width: 100%;
                    }
                }
                &Name {
                    margin-right: 30px;
                }
            }
        }
    }
    .proName {
        h3 {
            margin: 0;
            font-family: "regular-bold",sans-serif;
            font-weight: 300;
            font-size: 17px;
            color: #232323;
            text-transform: uppercase;
            line-height: 1.2;
        }
        p {
            margin: 0;
            font-family: "regular-book", sans-serif;
            line-height: 1.2;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
    .proQnt {
        input[type="number"] {
            padding: 0;
            pointer-events: unset;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
            }
        }
    }
    .FieldTopWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .FieldBox {
            width: 48%;
            > p.Field-Message {
                display: block;
            }
        }
        .textAreaBox {
            &.mb_20 {
                margin-bottom: 20px;
            }
            > p.Field-Message {
                display: block;
            }
        }
    }
    label.title {
        margin: 10px 0 10px;
    }
    .FieldLimit {
        font-style: italic;
        font-size: 13px;
        margin: 4px 0 0;
    }
    .Field {
        input[type="email"],
        input[type="text"] {
            width: 100%;
        }
    }
    padding: 5px 10px;
    .FieldWrap {
        margin-bottom: 15px;
        > label {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 15px;
        }
        input[type="file"] {
            border: 1px dashed #aaa;
        }
    }
    .radio_wrap_container {
        display: flex;
        gap: 15px;
        flex-direction: row;
    }
    .radio_wrap {
        display: flex;
        margin-bottom: 5px;
        label {
            margin-left: 10px;
        }
    }
    h2, h3 {
        width: 100%;
        margin: 0 0 10px;
        font-family: Intro;
        font-size: 35px;
        line-height: 1.1;
        @include mobile {
            font-size: 28px;
        }
    }
    h3 {
        font-size: 25px;
        margin: 30px 0 15px;
    }
    p {
        color: #53565A;
        font-size: 15px;
    }
    .decp {
        color: #53565A;
        font-family: 'Regular-Bold';
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
        width: 100%;
    }
    .finalBtnBottom {
        margin-top: 30px;
        display: flex;
    }
    .finalStep .decp {
        margin-bottom: 30px;
    }
    .FieldMessage .FieldWrap > p.Field-Message {
        display: block;
    }
    .Field-Label {
        font-size: 15px;
    }
    .finalStep .FieldBox {
        margin-bottom: 30px;
        > p.Field-Message {
            display: block;
        }
    }   
    .css-13cymwt-control, .css-t3ipsp-control {
        height: 40px;
        border: 2px solid var(--button-border);
        max-height: 40px;
    }    
    .css-13cymwt-control .css-1fdsijx-ValueContainer, .css-1fdsijx-ValueContainer {
        height: 35px;
    }
    .css-qbdosj-Input,
    .css-19bb58m {
        height: 28px;
    }
    .css-1dimb5e-singleValue {
        top: 5px;
    }
    .cnt_vehicle_wrap, 
    .textAreaBox {
        width: 100%;
        label {
            margin-bottom: 5px;
        }
        input {
            height: auto;
        }
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        margin-top: 20px;
    }
    .cnt_vehicle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .Field {
            width: 23%;
            margin-top: 0;
            @include mobile {
                width: 48%;
                margin-bottom: 15px;
            }
        }
    } 
    .differentVehicle {
        width: 100%;
        display: flex;
        margin: 18px 0;
        cursor: pointer;
        p {
            margin: 0;
        }
        input[type="checkbox"] {
            margin-right: 8px;
            height: auto;
        }
    }    
    .FieldBoxVeh {
        width: 100%;
        display: none;
        justify-content: space-between;
        flex-wrap: wrap;
        &.custom-vehicle-active {
            display: flex;
        }
        .Field {
            width: 32%;
            margin-top: 0;
            @include mobile {
                width: 48%;
                margin-bottom: 15px;
            }
        }
    }
    .productWrap {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        margin-top: 12px;
        img {
            width: 70px;
            height: 52px;
            object-fit: contain;
        }
        .Field {
            padding: 0 12px;
        }
        input[type="checkbox"] {
            margin-right: 8px;
        }
    }
    .prodTextWrap {
        padding-left: 30px;
        color: #333;
        font-family: 'Regular-Bold';
        p {
            text-transform: uppercase;
            margin: 0;
            &.title {
                font-weight: 600;
                font-size: 17px;
            }
        }
    }
    .list-heading {
        font-family: 'Regular-Bold';
        text-transform: uppercase;
        font-size: 17px;
        color: #666;
    }
    .radio_wrap.textAreaBox .Field {
        margin: 0 0 0 10px;
    }
    .workshopLoc {
        margin: 30px 0;
        max-width: 340px;
        .Field {
            margin-top: 0;
        }
    }
    .selAddressWrap {
        padding-right: 50px;
        max-width: 250px;
        p {
            margin: 0;
            &.title {
                font-weight: 600;
            }
        }
    }
    .selAddress {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .decp.mb_20 {
        margin-bottom: 35px;
    }
    h3.title_h4 {
        font-size: 23px;
    }
    .strong {
        font-weight: 600;
        color: #444;
    }
    .itemText {
        font-size: 18px;
        line-height: 1.5;
        padding: 30px 0;
    }
}
