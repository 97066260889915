/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

//  div div.Loader {
//     position: fixed;
//  }

.GearzettePage {
    &-Content {
        width: 100%;
        padding-inline: 15px;

        @include mobile {
            max-width: 460px;
            margin-inline: auto;
        }

        @include desktop {
            max-width: 1170px;
            margin-inline: auto;
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
        }

        section.Breadcrumbs {
            margin: 0 0 10px;
            font-size: 12px;

            .ContentWrapper {
                padding: 0;

                nav {
                    border: none;
                    padding: 0;
                }

                .Breadcrumbs-List {
                    padding: 0;
                }

                li.Breadcrumb > span {
                    color: #666;
                }
            }
        }

        .PostDetails {
            margin-block-start: 15px;

            h1 {
                font-family: Intro, sans-serif;
                font-size: 32px;
                line-height: 1.2;
                margin: 25px 0 5px 0;
                color: #303030;

                @include mobile {
                    font-size: 26px;
                }
            }

            a {
                color: black !important;
                text-decoration: underline;
            }

            p {
                font-size: 16px;
                color: #333;
                line-height: 1.42;

                @include mobile {
                    margin-inline: 0;
                }

                &.ql-align-justify {
                    text-align: justify;
                }
            }

            ul {
                list-style: unset;
                padding-left: 30px;
            }

            & > ul {
                li {
                    font-size: 16px;
                    line-height: 1.5;

                    &::before {
                        display: none;
                    }
                }

                @include mobile {
                    margin-inline: 0;
                }
            }

            picture {
                display: block;
            }

            .ql-size-small {
                font-size: 11px;
                color: #808080;
            }

            .published_date {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
        
        .post-skips {
            border-top: 1px solid #cccccc;
            padding-block-start: 20px;
            font-size: 16px;
            font-style: normal;
            margin-block: 20px 25px;
            justify-content: space-between;
            display: none;
        }

        .CloseDetails {
            margin-block-end: 20px;
            position: absolute;
            right: -255px;
            inset-block-start: 0;
            z-index: 1;

            @media (min-width: 1600px) {
                right: -355px;
            }

            @include mobile {
                position: static;
            }
        }

        .Image {
            padding: 0 !important;
            height: auto;
            line-height: 0;
            margin-block-start: 8px;

            img.Image-Image {
                position: static;
                max-width: 100%;
                height: auto !important;
            }
        }

        .GearzettePage-Wrapper {
            display: flex;
            flex-wrap: wrap;

            .Gearzette_top,
            .Gearzette-link {
                width: 100%;
            }

            .Gearzette_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: url(../../images/front-runner-gearzette-banner.jpg)
                    no-repeat center -32px;
                background-size: cover;
                width: 100%;
                padding: 10px 13px;
                margin-block-start: 30px;
                box-sizing: border-box;
                overflow: hidden;
                &_h1 {
                    font-family: "Regular-Bold", sans-serif;
                    font-size: 50px;
                    line-height: 0.8;
                    margin-block-end: 2px;
                    color: #ffffff;
                    text-transform: uppercase;
                }
                &_h2 {
                    font-family: "Regular-Book", sans-serif;
                    color: #ffffff;
                    font-size: 20px;
                    line-height: 1;
                    margin: 0 0 8px;
                    font-weight: normal;
                    text-transform: uppercase;
                }

                .blog--logo img {
                    max-height: 50px;
                }

                @include mobile {
                    .blog--logo {
                        display: none;
                    }

                    background-size: auto;
                    text-align: center;
                }
            }

            .Gearzette_left {
                flex: 1;
            }

            .Gearzette_right {
                width: 280px;
                padding-left: 45px;

                @media (min-width: 1600px) {
                    width: 355px;
                }
            }

            @include mobile {
                display: block;

                .Gearzette_right {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }

        .fr-line-header {
            border-bottom: 2px solid #444444;
            font-size: 16px;
            line-height: 1.4;
            margin-block-start: 48px;
            font-family: "regular-bold", sans-serif;

            span {
                color: #ffffff;
                background: #444444;
                padding: 5px 10px;
                text-transform: uppercase;
            }
        }

        .PostItem {
            display: flex;
            margin-block-end: 50px;

            .fr-post-list-featured-image {
                width: 30%;
                padding-right: 20px;
            }

            .fr-post-list-content {
                width: 67%;
                flex: 1;

                .lp-post-item-header {
                    color: #444;
                    font-size: 26px;
                    line-height: 1.2;
                    text-transform: none;
                    font-family: "intro", arial, sans-serif;
                    margin: 15px 0 10px 0;
                    cursor: pointer;

                    &:hover {
                        color: var(--text-red-color);
                    }
                }

                .lp-short-description {
                    font-size: 16px;
                    color: #222222;
                    line-height: 1.5;
                    padding-inline-end: 15px;

                    .read_more {
                        color: var(--text-red-color);
                        text-transform: uppercase;
                        font-family: "Regular-bold", sans-serif;
                        font-size: 15px;
                        border-bottom: 1px dotted rgba(222, 47, 12, 0.4);
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
            }

            @include mobile {
                display: block;

                .fr-post-list-featured-image,
                .fr-post-list-content {
                    width: 100%;
                    padding: 0;
                }

                .fr-post-list-featured-image {
                    margin-block-end: 15px;
                }
            }
        }

        .recent_posts {
            .PostItem {
                border-bottom: 1px dashed #e2e2e2;
                margin-block-end: 15px;
                padding-block-end: 10px;

                .fr-post-list-featured-image {
                    width: 70px;
                    padding-right: 10px;
                }

                .fr-post-list-content {
                    flex: 1;
                }

                h4.lp-recent-post-item-header {
                    text-transform: uppercase;
                    color: #333;
                    line-height: 1.3;
                    cursor: pointer;

                    &:hover {
                        color: var(--text-red-color);
                    }
                }
            }

            > div:last-child .PostItem {
                border-bottom: 0;
                padding-block-end: 0;
                margin-block-end: 0;
            }
        }

        .Categories .GearCategoryItem-Link {
            border-bottom: 1px dashed #e2e2e2;
            margin-block-end: 15px;
            padding-block-end: 10px;
            display: block;
            color: #333;

            &:hover {
                color: var(--text-red-color);
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        .WatchNow {
            margin-block-end: 30px;

            .fr-tune-into-tv-wide a {
                padding: 5px 10px;
                margin-block-start: 5px;
                background: #444444;
                color: #ffffff;
                display: block;
                font-family: "regular-bold", arial, sans-serif;
                font-size: 16px;
                text-align: center;
                line-height: 1.4;
            }

            .WatchNowVideo iframe {
                display: block;

                @media (min-width: 1600px) {
                    height: 180px;
                }
            }
        }

        .social-icons .icons {
            .social-link {
                display: block;
                padding: 0 10px;
                box-sizing: border-box;
                margin-block-end: 5px;
                background: #ccc;
                height: 30px;
                color: #fff;
                text-transform: capitalize;
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
            }

            .facebook .social-link {
                background-color: #3b5998;
            }

            .pinterest-p .social-link {
                background-color: #e60023;
            }

            .youtube .social-link {
                background-color: #bb0000;
            }

            .instagram .social-link {
                background-color: #e95950;
            }
        }

        .blog-search {
            display: flex;
            margin-block-start: 30px;

            input.input-text {
                height: 32px;
                font-size: 13px;
            }

            button.btn {
                height: 32px;
            }
        }

        ~ .Breadcrumbs {
            display: none;
        }
    }
}

ol > li::before {
    display: none;
}
