/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.SearchPageOverlay {
    padding-block-start: 30px;

    @include mobile {
        padding: 15px 15px 0;
        margin-top: var( --header-height-mb);
    }

    .SearchField {
        position: static;

        .ais-SearchBox-form {
            margin-block-end: 20px;

            .ais-SearchBox-input {
                border: 1px solid #ccc;
                border-radius: 25px;
                width: 100%;
                padding-left: 45px;
            }
        }

        .ais-Hits {
            border: none;
        }

        &_isActive::after {
            display: none;
        }

        &-Results {
            display: flex;

            &-Container .ais-Hits {
                max-height: unset;
            }

            &-Inner {
                background: #f5f5f5;
                border: 1px solid #e2e2e2;
                padding: 15px;

                @include mobile {
                    max-height: calc(100vh - 50px);
                    overflow-y: auto;
                }

                h4 {
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 400;
                    padding-block-end: 10px;
                }

                .ais-RefinementList-labelText, select {
                    text-transform: uppercase;
                    font-size: 15px;
                    color: #444;

                    @include mobile {
                        font-size: 17px;
                        line-height: 1.5;
                    }
                }
            }

            @include mobile {
                flex-direction: column;
            }

            aside {
                display: block;
                background-color: transparent;
                padding: 0;
                font-family: regular-bold, sans-serif;

                h3 {
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: 400;
                    padding: 5px 10px;
                    margin: 0;

                    @include mobile {
                        padding: 12px 10px;
                        text-align: center;
                    }
                }
            }

            &-Btn {
                display: none;
            }

            .ais-Pagination {
                display: block;
                padding-block-end: 40px;

                &-link {
                    display: block;
                    padding: 7px 13px;
                    color: unset;
                    border: 1px solid var(--color-dark-gray);
                    border-radius: 2px;
                    font-size: 16px;

                    &:hover {
                        color: var(--text-red-color);
                        border-color: var(--text-red-color);
                    }

                    &--selected {
                        background-color: var(--text-red-color);
                        border-color: var(--text-red-color);
                        color: #fff !important;
                    }

                    @include mobile {
                        padding: 3px 8px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
