/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.payment-success {
    padding: 20px 15px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;

    .suc_test {
        margin-block-start: -130px;
        font-size: 15px;
    }

    @include mobile {
        max-width: 490px;
        margin-inline: auto;  
    }

    h2 {
        font-size: 25px;
        margin: 0 0 8px;
    }

    h3 {
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        margin: 10px 0 10px;
    }    

    hr {
        border-bottom: 1px solid #ddd;
        margin-block-end: 15px;
    }

    .order-inf {
        background: #fdefd6;
        padding: 10px;
        margin-block-end: 20px;

        &>div {
            margin-block-end: 20px;

            &>div {
                margin-block-end: 10px;
            }
        }
    }

    .item-ord {
        border: 1px solid #ddd;
        padding: 10px;

        h4 {
            font-size: 22px;
            font-weight: normal;
            margin-block-end: 10px;
        }

        .item,.item-bt {
            padding: 10px 8px;

            &>div {
                margin-block-end: 8px;

                &>div {
                    margin-block-end: 8px;
                }
            }

            &:nth-child(even) {
                background: #f6f6f6;
            }
        }

        .item-bt {
            background: #f6f6f6;

            &>div {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &~ .Breadcrumbs {
        display: none;
    }

    &~ section.Header-Wrapper {
        header {
            .Header-LogoWrapper, button {
                @include mobile {
                    display: none;
                }                
            }
        }
    }
}
