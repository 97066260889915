/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.MyAccount {
    .my-quotation-list {
        .StockImage {
            width: 50px;
            min-width: 50px;
            margin-inline-end: 10px;
            border-bottom: none;
        }

        .view_more span {
            cursor: pointer;

            &:hover {
                color: var(--text-red-color);
            }
        }

        .quotation_wrap {
            color: #444;
            border-bottom: none;

            .des {
                margin-block-end: 5px;

                @include desktop { 
                    font-weight: 600;
                }
            }

            .ProductCard-Price span {
                padding: 0;
                color: var(--text-red-color);
            }

            .ProductCard-Price .price-box-text {
                display: block;
                font-size: 12px;
                font-weight: normal;
                margin-block-start: 2px;
                color: #555;
            }

            .ProductCard-Price {
                margin-block-end: 8px;
            }

            .notify_status {
                margin-block-end: 3px;
            }

            .quotation_on {
                margin-block-end: 3px;
            }

            .view_more {
                >span:first-child {
                    padding-left: 0;
                }

                >span:hover {
                    color: var(--text-red-color);
                }
            }
        }

        @media (max-width:1100px) {
            thead {
                display: none;
            }

            tr td {
                display: block;
                padding: 10px 0;

                &::before {
                    padding-inline-end: 10px;
                    content: attr(data-th) ": ";
                    display: inline-block;
                    color: #000000;
                    font-weight: 700;
                }

                &.view_more {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .my-quotation-details {
        .Quot_Num {
            font-family: regular-bold, sans-serif;
            font-size: 28px;
            color: #666;
            margin-block-end: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .status {
                border: 2px solid #cccccc;
                border-radius: 3px;
                display: inline-block;
                padding: 7px 10px;
                font-size: 12px;
                font-family: sans-serif;
                color: #303030;
                text-transform: uppercase;
                margin-left: 15px;
            }

            @include mobile {
                display: block;

                .status {
                    margin: 10px 0 0;
                    display: table;
                }
            }

            .button-right {
                margin-left: auto;

                .Accept {
                    margin-left: 12px;

                    &[disabled] {
                        pointer-events: none;
                    }
                }

                @media (max-width:992px) {
                    margin: 10px 0 0;
                    width: 100%;
                }
            }
        }

        .qt_exp {
            margin-left: 10px;
            color: var(--text-red-color);
        }

        .quote-date-wrap {
            display: flex;
            border-bottom: 1px solid #e2e2e2;
            padding: 10px 0;
            margin: 15px 0;
            font-size: 16px;
            color: #303030;

            .quotation_expire {
                font-size: 12px;
                margin-left: 15px;
                padding-block-start: 4px;
            }

            @include mobile {
                flex-wrap: wrap;

                .quotation_expire {
                    margin: 5px 0 0;
                }
            }
        }

        .quotation-total {
            background: #f5f5f5;
            text-align: end;
            padding: 5px 30px;

            @include mobile {
                padding: 5px 0;
            }

            &>div {
                padding: 8px 15px;
            }

            .rlt {
                display: inline-block;
                vertical-align: top;
                min-width: 120px;
            }
        }

        @media (max-width:1100px) {
            thead {
                display: none;
            }

            tr td {
                display: block;
                padding: 10px 0;

                &::before {
                    padding-inline-end: 10px;
                    content: attr(data-th) ": ";
                    display: inline-block;
                    color: #000000;
                    font-weight: 700;
                }
            }
        }

        & ~ .my-quotation-list {
            display: none;
        }
    }
}
