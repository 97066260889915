/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CompanyCredit {
    .Credit {
        .Balance {
            tr {
                background: transparent;
                display: block;
                margin-block-end: 10px;
                border: none;

                @include desktop {
                    display: inline-block;
                    margin-inline-end: 7rem;
                }

                th, td {
                    display: block;
                }

                th {
                    font-size: 16px;
                    font-family: 'regular-book', sans-serif;
                    color: var(--text-dark-gray-color);
                    font-weight: normal;
                    padding: 0;
                    margin-block-end: 6px;
                }

                td {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #303030;
                    padding: 0;
                }
            }
        }        
    }

    @include desktop {
        table {
            td, th {
                font-size: 12px;
                padding: 8px;
            }
        }
    } 

    @include mobile {
        table.cmCrTable {
            thead {
                display: none;
            }    

            tr {
                background: transparent;
                margin-block-end: 20px;
                display: block;

                td {
                    font-size: 13px;
                    display: flex;

                    &::before {
                        content: attr(data-th) ': ';
                        min-width: 150px;
                        padding-inline-end: 10px;
                        font-weight: 600;
                    }

                    &:nth-child(even) {
                        background-color: var(--table-secondary-color);
                    }  
                }
            }
        }
    }
}
