/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable block-closing-brace-empty-line-before */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable indentation */
/* stylelint-disable declaration-no-important */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable rule-empty-line-before */

.staticWhyUs {
    &Wrap {
      background: #222;
      text-align: center;
      padding: 50px 0;
      h2 {
          color: #fff;
          font-family: "intro", sans-serif;
          font-size: 34px;
          line-height: 1.1;
          margin-bottom: 40px;
          @include mobile {
              font-size: 30px;
          }
      }
      .whyFrText {
          color: #fff;
          text-transform: uppercase;
          font-family: "regular-bold", sans-serif;
          font-size: 28px;      
      }
    }
    &Box {
      ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          li {
              color: #fff;
              padding: 0 20px;
              display: flex;
              align-items: center;
              // flex: 1;
              min-width: 200px;
              .rlText {
                  font-family: "regular-bold", sans-serif;
                  font-size: 24px;
                  line-height: 1.1;
                  text-transform: uppercase;
                  text-align: left;
                  max-width: 250px;
              }  
              .lfImg {
                  margin-right: 15px;
                  background-color: var(--text-red-color);
                  width: 56px;
                  aspect-ratio: 1;
                  flex: none;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  border-radius: 999px;
                  svg {
                      font-size: 30px;
                      fill: #fff;
                  }
              }
              @media (max-width: 1200px) {
                  flex: none;
                  min-width: auto;
                  margin-bottom: 25px;
              }
              @include mobile {        
                  width: 85% !important;
                  padding: 0 5px;
                  .rlText {
                      font-size: 23px;
                  }
                  &:last-child {
                      margin-bottom: 0;
                  }
              }
          }
          &.fourItem {
              li {
                  @media (max-width: 1200px) {
                      width: 50%;
                  }
              }
          }
          @media (max-width: 1200px) {
              flex-wrap: wrap;
          }
      }
    }
}
