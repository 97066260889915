/* stylelint-disable declaration-no-important */

.btn {
    @include button;
    
    text-align: center;
}

.btn-primary {
    background-color: var(--text-red-color);
}

body button * {
    color: inherit;
}

body .Button {
    font-family: regular-bold, sans-serif;
    line-height: 30px;
    border: 2px solid rgba(0,0,0,.2);
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--text-red-color);
    transition: all .24s linear 0s;
    color: var(--btn-text-color);
    padding: 0 12px;
    text-transform: uppercase;
    cursor: pointer;
    height: auto;
    width: auto;
    border-radius: 999px;

    &:hover, &:focus {
        border-radius: 999px !important;
    }

    &:hover, &:not([disabled]):hover, &:not([disabled]):focus {
        background-color: var(--text-red-hover-color);
        text-decoration: none;
        color: var(--btn-text-color);
        height: auto;
        padding: 0 12px;
    }

    * {
        color: inherit;
    }

    &_likeLink {
        font-family: helvetica neue,Helvetica,sans-serif;
        line-height: 1.2;
        background: transparent !important;
        border: none;
        color: var(--text-light-black-color) !important;
        text-transform: unset;
        padding: 0 !important;
    
        &:hover {
            color: var(--text-red-color) !important;
        }
    }
}
