/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable at-rule-empty-line-before */

.SearchField {
    .Loader {
        &Inner {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &Image {
            width: 50px;
            margin-right: 20px;
        }
        &Text {
            margin-bottom: 7px;
        }
        &Wrapper {
            margin-bottom: -2px;
            background: #fff;
            border-top: 1px solid #ddd;
            padding-top: 10px;
            @include mobile {
                padding: 15px 15px 0;
                border: none;
            }
            .TextPlaceholder {
                display: block;
                height: 10px;
                overflow: hidden;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .price-box-text {
        font-size: 10px;
        color: #444;
        line-height: 1.2;
    }
    .priceDisWrap {
        display: flex;
        align-items: center;
        .discountLable {
            font-size: 12px;
            background-color: #222;
            padding: 0 4px;
            border-radius: 5px;
            text-transform: uppercase;
            margin-left: 5px;
            color: #fff;
            font-family: regular-bold,sans-serif;
        }
    }
    .price-amznstyle {
        color: var(--text-red-color);
        font-size: 20px;
        font-family: regular-bold, sans-serif;
        margin-top: 5px;
        .price-symbol {
            top: -6px;
            right: 1px;
            font-size: 14px;
            font-weight: 400;
        }
        .price-whole {
            font-size: 24px;
            font-weight: 400;
        }
        .price-decimal {
            top: -6px;
            left: 1px;
            font-size: 14px;
            font-weight: 400;
        }
        .offscreen {
            position: absolute !important;
            left: 0 !important;
            bottom: -1px !important;
            z-index: -1 !important;
            opacity: 0;
        }
        &Price-max {
            .price-container {
                display: none;
            }
            
            .offscreen {
                font-family: regular-bold, sans-serif;
                color: #888;
                font-size: 16px;
                text-decoration: line-through;
                margin-left: 8px;
            }
        }
    }
    &-Results {
        position: relative;
        margin-top: 0px;
        flex-direction: row;
        background-color: #fff;
        border-radius: 0 0 25px 25px;
        display: none;
        @include desktop {
            max-height: calc(100vh - 160px);
            overflow-y: auto;
        }
        @include mobile {
            position: absolute;
            left: -15px;
            right: -15px;
            margin-top: 6px;
            border-radius: 0;
            display: block;
        }
        aside {
            width: 300px;
            background-color: #e9e9e9;
            padding: 10px 15px;
            display: none;
        }
        .ais-Pagination {
            display: none;
        }
        &-Btn.Button {
            margin: 10px 0 15px 0px;
            @include mobile {
                margin: 10px auto 36px;
                display: block;
                text-align: center;
                width: 92%;
            }
        }
        &-Inner {
            &:empty ~ .LoaderWrapper {
                display: none;
            }
        }
        &-Container {
            width: 100%;
            margin-top: 0;
            padding: 0 15px;
            display: flex;
            flex-direction: column-reverse;
            .noResult {
                padding: 10px 0 12px 20px;
            }
            .priceFrom {
                font-size: 12px;
                color: var(--text-gray-color);
            }
            @include mobile {
                padding: 0;
                // height: calc(100vh - 99px);
                flex-direction: column;
            }
            .ais-Hits {
                max-height: 700px;
                overflow-y: auto;
                @include mobile {
                    // max-height: calc(100vh - 159px);
                    max-height: calc(100vh - 180px);
                    border-top: none !important;
                }
                li.ais-Hits-item {
                    .SearchField {
                        &-Item {
                            display: flex;
                            color: unset;
                            padding: 10px;
                            border-radius: 4px;
                            &:hover {
                                background: var(--light-bg-color);
                            }
                            .Image {
                                min-width: 80px;
                                max-width: 80px;
                                height: 60px;
                                padding: 0;
                                margin-right: 10px;
                                img {
                                    border-radius: 4px;
                                }
                            }
                        }
                        &-Title {
                            font-family: regular-bold, sans-serif;
                            font-size: 16px;
                            text-transform: uppercase;
                            line-height: 1.2;
                        }
                        &-Additionals {
                            line-height: 1.2;
                        }
                        &-Brand,
                        &-Sku {
                            font-size: 11px;
                            color: var(--text-gray-color);
                            text-transform: uppercase;
                        }
                        &-Price {
                            color: var(--text-red-color);
                            font-size: 20px;
                            font-family: regular-bold, sans-serif;
                            margin-top: 4px;
                        }
                    }
                    .separator {
                        margin: 0 5px;
                        color: #ccc;
                        font-size: 12px;
                        &::before {
                            content: '|';
                        }
                    }
                }
            }
        }
    }

    .ais {
        &-SearchBox {
            position: relative;
            display: flex;
            button.cancel-button {
                display: none;
                width: 25px;
                text-align: right;
                font-size: 20px;
                @include mobile {
                    display: inline-block;
                }
            }
        }
        &-SearchBox-form {
            width: 100%;
            .ais-SearchBox-input {
                @include mobile {
                    font-size: 14px;
                    padding: 2px 5px 2px 40px;
                    background-color: #ffffff;
                    border-radius: 999px;
                    border: 1px solid var(--input-border-color);
                    width: 100%;
                    height: 38px;
                    &[value=""] {
                        ~ button.ais-SearchBox-submit {
                            display: block;
                        }
                    }
                }
            }
        }
        &-SearchBox-submit {
            position: absolute;
            left: 0;
            top: 8px;
            @include mobile {
                left: unset;
                right: 15px;
                top: 11px;
                display: none;
                svg {
                    width: 13px;
                    height: 13px;
                    fill: var(--text-gray);
                }
            }
        }
        &-SearchBox-reset {
            position: absolute;
            right: 20px;
            top: 12px;
            padding: 5px;
            @include mobile {
                right: 15px;
                top: 10px;
                padding: 0;
            }
        }
        &-Hits {
            background-color: #fff;
            padding: 5px 0 0;
            top: 0;
            border-top: 1px solid #ddd;
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    &:empty {
                        display: none;
                    }
                    &::before {
                        display: none;
                    }
                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }
        &-RefinementList {
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            &-checkbox {
                min-width: auto;
                min-height: auto;
                -webkit-appearance: checkbox;
                appearance: checkbox;
                caret-color: var(--primary-base-color);
                margin-right: 8px;
            }
            &-count {
                background-color: var(--text-gray);
                color: #fff;
                padding: 2px 5px;
                border-radius: 999px;
                font-size: 12px;
            }
        }
        &-ToggleRefinement {
            &-checkbox {
                min-width: auto;
                min-height: auto;
                -webkit-appearance: checkbox;
                appearance: checkbox;
                caret-color: var(--primary-base-color);
                margin-right: 8px;
            }
        }
        &-MenuSelect {
            select {
                -webkit-appearance: auto;
                appearance: auto;
                background: #fff !important;
                padding: 5px 8px;
                width: 100%;
                margin-bottom: 4px;
                border-radius: 4px;
            }
        }
        &-Pagination {
            &-list {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: flex-start;
                gap: 5px;
            }
            &-item {
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 20px;
                &::before {
                    display: none;
                }
            }
        }
        &-Highlight-highlighted {
            background-color: yellow;
        }
    }

    // &-SearchWrapper {
    //     margin-top: 28px;
    // }

    @include mobile {
        .search-wrapper {
            background: var(--header-background);
        }
        &-Input,
        &-Placeholder {
            font-size: 14px;
            padding: 10px 14px;
            background-color: var(--search-field-background);
            border-radius: 999px;
            border: 1px solid var(--input-border-color);
            display: none;
        }
        svg.fa-magnifying-glass {
            position: absolute;
            left: 15px;
            top: 13px;
            z-index: 1;
            font-size: 14px;
        }
        &-SearchIcon {
            right: 6px;
            .SearchIcon {
                width: 16px;
                fill: var(--text-gray);
            }
        }
        &-CloseIcon {
            display: none;
        }
        &.SearchField_isVisible {
            // margin-left: -25px;
            margin-top: 0;
        }
        &.SearchField_isActive {
            margin-left: auto;
        }
    }

    // input[type="search"]::-webkit-search-decoration,
    // input[type="search"]::-webkit-search-cancel-button,
    // input[type="search"]::-webkit-search-results-button,
    // input[type="search"]::-webkit-search-results-decoration {
    //     -webkit-appearance:none;
    // }

    input[type="search"]::-webkit-search-cancel-button {
        /* Remove default */
        -webkit-appearance: none;
        background: url(../../images/close.svg);
        background-repeat: no-repeat;
        width: 18px;
        height: 16px;
        cursor: pointer;
    }

    @include desktop {
        position: absolute;
        margin: 0;
        z-index: 11;
        // left: unset;
        top: 52px;
        right: 130px;
        width: auto;
        transition: all 0.35s ease-in-out;
        left: calc(100% - 400px);
        max-width: 100%;
        @media (max-width: 1400px) {
            left: calc(100% - 330px);
        }
        @media (max-width: 1200px) {
            right: 65px;
            left: calc(100% - 270px);
        }
        @media (max-width: 980px) {
            left: calc(100% - 100px);
            .SearchField-Wrapper .SearchField-Input {
                border: none;
                background: transparent !important;
                padding: 0;
                font-size: 0;
                cursor: pointer;
            }
            svg.svg-inline--fa.fa-magnifying-glass {
                z-index: 0;
                font-size: 24px;
                top: 11px;
                left: 10px;
            }
            &.SearchField_isVisible {
                svg.svg-inline--fa.fa-magnifying-glass {
                    font-size: 16px;
                    top: 16px;
                    left: 15px;
                }
            }
        }
        &-Wrapper {
            .ais-SearchBox-input {
                margin: 0;
                padding: 2px 5px 2px 40px;
                transition: all 0.2s ease 0s;
                outline: none;
                font-size: 15px;
                font-weight: 400;
                color: #444;
                border: 1px solid #ccc;
                box-shadow: inset 1px 3px 4px rgba(0, 0, 0, 0.1);
                height: 48px;
                min-height: unset;
                width: 100%;
                border-radius: 25px;
                z-index: 1;
                @media (max-width: 980px) {
                    padding: 0;
                    // font-size: 0;
                    cursor: pointer;
                    box-shadow: none;
                    background: transparent;
                    opacity: 0;
                }
            }
            .SearchField {
                &-Input {
                    background: var(--input-background-color) !important;
                    margin: 0;
                    padding: 2px 5px 2px 40px;
                    transition: all 0.2s ease 0s;
                    outline: none;
                    font-size: 15px;
                    font-weight: 400;
                    color: #444;
                    border: 1px solid #ccc;
                    box-shadow: inset 1px 3px 4px rgba(0, 0, 0, 0.1);
                    height: 48px;
                    min-height: unset;
                    width: 100%;
                    border-radius: 25px;
                    z-index: 1;
                }
                &-CloseIcon,
                &-SearchIcon {
                    left: 175px;
                    top: calc(50% - 12px);
                    z-index: 1;
                    .SearchIcon {
                        width: 17px;
                        fill: #777;
                    }
                }
                &-SearchIcon {
                    left: 177px;
                    top: 3px;
                    z-index: 1;
                }
            }
        }
        &-SearchInnerWrapperTysens {
            // display: none;
            // margin-top: -45px;
            // z-index: 1;
        }
        svg.fa-magnifying-glass {
            position: absolute;
            left: 18px;
            top: 16px;
            z-index: 2;
            font-size: 16px;
        }
        &_isActive {
            left: 166px !important;
            svg.svg-inline--fa.fa-magnifying-glass {
                z-index: 2;
            }
            .SearchField-SearchInnerWrapperTysens {
                display: block;
                .ais-SearchBox-submit {
                    display: none;
                }
                .SearchField-Results {
                    display: block;
                }
            }
            .search-wrapper {
                z-index: 12;
                input#search-field {
                    display: none;
                    width: 100%;
                }
                .ais-SearchBox-form .ais-SearchBox-input {
                    width: 100%;
                    border: none;
                    padding: 0 20px 0 45px;
                    border-radius: 25px 25px 0 0;
                    background: var(--input-background-color);
                    font-size: 15px;
                    opacity: 1;
                    &[value=""] {
                        border-radius: 25px;
                    }
                }
            }
            .SearchOverlay {
                border-radius: 0 0 25px 25px;
                &-Results {
                    padding: 12px 20px;
                    max-width: 800px;
                }
            }
            &::after {
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.2);
                z-index: 11;
            }
        }
    }

    // @media (min-width: 1200px) {
    //     left: 32px;
    // }

    &-Loader {
        background-image: url(../../images/loader.gif);
        background-repeat: no-repeat;
        width: 17px;
        height: 12px;
        position: absolute;
        top: 9px;
        left: 170px;
        z-index: 1;
        @include mobile {
            top: 13px;
            right: 20px;
            left: auto;
        }
    }
    .Loader {
        display: none;
    }
}

.LocalizationWrapper {
    &-de_DE {
        .SearchField {
            @include desktop {
                left: calc(100% - 400px);
                max-width: 100%;

                @media (max-width: 1600px) {
                    left: calc(100% - 350px);
                }

                @media (max-width: 1400px) {
                    left: calc(100% - 350px);
                }

                @media (max-width: 1200px) {
                    right: 65px;
                    left: calc(100% - 280px);
                }

                @media (max-width: 980px) {
                    right: 65px;
                    left: calc(100% - 100px);
                }
            }
        }
    }
    &-fr_FR {
        .SearchField {
            @include desktop {
                left: calc(100% - 400px);
                max-width: 100%;
                @media (max-width: 1600px) {
                    left: calc(100% - 300px);
                }
                @media (max-width: 1400px) {
                    left: calc(100% - 300px);
                }
                @media (max-width: 1200px) {
                    right: 65px;
                    left: calc(100% - 240px);
                }
                @media (max-width: 980px) {
                    right: 65px;
                    left: calc(100% - 100px);
                }
            }
        }
    }
    &-es_ES {
        .SearchField {
            @include desktop {
                left: calc(100% - 400px);
                max-width: 100%;

                @media (max-width: 1600px) {
                    left: calc(100% - 320px);
                }

                @media (max-width: 1400px) {
                    left: calc(100% - 320px);
                }

                @media (max-width: 1200px) {
                    right: 65px;
                    left: calc(100% - 255px);
                }

                @media (max-width: 980px) {
                    right: 65px;
                    left: calc(100% - 100px);
                }
            }
        }
    }
    &-nl_NL {
        .SearchField {
            @include desktop {
                left: calc(100% - 400px);
                max-width: 100%;

                @media (max-width: 1600px) {
                    left: calc(100% - 350px);
                }

                @media (max-width: 1400px) {
                    left: calc(100% - 350px);
                }

                @media (max-width: 1200px) {
                    right: 65px;
                    left: calc(100% - 280px);
                }

                @media (max-width: 980px) {
                    right: 65px;
                    left: calc(100% - 100px);
                }
            }
        }
    }
}
