/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable declaration-no-important */

div#popup-root {
    .ReturnsForm_Popup_wrap-overlay {
        background: rgba(0, 0, 0, 0.4);
    }

    .ReturnsForm_Popup_wrap-content {
        max-height: 90%;
        overflow-y: auto;
        max-width: 720px !important;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .cross_subs_popup {
            position: absolute;
            inset-block-start: 10px;
            right: 10px;
            z-index: 9;

            button.Header-Button_type_close {
                position: static;
                background: #111;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                padding: 3px;

                .CloseIcon {
                    fill: #fff;
                }

                &:hover {
                    background: #333;
                }
            }
        }
    }
}

.ReturnsForm {
    div.Loader {
        inset: -29px;
        z-index: 99;
    }

    .FieldWrapMain {
        display: none;

        > p.Field-Message {
            display: block;
        }
    }

    .proTopSec {
        width: 100%;
        display: flex;
        color: #53565A;
        font-family: 'Regular-Bold';
        font-size: 16px;
        text-transform: uppercase;
        margin: 20px 0 8px;

        .qty {
            margin-left: auto;
        }
    }

    input[type="checkbox"]:checked {
        ~ .FieldWrapMain {
            display: block;
            margin-block-start: 20px;
        }

        ~ .prodWrap {
            opacity: 1 !important;
        }
    }

    input[type="file"] {
        border: 1px dashed var(--input-border-color);
        width: 60%;
    }

    textarea#topic {
        height: 130px;
    }

    .productSec {
        border: 1px solid #ddd;
        padding: 10px 15px;
        margin-block-end: 15px;
        width: 100%;
        border-radius: 5px;

        input[type="checkbox"] {
            position: absolute;
            inset-block-start: 32px;
            z-index: 1;
            height: auto;

            ~ .prodWrap {
                opacity: .5;
            }
        }
    }

    .css-b62m3t-container {
        background: #fff;
        z-index: 9;
        width: 60%;
    }

    .prod {
        &Wrap {
            display: flex;
            width: 100%;
            padding-left: 30px;
            align-items: center;

            .proQnt {
                margin-left: auto;
            }

            .pro {
                &Imag {
                    width: 80px;
                    min-width: 80px;
                    margin-inline-end: 18px; 

                    img {
                        width: 100%;
                    }
                }

                &Name {
                    margin-inline-end: 30px;
                }
            }
        }
    }

    .proName {
        h3 {
            margin: 0;
            font-family: "regular-bold",sans-serif;
            font-weight: 300;
            font-size: 17px;
            color: #232323;
            text-transform: uppercase;
            line-height: 1.2;
        }

        p {
            margin: 0;
            font-family: "regular-book", sans-serif;
            line-height: 1.2;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    .proQnt {
        input[type="number"] {
            padding: 0 10px;
            pointer-events: unset;
            max-width: 80px;
            text-align: center;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
            }
        }
    }

    .FieldTopWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .FieldBox {
            width: 48%;

            > p.Field-Message {
                display: block;
            }
        }
    }

    label.title {
        margin: 10px 0 10px;
    }

    .FieldLimit {
        font-style: italic;
        font-size: 13px;
        margin: 4px 0 0;
    }

    .Field {
        input[type="email"],
        input[type="text"] {
            width: 100%;
        }
    }

    padding: 5px 10px;

    .FieldWrap {
        margin-block-end: 15px;

        > label {
            margin-block-end: 0;
            font-weight: 400;
            font-size: 15px;
        }

        input[type="file"] {
            border: 1px dashed #aaa;
        }
    }

    .radio_wrap_container {
        display: flex;
        gap: 15px;
        flex-direction: row;
    }

    .radio_wrap {
        display: flex;
        margin-block-end: 5px;

        input {
            height: auto;
        }

        label {
            margin-left: 10px;
        }
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        margin-top: 20px;
    }

    .continueBtn,.prevStep {
        margin-block-start: 20px;
    }

    .prevStep {
        margin-left: 20px;
    }

    h2, h3 {
        width: 100%;
        margin: 0 0 10px;
        font-family: Intro;
        font-size: 35px;
    }

    h3 {
        font-size: 25px;
        margin: 30px 0 15px;
    }

    p {
        color: #53565A;
        font-size: 15px;
    }

    .decp {
        color: #53565A;
        font-family: 'Regular-Bold';
        font-size: 16px;
        text-transform: uppercase;
        margin-block-end: 10px;
    }

    .finalBtnBottom {
        margin-block-start: 30px;
        display: flex;

        .prevBtn {
            // margin-left: 20px;
        }
    }

    .finalStep .decp {
        margin-block-end: 30px;
    }

    .FieldMessage .FieldWrap > p.Field-Message {
        display: block;
    }

    .Field-Label {
        font-size: 15px;
    }

    .finalStep .FieldBox {
        margin-block-end: 30px;

        > p.Field-Message {
            display: block;
        }
    }   

    .css-13cymwt-control, .css-t3ipsp-control {
        height: 40px;
        border: 2px solid var(--button-border);
        max-height: 40px;
    }    

    .css-13cymwt-control .css-1fdsijx-ValueContainer, .css-1fdsijx-ValueContainer {
        height: 35px;
    }

    .css-qbdosj-Input,
    .css-19bb58m {
        height: 28px;
    }

    .css-1dimb5e-singleValue {
        inset-block-start: 5px;
    }
}
