/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-missing-end-of-source-newline */

.faqAccordionWrapper {
    margin-top: 30px;

    .accordion__heading {
        border: none;
        background: #eee;
        padding: 0;

        .accordion__button {
            padding: 12px 17px;
            font-size: 20px;
        }
    }
    
    .accordion__item {
        border: 1px solid #eee;
        margin-bottom: 15px;
    }

    .accordion__panel {
        background-color: transparent;
        padding: 10px 15px;

        &[data-hidden="true"] {
            display: none;
        }
    }

    .toggle-nav {
        height: auto;
        top: 0;
        bottom: 0;
        border-left: none;
    }

    h2 {
        text-transform: none;
    }

    h4 {
        font-family: regular-bold, sans-serif;
        font-size: 18px;
        line-height: 1.2;
        text-transform: uppercase;
        margin: 8px 0 8px;
        padding-top: 5px;
    }

    p, li {
        font-size: 17px !important;
        font-family: 'regular-book', sans-serif;
        color: var(--text-dark-gray-color);
    }

    li {
        margin-bottom: 5px;
    }

    a {
        text-decoration: underline !important;
        color: var(--paragraph-color) !important;

        &:hover {
            color: var(--text-red-color) !important;
        }
    }

    @include mobile {
        ul {
            list-style: disc;
            padding-left: 20px;
            margin-top: 10px;

            li {
                margin-bottom: 10px;
            }
        }
    }
}
