/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable number-max-precision */
/* stylelint-disable declaration-no-important */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable indentation */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --menu-background-color: #fff;
    --menu-desktop-background-color: rgba(122, 122, 122, 0.44);
    --menu-desktop-height: 60px;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: var(--primary-base-color);
    --menu-second-level-color: #adadad;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

header .Menu {
    &-MenuWrapper {
        @include desktop {
            .Menu-Item:not(:first-of-type) {
                margin-top: 0;
            }
        }

        li {
            @include mobile {
                padding-left: 0;
            }

            &::before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .first_menu {
            .Menu-Item {
                padding: 0 10px;
                margin-bottom: 10px;
                border: 1px transparent solid;

                .Menu-SubCatLink {
                    flex-direction: row;
                }
                svg {
                    margin-left: 5px;
                    font-size: 15px;
                }
                @media (max-width: 1100px) {
                    font-size: 15px;
                }
                @media (min-width: 1600px) {
                    padding: 0 15px;
                }
            }
        }
    }

    &-ItemCaption {
        color: unset;
        &:hover {
            color: unset;
        }
    }

    &_HideOnDesktop {
        @include desktop() {
            display: none;
        }
    }

    &-Overlay {
        background-color: var(--menu-desktop-background-color);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: -3px;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }

    &-MainCategories {
        pointer-events: unset;
        width: 100%;
        @include desktop {
            max-width: 100%;
        }
    }

    &-ItemList {
        display: grid;

        @include desktop {
            display: flex;
        }

        &_type {
            &_main {
                width: 100%;
            }

            &_subcategory {
                background: var(--menu-background-color);
                grid-auto-rows: max-content;
                padding: 0 1.2rem;

                @include mobile {
                    padding: 0 1.4rem;
                }

                @include desktop {
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-ItemCaption {
        &_type {
            &_main {
                font-size: 18px;
                line-height: 1.2;
                text-align: left;
                margin: 0;
                @media (min-width: 1600px) {
                    font-size: 20px;
                }
            }

            &_subcategory {
                font-size: 1.2rem;
                font-weight: normal;
                padding: 1.2rem 0;

                @include mobile {
                    font-size: 1.4rem;
                    padding: 1.4rem 0;
                }

                @include desktop {
                    padding-left: 0;
                }
            }
        }

        &_isLogo {
            font-size: 1.1rem;
        }

        &_isBanner {
            --button-background: var(--homepage-button-background);
            --button-border: transparent;
            --button-color: var(--homepage-button-color);
            --button-hover-background: var(--homepage-button-color);
            --button-hover-border: transparent;
            --button-hover-color: var(--homepage-button-background);
            --button-padding: 9px 49px;

            bottom: 20px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    &-ItemFigure {
        align-items: center;
        display: grid;

        &_type {
            &_main {
                padding-left: 30px;
                text-transform: uppercase;

                @include desktop {
                    height: var(--menu-desktop-height);
                    padding-left: 0;
                    z-index: 20;
                }
            }
        }

        &_isBanner {
            @include desktop {
                overflow: hidden;
                width: 200px;
            }
        }

        &_isLogo {
            display: flex;

            .Image-Image {
                object-fit: cover;
            }
        }
    }

    &-Item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0 15px;
        font-family: "regular-bold", sans-serif;
        font-weight: 400;
        font-size: 17px;
        color: #232323;
        text-transform: uppercase;
        min-height: 48px;
        white-space: nowrap;
        -webkit-margin-start: 0 !important;
        @media (min-width: 1600px) {
            font-size: 18px;
        }
        svg {
            transition: transform 0.3s ease-in-out;
        }
        &:hover {
            color: var(--text-red-color);
        }
        &.shop_by {
            pointer-events: none;
            padding-right: 15px !important;
            @media (min-width: 811px) and (max-width: 992px) {
                display: none;
            }
        }
        &:first-child {
            padding: 0 15px;
        }
        .Menu-SubCatLink {
            cursor: pointer;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;

            .link-badge {
                position: absolute;
                top: -2px;
                right: 0;
                font-size: 11px;
                font-weight: 400;
                line-height: 15px;
                color: #fff;
                background: var(--text-blue-color);
                padding: 0 4px;
                white-space: nowrap;
                border-radius: 2px;
            }
        }
        @media (min-width: 1600px) {
            padding: 0 25px;
            // font-size: 20px;
        }
        button {
            width: 100%;
        }
        &.promo-black-friday, &.promo-cyber-sale {
            background-color: var(--primary-accent-color);
            color: #fff;
        }
        &.promo-xmas {
            position: relative !important;
            background-color: var(--primary-base-color);
            color: #fff;
        }
        &.selectedLabel {
            border: 1px #ccc solid !important;
            &.submenuVisible {
                svg {
                    transform: rotate(180deg);
                }
            }
            &.promo-black-friday,
            &.promo-cyber-sale {
                justify-content: flex-start;
                // padding-top: 7px;
                background-color: var(--primary-accent-color);
                // background-color: var(--text-red-color);
                color: #fff;
                a::after {
                    display: none;
                    //height: 100%;
                    //top: 38px;
                }
                @media (min-width: 1600px) {
                    //padding-top: 5px;
                    a::after {
                        display: none;
                        //top: 40px;
                    }
                }
            }
            &.promo-xmas {
                justify-content: flex-start;
                background-color: var(--primary-base-color);
                color: #fff;
                a::after {
                    display: none;
                }
                @media (min-width: 1600px) {
                    a::after {
                        display: none;
                    }
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;
        top: calc(var(--header-nav-height) + 3px);
        pointer-events: none;
        width: 100%;

        @include tablet {
            top: var(--header-nav-height);
            left: 0;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: calc(var(--header-nav-height) * -1);
        }

        &_isVisible {
            pointer-events: all;
        }
    }

    &-SubCategoriesWrapperInner {
        background-color: var(--overlay-background);
        transition: clip-path 200ms ease-in;
        will-change: clip-path;
        clip-path: inset(0 0 100% 0);
        margin: auto;

        &_isVisible {
            clip-path: inset(0);
        }

        @media (min-width: 1024px) and (max-width: 1130px) {
            max-height: calc(80vh - var(--header-nav-height));
            overflow-y: auto;
        }
    }

    &-SubCategories,
    &-Promotion {
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Promotion {
        order: 3;
    }

    &-SubCategories {
        padding: 40px 55px 60px 1.2rem;

        @include mobile {
            padding: 40px 55px 60px 1.4rem;
        }
    }

    &-SubMenu {
        background-color: var(--overlay-background);
        opacity: 0;
        pointer-events: none;
        touch-action: pan-y;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;

        @include desktop {
            background: transparent;
            transform: translateX(0);
            transition-duration: 0ms;
            width: 100%;
        }

        &_isVisible {
            @include subcategory-visible;
            @include mobile {
                height: 100%;
                overflow-y: scroll;
            }

            .Menu-Link {
                @include desktop {
                    padding: 0;
                }
            }
        }

        .Menu-Link {
            @include desktop {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &-SubItemWrapper {
        flex-basis: 200px;

        @media (min-width: 992px) {
            margin-right: 4.8rem;
            margin-bottom: 2.4rem;
        }

        &_isBanner {
            flex-grow: 1;
            flex-basis: 400px;
        }

        > .Menu-Link {
            display: block;

            @media (min-width: 992px) {
                color: var(--menu-second-level-color);
                font-size: 1.4rem;
                text-transform: uppercase;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemList {
                display: block;
                padding: 0;

                &_isBanner {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-template-columns: repeat(auto-fit, 200px);
                }

                .Menu-Item {
                    display: block !important;
                }
            }
        }
    }

    &-Image {
        &_isBanner {
            @include desktop {
                padding-bottom: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        &_isLogo {
            height: 44px;
            margin-right: 20px;
            width: 44px;
        }

        &_type {
            &_main {
                grid-column: 2;
                height: 100%;
                justify-self: end;
                padding-bottom: 0;
            }

            &_subcategory {
                border-radius: 50%;
                height: 3.84rem;
                padding-bottom: 0;
                width: 3.84rem;

                @include mobile {
                    height: 4.48rem;
                    width: 4.48rem;
                }
            }
        }
    }

    &-Link {
        color: inherit;
        white-space: nowrap;
        padding-left: 0;

        @include mobile {
            padding: 1.4rem 0;
        }

        @include desktop {
            display: block;
            margin: 0;
        }

        &:hover,
        &_isHovered,
        &:focus {
            color: var(--menu-item-hover-color);
            text-decoration: none;
        }
    }

    &-Link + &-Link {
        margin-left: 0;
    }

    .Image {
        background: none;
    }
}

header .Menu-Item.selectedLabel {
    > .Menu-SubCatLink {
        // &::after {
        //     content: "";
        //     display: block;
        //     border-width: 10px;
        //     border-color: #dd3526 transparent transparent transparent;
        //     border-style: solid;
        //     bottom: -2px;
        //     position: absolute;
        //     left: 50%;
        //     margin-left: -10px;
        //     transition: width 2s, height 2s, background-color 2s, transform 2s;
        //     display: none;
        // }
        position: relative;
    }
    color: var(--text-red-color);
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 40px;
    background: linear-gradient(180deg, rgba(226, 226, 226, 0) 49.48%, rgba(138, 138, 138, 0.0268041) 56.25%, rgba(171, 171, 171, 0.2) 100%), #FFFFFF;
    svg {
        fill: var(--text-red-color);
    }
    &Act {
        color: var(--text-red-color);
    }
    &2 {
        color: var(--text-red-color);
    }
}
.activeMenu ~ .Menu-MainCategories.second_menu {
    display: block;
}
.Menu-MainCategories.second_menu {
    display: none;
    &.vehicleFind {
        display: block;
        position: absolute;
        top: 70px;
        z-index: 1;
    }
    &.subMenus {
        position: relative;
        z-index: 2;
        box-shadow: none;
    }
    > div {
        position: relative;
    }
    .Menu-OverlayBox {
        position: absolute;
        height: 100vh;
        left: 0;
        right: 0;
        border: 0;
    }
    .MainCategoriesWrap > ul {
        display: block;
        padding: 25px 0 10px 0 !important;
        columns: 5 100px;
        gap: 10px;
        @media (max-width: 1200px) {
            columns: 4 175px;
            padding: 25px 15px 0 !important;
        }
        &[aria-label="Shop Online"] {
            > li.Menu-Item {
                &:nth-child(1) {
                    display: block;
                }
            }
        }
        &[aria-label="Discover More"] {
            columns: 4 175px;
            max-width: 1200px;
            margin-left: 0;
            > li.Menu-Item {
                margin-right: 5px;
                &:nth-child(4) {
                    min-height: 295px;
                    @media (min-width: 1600px) {
                        min-height: 318px;
                    }
                }
                &:nth-child(3), &:nth-child(5) {
                    display: block;
                }
            }
        }
        > li.Menu-Item {
            height: auto;
            padding: 0;
            display: inline-block;
            min-width: 180px;
            min-height: unset;
            vertical-align: top;
            margin-right: 50px;
            margin-bottom: 12px;
            a {
                color: #232323;
                margin-bottom: 4px;
                &.NoUrl {
                    pointer-events: none;
                }
            }

            .Menu-ItemFigure_type_main {
                height: auto;
                > .Menu-ItemCaption {
                    // border-left: 1px solid #ccc;
                    // padding: 0 14px;
                    font-weight: 400 !important;
                    @media (min-width: 811px) and (max-width: 992px) {
                        // padding: 0 8px;
                        // font-size: 15px;
                    }
                    @media (min-width: 1600px) {
                        // padding: 0 24px;
                        // font-size: 18px;
                    }
                }
            }
            &:first-child {
                .Menu-ItemFigure_type_main {
                    .Menu-ItemCaption {
                        border-left: none;
                    }
                }
            }
            & > div > .Menu-Link.active_menu {
                // background-color: var(--primary-accent-color);
                // color: #fff;
                // position: relative;
                // &::after {
                //     content: "";
                //     display: block;
                //     border-width: 10px;
                //     border-color: var(--primary-accent-color) transparent
                //         transparent transparent;
                //     border-style: solid;
                //     z-index: 2;
                //     position: absolute;
                //     left: 50%;
                //     margin-left: -10px;
                // }
            }
            .Menu-Link:hover {
                color: inherit !important;
            }
            &:hover {
                // background: var(--primary-accent-color);
                // color: #fff;
                // ul > .category-details-image {
                //     display: flex;
                // }
                .Menu-Link.active_menu {
                    background-color: transparent;
                }
                > div {
                    > .Menu-Link {
                        // &::after {
                        //     content: "";
                        //     display: block;
                        //     border-width: 10px;
                        //     border-color: var(--primary-accent-color)
                        //         transparent transparent transparent;
                        //     border-style: solid;
                        //     z-index: 2;
                        //     position: absolute;
                        //     left: 50%;
                        //     margin-left: -10px;
                        // }
                    }
                }
                div#More {
                    color: inherit;
                }
            }
            div#More {
                color: var(--text-red-color);
            }
        }
    }
    li.Menu-Item {
        .submenu {
            // display: none;
            // position: absolute;
            // left: 0;
            // right: 0;
            // background: #e3e3e3;
            .Menu-Item {
                display: block;
                height: auto;
                line-height: 1;
                padding: 0;
                min-height: unset;
                .Menu-ItemFigure_type_main {
                    height: auto;
                    > .Menu-ItemCaption {
                        padding: 0;
                        border: none;
                    }
                }
                &:hover {
                    .submenu {
                        position: static;
                    }
                    .category-details-image {
                        display: flex;
                        z-index: 1;
                        background: #f5f5f5;
                    }
                }
                .submenu {
                    display: none;
                }
                > div {
                    &:first-child {
                        // width: 265px;
                        // border-right: 1px solid #cccccc;
                        @media (min-width: 1600px) {
                            // width: 310px;
                        }
                    }
                }
                .Menu-Link {
                    &::after {
                        // content: "";
                        // border-color: transparent transparent transparent #ccc;
                        // border-style: solid;
                        // border-width: 5px;
                        // position: absolute;
                        // top: 38%;
                        // right: 4%;
                    }
                    position: relative;
                    color: #666;
                    transition: transform 0.2s ease-in-out;
                    white-space: pre-line;
                    line-height: 1;
                    margin-bottom: 6px;
                    * {
                        color: inherit;
                    }
                    &:hover {
                        transform: translateX(3px);
                        color: var(--text-red-color) !important;
                        &::after {
                            border-color: transparent transparent transparent
                                var(--text-red-color);
                        }
                    }
                }
            }
            .Menu-ItemCaption_type_main {
                font-size: 16px !important;
                line-height: 1.2;
                font-weight: 400 !important;
                @media (min-width: 1600px) {
                    font-size: 17px !important;
                }
            }
            > ul {
                // background: #f5f5f5;
                position: relative;
                // padding: 30px 30px;
                // min-height: 234px;
                &:empty {
                    display: none;
                }
            }
        }
        &:hover {
            .submenu {
                display: block;
            }
        }
    }
    background: #ffffff;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    text-align: left;
    width: 100%;
}
div.Menu-MenuWrapper {
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
    @include mobile {
        display: none;
    }
    @include desktop {
        padding-top: 12px;
        padding-bottom: 2px;
    }
    * {
        position: static;
    }
    @include desktop {
        margin: 0;
    }
    .Menu-MainCategories {
        ul, .MainCategoriesWrap {
            max-width: 1170px;
            margin: 0 auto;
            padding: 0;
            @media (min-width: 1200px) {
                max-width: 1170px;
                width: 100%;
            }
            @media (min-width: 1600px) {
                max-width: 1405px;
            }
        }
        &.first_menu {
            ul {
                @include desktop {
                    padding-left: 178px;
                }
            }
            .deals-link {
                position: relative;
                text-align: center;
                .deals-badge {
                    position: absolute;
                    top: 4px;
                    right: -7px;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 15px;
                    color: #fff;
                    background: var(--text-blue-color);
                    padding: 0 4px;
                    white-space: nowrap;
                    &.promo-black-friday, &.promo-cyber-sale,
                    &.promo-xmas {
                        font-size: 14px;
                        display: flex;
                        flex-direction: column;
                        position: static;
                        top: auto;
                        right: auto;
                        left: auto;
                        background: none;
                        @media (min-width: 1600px) {
                            margin-bottom: 3px;
                            font-size: 16px;
                        }
                    }
                }
                &::after {
                    display: none;
                }
            }
        }
    }
    form.Form.Vehicle-Finder {
        max-width: 970px;
        margin: 0 auto;
        padding: 8px 10px;
        .fr-link-gear {
            align-items: center;
            color: #444;
            font-size: 16px;
            font-family: "regular-bold", "Helvetica Neue", arial, sans-serif;
            white-space: nowrap;
            margin-right: 10px;
            text-transform: uppercase;
        }
        @media (min-width: 1200px) {
            max-width: 1170px;
            padding-left: 0;
            padding-right: 0;
            .fr-link-gear {
                display: flex;
            }
            .VehicleFinder-Wrapper {
                max-width: calc(100% - 300px);
            }
        }
        @media (min-width: 1600px) {
            max-width: 1405px;
            .fr-link-gear {
                font-size: 18px;
            }
        }
        .CustomerGarage {
            span.fr-link-gear {
                display: flex;
                @include mobile {
                    display: none;
                }
            }
            .VehicleFinder-Wrapper {
                .Field {
                    width: 100%;
                    margin-right: 20px;
                }
            }
        }
        .Field {
            position: relative;
            ul.Field-SelectOptions {
                position: absolute;
            }
        }
        .FrontrunnerLoader-Wrapper {
            display: none;
        }
    }
    .category-details-image {
        display: none;
        position: absolute;
        flex-wrap: wrap;
        left: 295px;
        top: 30px;
        right: 25px;
        bottom: 0;
        @media (min-width: 1600px) {
            left: 345px;
        }
        .Image {
            height: auto;
            width: 54%;
            padding-left: 30px;
            img.Image-Image {
                height: auto !important;
            }
            @media (min-width: 1600px) {
                width: 50%;
            }
            @media (min-width: 811px) and (max-width: 992px) {
                padding-left: 15px;
            }
        }
        .category-description {
            font-size: 14px;
            line-height: 1.2;
            font-family: "Regular-Book", sans-serif;
            color: #666;
            text-transform: none;
            width: 46%;
            padding-left: 30px;
            @media (min-width: 1600px) {
                font-size: 16px;
                width: 50%;
            }
            @media (min-width: 811px) and (max-width: 992px) {
                padding-left: 18px;
            }
        }
        .Menu-SubSubLevelDesktop {
            font-family: Regular-bold, sans-serif;
            text-transform: uppercase;
            margin-bottom: 13px;
            .Menu-ItemFigure_isBanner {
                width: auto;
                .Menu-ItemCaption_isBanner {
                    transform: unset;
                    font-size: 14px;
                    font-weight: 400 !important;
                }
            }
            .Menu-Link {
                line-height: 20px;
                &:hover .Menu-ItemFigure_isBanner {
                    color: var(--text-red-color);
                }
                &::after {
                    display: none;
                }
            }
        }
    }
    .Menu-Item {
        a {
            color: inherit;
            text-decoration: none !important;
            font-size: inherit;
            &#More,
            &#Mehr,
            &#Plus,
            &#Meer,
            &#Más\ {
                //color: var(--text-red-color);
                //background-color: transparent;
                & + .submenu {
                    li.Menu-Item {
                        &:first-child {
                            .category-details-image {
                                display: flex;
                                z-index: 1;
                                background: #f5f5f5;
                            }
                        }
                    }
                }
            }
        }
    }
}

.Header_name_search {
    .Header-Button_type_back {
        display: none;
    }
}

@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.snow {
    $total: 200;
    position: absolute !important;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    z-index: 999;

    @for $i from 1 through $total {
        $random-x: random(1000000) * 0.0001vw;
        $random-offset: random_range(-100000, 100000) * 0.0001vw;
        $random-x-end: $random-x + $random-offset;
        $random-x-end-yoyo: $random-x + ($random-offset / 2);
        $random-yoyo-time: random_range(30000, 80000) / 100000;
        $random-yoyo-y: $random-yoyo-time * 100vh;
        $random-scale: random(10000) * 0.0001;
        $fall-duration: random_range(10, 30) * 1s;
        $fall-delay: random(30) * -1s;

        &:nth-child(#{$i}) {
            opacity: random(10000) * 0.0001;
            transform: translate($random-x, -10px) scale($random-scale);
            animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
        }

        @keyframes fall-#{$i} {
            #{percentage($random-yoyo-time)} {
                transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
            }

            to {
                transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
            }
        }
    }
}
.LocalizationWrapper {
    &-de_DE {
        div.Menu-MenuWrapper form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
            @include desktop {
                max-width: calc(100% - 358px);
            }
        }
    }
    &-fr_FR {
        div.Menu-MenuWrapper form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
            @include desktop {
                max-width: calc(100% - 378px);
            }
        }
    }
    &-es_ES {
        div.Menu-MenuWrapper form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
            @include desktop {
                max-width: calc(100% - 320px);
            }
        }
    }
    &-nl_NL {
        div.Menu-MenuWrapper form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
            @include desktop {
                max-width: calc(100% - 320px);
            }
        }
    }
}
