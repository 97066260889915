/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.freeshippingindicator {
    font-family: regular-bold, sans-serif;
    padding: 10px 25px 10px 20px;
    text-transform: uppercase;

    &-title {
        text-align: center;
        margin-block: 5px 15px;
        font-size: 16px;
    }

    &-terms {
        color: var(--text-gray);
        text-transform: none;
        font-family: regular-book, sans-serif;

        a {
            color: var(--text-gray-color);
            text-decoration: underline;

            &:hover {
                color: var(--text-gray-color);
                text-decoration: underline;
            }
        }
    }

    .progressbar-container {
        position: relative;
        border: 1px solid var(--text-blue-color);
        width: 100%;
        height: 15px;
        margin-block-end: 10px;
        // &.active {
        //     border: 1px solid green;
        // }
    }

    .progressbar {
        display: block;
        width: 50%;
        height: 100%;
        background: var(--text-blue-color);
        position: absolute;
        transition: width 1s ease;

        &-icon {
            background: var(--text-blue-color);
            padding: 5px;
            position: absolute;
            right: -16px;
            inset-block-start: -10px;
            width: 32px;
            height: 32px;
            line-height: 24px;
            text-align: center;
            border-radius: 999px;

            svg {
                font-size: 16px;
            }

            z-index: 1;
        }
        // &.active {
        //     background: green;
        //     .progressbar-icon {
        //         background: green;
        //     }
        // }
    }
}
