/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-missing-end-of-source-newline */

.yotpoLoyalty-Confirmation {
    margin-top: 20px;
}

.rewards.Button {
    margin-left: 20px;
}

.confirmationPopup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.212);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        align-items: flex-start;
        z-index: 999;
    }

    .confirmationPopupWrapp {
        background: #fff;
        padding: 50px 35px 30px;

        @include mobile {
            margin-top: 50px;
        }
    }

    .confirmationBoxContent {
        margin-bottom: 25px;
        font-size: 16px;
    }
    
    .close {
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: 28px;
        cursor: pointer;
    }   

    .yotpoLoyalty-Ok.Button {
        min-width: 88px;
        font-size: 15px;
        float: right;
    }
}
