/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

body .PrivacyConsentCheckboxes {
    margin-block-start: 0;

    &-Wrapper {
        display: flex;
        border: 1px #ccc dashed;
        padding: 12px;
    }

    &-Consents {
        margin: 0;
    }

    &-Consent {
        padding: 0;
        display: flex;
        font-size: 12px;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;

        &,
        &:last-of-type {
            margin-block-end: 10px;
        }

        &::before {
            display: none;
        }

        p:last-of-type {
            margin-block-end: 0;
        }
    }

    &-Box {
        margin: 0;
    }

    &-BoxLabel {
        font-size: 14px;
        margin-left: 2px;

        p:last-of-type {
            margin-block-end: 0;
        }

        p:first-child::before {
            content: "*";
            color: var(--primary-error-color);
        }
    }

    &-RequiredMessage {
        color: var(--primary-error-color);
        display: none;
    }

    &-Wrapper {
        display: flex;
    }

    &-SignUp {
        &:last-of-type {
            margin: 0 0 20px 0;
            font-size: 14px;

            @include mobile {
                text-align: center;
            }
        }
    }

    &-Tooltip {
        text-align: center;
        display: flex;
        height: 100%;
        inset-block-start: 40px;
        margin-left: 19px;
        cursor: pointer;

        &::before {
            content: "?";
        }

        &:hover {
            &::before {
                content: "?";
            }
        }
    }

    .Tooltip {
        &-Content {
            text-align: start;
            right: 0;
        }
    }

    .Button[disabled] {
        opacity: 0.5;
        background: var(--text-red-color) !important;
    }
}
