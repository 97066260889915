/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.CompanyStructure {
    .box-actions {
        display: flex;
        flex-wrap: wrap;

        button.action {
            border: 2px solid var(--button-border);
            display: inline-block;
            width: auto;
            padding: 0 10px;
            height: 32px;
            margin: 0 5px 6px 0;
            color: var(--text-gray-color);
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'regular-bold', sans-serif; 

            &._hidden {
                display: none;
            }

            &:hover {
                color: var(--text-red-color);
            }       
        }

        @include mobile {
            justify-content: space-around;
            margin-block-end: 15px;

            button.action {
                width: 48%;
                margin-inline-end: 0;
            }
        }
    }

    svg.fa-users,.email,.type,svg.fa-question-circle {
        display: none;
    }    

    .CompanyTeam {
        svg {
            &.fa-users, &.fa-question-circle {
                display: inline-block;
            }

            &.fa-question-circle {
                position: absolute;
                margin-left: 40px;
                font-size: 20px;
                cursor: pointer;
                color: #bbbbbb !important;

                &:hover {
                    color: #444 !important;

                    &+.description {
                        display: block;
                    }
                }
            }

            &.fa-user {
                display: none;
            }
        }
    }

    .jstree-container-ul {
        .jstree-anchor {
            border: 1px solid #e2e2e2;
            display: inline-block;
            padding: 6px 10px;
            height: auto;
            color: var(--text-light-black-color);
            margin-block: 3px;
            margin-left: 5px;
            cursor: pointer;

            &.company-admin {
                margin-left: -.2rem;
            }

            svg {
                margin-inline-end: 10px;
                vertical-align: middle;
            }

            div.name {
                display: inline-block;
            }

            .description {
                display: none;
                position: absolute;
                background: #f4f4f4;
                border: 1px solid #999999;
                border-radius: 1px;
                color: var(--text-light-black-color);
                padding: 7px 15px;
                z-index: 1;
                top: 36px;
                left: -23px;
                font-size: 12px;
                min-width: 200px;

                &::before {
                    content: "";
                    border: 10px solid transparent;
                    height: 0;
                    width: 0;
                    position: absolute;
                    margin-block-start: -21px;
                    border-bottom-color: #eeeeee;
                    top: 1px;
                    left: 30px;             
                }

                @include desktop {
                    left: unset;
                    top: 0;
                    right: -246px;

                    &::before {
                        top: 50%;
                        left: -21px;
                        border-bottom-color: transparent;
                        border-right-color: #eeeeee;
                        margin-block-start: -10px;
                    }
                }
            }      
        }

        .Selected > .jstree-anchor, .Selected > div > div >.jstree-anchor {
            background-color: #808080;
            color: #fff;

            svg {
                color: #fff;
            }
        }

        ul li {
            background: none;
            border-left: 1px dashed var(--tree-border-color);
            margin-left: 24px;
            padding: .5rem 0;
            position: relative;
            margin-block-end: 0;

            &:last-child {
                border-left: none;
                padding-block-end: 0;

                &::after {
                    border-left: 1px dashed var(--tree-border-color);
                    content: '';
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            &.closed ul.jstree-children {
                display: none;
            }

            &.jstree-node > .jstree-icon {
                &::before {
                    content: "";
                    border: 2px solid #c2c2c2;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    border-radius: 50%;
                    margin-left: 15px;
                }

                &::after {
                    border-top: 1px dashed var(--tree-border-color);
                    content: '';
                    height: .1rem;
                    position: absolute;
                    left: 0;
                    top: 1px;
                    width: 15px;
                }
            }

            &.jstree-leaf.jstree-node > .jstree-icon {
                width: 41px;
                display: inline-block;
                height: 25px;
                inset-block-start: -12px;
                border-left: 1px dashed var(--tree-border-color);

                &::after {
                    right: 0;
                    inset-block-start: 27px;
                    width: 100%;
                }

                &::before {
                    display: none;
                }
            }
        }

        &.collapse {
            ul ul {
                display: none;
            }
        }
    }

    .LeftIconArrow {
        /* position: absolute; */
        left: 34px;
        top: -2px;
        width: 18px;
        text-align: center;
        cursor: pointer;
        z-index: 2;
        color: #c2c2c2;
        font-size: 24px;
        display: inline-block;
        margin-left: -18px;

        .ChevronIcon {
            fill: #c2c2c2;
            width: 14px;

            &_direction_bottom {
                display: none;
            }
        }

        &.closed {
            inset-block-start: -1px;

            .ChevronIcon_direction {
                &_bottom {
                    display: inline-block;
                }

                &_top {
                    display: none;
                }
            }
        }
    }

    .Draggable {
        padding: 0 !important;
        display: inline-block;
        width: auto !important;

        &>div {
            padding: 0 !important;
            margin: 0 !important;
            text-align: start !important;
        }
    }

    .update-parent-tree {
        display: inline-block;
        padding: 0 0 0 7px;

        svg {
            margin-inline-end: 0 !important;
        }

        .description_alt {
            display: none;
            position: absolute;
            z-index: 1;
            background: #ddd;
            white-space: nowrap;
            color: #333;
            padding: 1px 8px;
            border: 1px solid;
        }

        &:hover {
            .description_alt {
                display: block;
            }
        }
    }

    .parent-options {
        // .jZkBQk {
        //     height: 34px;
        //     .wrapper {
        //         border-radius: 0;
        //         z-index: 9;
        //     }
        //     .frjscc {
        //         min-height: unset;
        //         svg {
        //             margin-left: 7px;
        //             margin-right: 3px;
        //             width: 17px;
        //         }
        //         input {
        //             padding: 0;
        //             font-size: 12px;
        //         }
        //     }
        // }
        margin-block-start: 7px;

        input[type="text"] {
            width: 120px;
            height: 20px;
            border: 1px solid #ddd;
        }

        .no-suggestions {
            color: #333;
            font-size: 11px;
            position: absolute;
            z-index: 2;
            white-space: nowrap;
            background: #ddd;
            padding: 2px 8px;
        }

        ul.suggestions {
            position: absolute;
            z-index: 1;
            background: #ddd;
            width: auto;
            min-width: 120px;
            border: none;

            li {
                border-left: none;
                margin-left: 0;
                padding: 2px 8px;
                color: #333;
                font-weight: normal;
                border-color: #bbb;

                &.suggestion-active {
                    color: #000;
                }

                &:hover {
                    background-color: #ccc;
                }
            }
        }
    }
}
