/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.SearchFields {
    .SerachFilter {
        margin: 10px 0 15px;

        .btn {
            font-size: 16px;
        }
    }

    button.cancel-button {
        display: none;
    }

    @include desktop {
        button.CloseBtn {
            display: none;
        }
    }

    @include mobile {
        aside.FilterOverlay {
            position: fixed;
            inset: 0;
            background: #fff !important;
            z-index: 999;
            width: auto;

            button.CloseBtn {
                position: absolute;
                right: 12px;
                inset-block-start: 10px;

                .CloseIcon {
                    fill: #444;
                }
            }

            &.false {
                display: none;
            }
        }  
    }  

    .search-results-header {
        font-family: regular-bold, sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        line-height: 1.2;
        margin-block: 5px 3px;

        span {
            color: #777;
        }
    }

    ul.ais-InfiniteHits-list {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 50%;
            list-style: none;

            @include mobile {
                width: 100%;
            }

            &::before {
                display: none;
            }

            .SearchField-Item {
                display: flex;

                .Image {
                    width: 100px;
                    padding: 0;
                    height: auto;

                    img {
                        position: static;
                    }
                }
            }
        }
    }

    .SearchField-Results {
        position: static;
        max-height: unset;
        border-radius: 0;
        overflow: unset;

        &-Container {
            display: block;
        }
    }

    .price-box-text {
        font-size: 10px;
        color: #444;
        line-height: 1.2;
    }
    .priceDisWrap {
        display: flex;
        align-items: center;
        .discountLable {
            font-size: 12px;
            background-color: #222;
            padding: 0 4px;
            border-radius: 5px;
            text-transform: uppercase;
            margin-left: 5px;
            color: #fff;
            font-family: regular-bold,sans-serif;
        }
    }
    .price-amznstyle {
        color: var(--text-red-color);
        font-size: 20px;
        font-family: regular-bold, sans-serif;
        margin-block-start: 5px;

        .price-symbol {
            inset-block-start: -6px;
            right: 1px;
            font-size: 14px;
            font-weight: 400;
        }

        .price-whole {
            font-size: 24px;
            font-weight: 400;
        }

        .price-decimal {
            top: -7px;
            left: 1px;
            font-size: 14px;
            font-weight: 400;
        }

        .offscreen {
            position: absolute !important;
            inset-inline-start: 0 !important;
            inset-block-end: -1px !important;
            z-index: -1 !important;
            opacity: 0;
        }

        &Price-max {
            .price-container {
                display: none;
            }
            
            .offscreen {
                font-family: regular-bold, sans-serif;
                color: #888;
                font-size: 16px;
                text-decoration: line-through;
                margin-left: 8px;
            }
        }
    }

    &-Results {
        position: relative;
        margin-block-start: 0px;
        display: block;
        flex-direction: row;
        background-color: #fff;
        border-radius: 0;

        aside {
            width: 300px;
            background-color: #e9e9e9;
            padding: 10px 15px;
            display: none;
        }

        .ais-Pagination {
            display: none;
        }

        &-Btn {
            margin: 0 0 15px 20px;
        }

        &-Container {
            width: 100%;
            margin-block-start: 0;
            padding: 0 15px 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile {
                padding: 0;
            }

            .ais-InfiniteHits-list {
                // max-height: 700px;
                // overflow-y: auto;

                li {
                    margin-block-end: 5px;

                    .SearchField {
                        &-Item {
                            display: flex;
                            color: unset;
                            padding: 15px;

                            &:hover {
                                background: var(--light-bg-color);
                            }

                            .Image {
                                min-width: 80px;
                                max-width: 80px;
                                height: 60px;
                                padding: 0;
                                margin-right: 10px;
                            }
                        }

                        &-Title {
                            font-family: regular-bold, sans-serif;
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                        &-Brand,
                        &-Sku {
                            font-size: 11px;
                            color: var(--text-gray-color);
                            text-transform: uppercase;
                        }

                        &-Price {
                            color: var(--text-red-color);
                            font-size: 20px;
                            font-family: regular-bold, sans-serif;
                        }
                    }
                    .separator {
                        margin: 0 5px;
                        color: #ccc;
                        font-size: 12px;

                        &::before {
                            content: '|';
                        }
                    }
                }
            }
        }
    }

    .ais {
        &-SearchBox-form {
            position: relative;
            margin-block-end: 25px;

            .ais-SearchBox-input {
                border-radius: 999px;
                width: 100%;
                height: 48px;
                padding: 10px 10px 10px 40px;
                opacity: 1;
            }
            
            input[type="search"]::-webkit-search-cancel-button {
                /* Remove default */
                -webkit-appearance: none;
                background: url(../../images/close.svg);
                background-repeat: no-repeat;
                width: 18px;
                height: 16px;
                cursor: pointer;
            }

            @include mobile {
                margin-block-end: 10px;
            }
        }

        &-SearchBox-submit {
            position: absolute;
            left: 0;
            top: 8px;
        }

        &-SearchBox-reset {
            position: absolute;
            right: 15px;
            inset-block-start: 10px;
            padding: 5px;
        }

        &-Hits {
            background-color: #fff;
            padding: 5px 0 0;
            inset-block-start: 0;

            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;

                    &:empty {
                        display: none;
                    }

                    &::before {
                        display: none;
                    }

                    @media (max-width: 980px) {
                        width: 100%;
                    }
                }
            }
        }

        &-RefinementList {
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            &-checkbox {
                min-width: auto;
                min-height: auto;
                -webkit-appearance: checkbox;
                appearance: checkbox;
                caret-color: var(--primary-base-color);
                margin-right: 8px;
                height: auto;
            }

            &-count {
                background-color: var(--text-gray);
                color: #fff;
                padding: 2px 5px;
                border-radius: 999px;
                font-size: 12px;
            }
        }

        &-ToggleRefinement {
            &-checkbox {
                min-width: auto;
                min-height: auto;
                -webkit-appearance: checkbox;
                appearance: checkbox;
                caret-color: var(--primary-base-color);
                margin-inline-end: 8px;
            }
        }

        &-MenuSelect {
            select {
                -webkit-appearance: auto;
                appearance: auto;
                background: #fff !important;
                padding: 5px 8px;
                width: 100%;
                margin-block-end: 4px;
                border-radius: 4px;
            }
        }

        &-Pagination {
            margin-block-start: 15px;

            &-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: flex-start;
                gap: 5px;
            }

            &-item {
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 20px;

                &::before {
                    display: none;
                }
            }
        }

        &-Highlight-highlighted {
            background-color: yellow;
        }
    }

    // &-SearchWrapper {
    //     margin-top: 28px;
    // }

    svg.fa-magnifying-glass {
        position: absolute;
        left: 18px;
        top: 16px;
        z-index: 1;
        font-size: 16px;
    }

    @include desktop {
        margin-block-end: 32px;

        &-Wrapper {
            .SearchField {
                &-Input {
                    background: var(--input-background-color) !important;
                    margin: 0;
                    padding: 2px 5px 2px 40px;
                    transition: all 0.2s ease 0s;
                    outline: none;
                    font-size: 15px;
                    font-weight: 400;
                    color: #444;
                    border: 2px solid #ccc;
                    height: 45px;
                    min-height: unset;
                    width: 200px;
                    border-radius: 25px;
                    z-index: 1;
                }

                &-CloseIcon,
                &-SearchIcon {
                    left: 175px;
                    top: calc(50% - 12px);
                    z-index: 1;

                    .SearchIcon {
                        width: 17px;
                        fill: #777;
                    }
                }

                &-SearchIcon {
                    left: 177px;
                    top: 3px;
                    z-index: 1;
                }
            }
        }

        &-SearchInnerWrapperTysens {
            display: none;
        }

        &_isActive {
            .SearchField-SearchInnerWrapperTysens {
                display: block;

                .ais-SearchBox-submit {
                    display: none;
                }
            }

            .search-wrapper {
                z-index: 12;

                .ais-SearchBox-form .ais-SearchBox-input {
                    width: 100%;
                    border: none;
                    padding: 0 20px 0 45px;
                    border-radius: 25px 25px 0 0
                }
            }

            .SearchOverlay {
                border-radius: 0 0 25px 25px;

                &-Results {
                    padding: 12px 20px;
                    max-width: 800px;
                }
            }
        }
    }

    // @media (min-width: 1200px) {
    //     left: 32px;
    // }

    &-Loader {
        background-image: url(../../images/loader.gif);
        background-repeat: no-repeat;
        width: 17px;
        height: 12px;
        position: absolute;
        top: 9px;
        left: 170px;
        z-index: 1;

        @include mobile {
            inset-block-start: 13px;
            inset-inline: auto 20px;
        }
    }

    .Loader {
        display: none;
    }
}
