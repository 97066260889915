/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CookiePopup {
    inset-inline: 0;
    inset-block-end: 0;
    opacity: 1;
    transform: unset;
    background: #333;
    padding: 18px 25px 30px;
    width: auto;
    flex-direction: column;
    margin: 0;

    &-Content {
        font-size: 13px;
        font-weight: normal;
        max-width: 958px;
        margin: 0 auto;
        text-align: start;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
                color: var(--text-red-color);
            }
        }
    }

    &-CTA.Button {
        margin-block-start: 10px;
    }
}
