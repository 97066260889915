/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable property-no-unknown */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --placeholder-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.08) 0,
        rgba(255, 255, 255, 0.15) 15%,
        rgba(255, 255, 255, 0.08) 30%
    );
}

.Footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
    background-color: var(--footer-bg-color);
    color: var(--footer-text-color);
    padding-block-end: var(--header-nav-height);

    @include desktop {
        padding-inline: 0;
        padding-block-end: 0;
        text-align: left;
    }

    [class*="fr_f_row"] {
        padding: 30px 0;
        width: 100%;
        border-bottom: 1px solid #555;

        @include desktop {
            display: flex;
            margin-inline: -15px;
            width: auto;
        }

        .Image {
            padding-block-end: 0;
            height: auto;

            .Image-Image {
                position: static;
                height: auto !important;
            }
        }
    }

    .fr_f_row1 {
        padding: 0;

        @include desktop {
            > div {
                max-width: 25%;
                flex: 0 0 25%;
                padding: 0 20px;

                .Image {
                    padding-inline-end: 15px;
                }
            }
        }

        img {
            max-width: 176px;
            height: auto;
            margin-block: 45px 15px;
        }

        .fr_g_footer_block {
            margin: 30px 0;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: #fff;

            @include desktop {
                border-right: 1px solid #555;

                &:last-child {
                    border-right: none;
                }
            }

            .h2 {
                margin: 0;
                font-size: 18px;
                line-height: 1.5;

                @include tablet {
                    font-size: 14px;
                }
            }

            .fr_text_lg {
                font-size: 28px;

                @include tablet {
                    font-size: 20px;
                }
            }

            .fr_text_color_1 {
                color: var(--text-blue-color);
            }

            .fr_text_color_2 {
                color: var(--text-orange-color);
            }

            .fr_text_color_3 {
                color: var(--text-red-color);
            }

            .fr_text_muted {
                font-family: sans-serif;
                color: var(--footer-text-color);
                font-size: 12px;
                margin-block-start: 5px;
            }
        }
    }

    .fri {
        transform: scaleY(-1);
    }

    .fr_f_row2 {
        text-transform: uppercase;

        .fr_text_x-lg {
            font-family: intro, sans-serif;
            font-size: 22px;
            line-height: 1;
            margin-block-end: 10px;

            @include desktop {
                width: 25%;
                padding-left: 15px;
                margin: 0;
            }
        }

        @include desktop {
            align-items: center;

            .help_wrap {
                width: 75%;
                padding-inline: 15px;
            }

            .talk_sp_wrap {
                display: flex;
                padding: 5px 0;
                flex-wrap: wrap;

                .talk_sp {
                    margin-inline-end: 15px;

                    &:last-child {
                        margin-inline-end: 0;
                    }
                }
            }

            .h2.vis_help {
                text-align: start;
            }
        }

        .h2.talk_sp.ph_num {
            .footer-btn {
                margin: 20px 5px 0 0;

                @include mobile {
                    margin-block-start: 10px;
                }
            }
        }

        .vis_help,
        .talk_sp {
            font-size: 18px;
            line-height: 1.2;
            font-family: regular-bold, sans-serif;

            @include desktop {
                font-size: 17px;
            }
        }

        .footer-btn {
            margin: 8px 0;
            border-radius: 999px;
        }

        .aus-acknowledgement {
            font-size: 18px;
            font-family: regular-book, sans-serif;
            color: var(--footer-text-color);
            line-height: 1.4;
            margin-block-start: 10px;
            text-transform: none;
            text-align: center;
        }

        @media (max-width: 648px) {
            #FRCustomTrustbadgeMobile {
                height: 80px;
                width: 160px;
                margin: 25px auto 0 auto;
                overflow: hidden;

                > div {
                    display: none !important;
                }

                > div:first-child {
                    display: block !important;
                    margin: 0 auto;
                }
            }
        }
    }

    .fr_f_row3 {
        & > div {
            width: 25%;
            padding-inline: 15px;

            @include mobile {
                width: 100%;
            }
        }

        .our-story {
            h2 {
                font-size: 22px;
                font-weight: 400;
                margin: 0 0 10px;
                color: inherit;
                text-transform: uppercase;
            }

            p {
                font-family: regular-book, sans-serif;
                font-size: 16px;
                line-height: 1.3;
                color: #fff;
                word-break: break-word;
                word-wrap: break-word;
            }

            .h2 {
                font-size: 17px;
                margin: 0 0 6px;
            }

            a {
                color: var(--footer-text-color);
                text-transform: uppercase;

                &.more {
                    font-family: "regular-bold", sans-serif;
                }

                &.fr_text_color_1 {
                    color: var(--text-blue-color);
                }

                &:hover {
                    color: var(--text-red-color);
                }
            }

            #FRCustomTrustbadge {
                width: 100%;
                height: 90px;
                margin-block-start: 20px;

                > div {
                    display: none !important;
                }

                > div:first-child {
                    display: block !important;
                }
            }
        }

        .h2,
        .h2 .footerLink {
            font-size: 18px;
            margin: 0 0 10px;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: #fff;

            a {
                color: inherit;
            }
        }

        .h2 a:hover {
            color: var(--link-color);
            text-decoration: none;
        }

        .fr_list {
            margin-block-end: 30px;

            .footer-link,
            .footerLink {
                display: block;
                margin: 0;
                line-height: 25px;
                color: var(--footer-text-color);
                font-size: 16px;
                font-family: regular-book, sans-serif;
                text-transform: uppercase;
                cursor: pointer;

                @include mobile {
                    line-height: 26px;
                }

                &:hover {
                    color: var(--link-color);
                    text-decoration: none;
                }
            }
        }

        .InstagramFeeds-block {
            color: var(--footer-text-color);
            font-size: 14px;
            font-family: regular-book, sans-serif;
            max-width: 300px;
            margin-left: auto;

            @include mobile {
                margin: 30px auto 0 auto;
            }

            .InstagramFeeds {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                grid-gap: 10px;
                margin-bottom: 10px;
            }

            a,
            .footerLink {
                line-height: 0;
                display: block;
                color: var(--footer-text-color);
                text-decoration: none;

                img {
                    object-fit: contain;
                    height: auto;
                }

                &:hover {
                    color: var(--link-color);
                    text-decoration: none;
                }
            }

            .instagram_posts_follow {
                text-transform: uppercase;

                a {
                    line-height: normal;
                }
            }
        }
    }

    .fr_f_row4 {
        text-transform: uppercase;
        font-family: regular-bold, sans-serif;

        & > div {
            @include desktop {
                width: 25%;
                padding-inline: 15px;
            }

            .title {
                font-size: 22px;
                font-weight: 400;
                margin-block-end: 10px;
                margin-block-end: 15px;
            }
        }

        .social-icons {
            .icons {
                margin: 20px 0;
                display: flex;

                @include mobile {
                    justify-content: center;
                }

                .fab {
                    display: inline-block;

                    &:first-child {
                        .footer-link {
                            margin-left: 0;
                        }
                    }

                    .footer-link {
                        font-size: 20px;
                        background: #e2e2e2;
                        color: #222;
                        display: inline-block;
                        vertical-align: top;
                        width: 40px;
                        height: 40px;
                        margin: 0 5px;
                        border-radius: 50%;
                        line-height: 41px;
                        text-align: center;

                        &:hover {
                            background: #fff;
                        }

                        .fa-tumblr {
                            font-size: 18px;
                        }

                        &:empty {
                            display: none;
                        }

                        @include desktop {
                            background: transparent;
                            width: 30px;
                            color: var(--footer-text-color);
                            line-height: unset;
                            height: auto;

                            &:hover {
                                background: transparent;
                                color: #fff;
                            }
                        }
                    }

                    // &.youtube {
                    //     order: 1;
                    // }

                    // &.instagram {
                    //     order: 2;
                    // }

                    // &.facebook {
                    //     order: 3;
                    // }

                    // &.pinterest-p {
                    //     order: 4;
                    // }

                    // &.twitter {
                    //     order: 5;
                    // }

                    // &.tumblr {
                    //     order: 6;
                    // }
                }
            }
        }

        .social_newsletter_cont {
            display: block;
            width: 50%;

            @include mobile {
                width: 100%;
                margin-top: 30px;
            }

            form.Form {
                display: flex;
                align-items: center;

                @include mobile {
                    justify-content: center;
                    margin-bottom: 30px;
                }

                .Field {
                    margin-block-start: 0;
                    max-width: 220px;
                    width: 100%;

                    input {
                        border: 2px solid #ccc;
                        border-right: 0;
                        height: 34px;
                        padding: 2px 10px;
                        color: #555555;
                        font-family: arial, sans-serif;
                        font-size: 14px;
                        width: 100%;
                        border-radius: 999px 0 0 999px;
                    }
                }

                button.Button {
                    border-radius: 0 999px 999px 0;
                }
            }
        }

        .address {
            .address-lines {
                font-family: regular-book, sans-serif;
                font-size: 18px;
                color: var(--footer-text-color);
                line-height: 1.4;
            }
        }
    }

    .fr_f_row5 {
        border-bottom: none;

        .links {
            .footer-link,
            .footerLink {
                font-family: regular-bold, sans-serif;
                color: var(--footer-text-color);
                font-size: 14px;
                text-transform: uppercase;
                display: block;
                padding: 10px 0;
                margin: 0;
                cursor: pointer;

                &:hover {
                    color: var(--link-color);
                    text-decoration: none;
                }
            }

            @include desktop {
                display: flex;
                flex-wrap: wrap;

                a.footer-link,
                .footerLink {
                    border-right: 1px solid #666;
                    padding: 0 7px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .label {
            color: #999;
            font-size: 11px;
            line-height: 1.3;
            display: block;
            margin-block: 10px 15px;
            font-family: sans-serif;
        }

        .links_label {
            @include desktop {
                width: 50%;
                padding-inline: 15px;
            }
        }

        .icons {
            font-size: 26px;
            line-height: 1.7;
            color: var(--footer-text-color);
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-start;

            @include mobile {
                justify-content: center;
            }

            & > div {
                padding: 0 8px;
                min-width: 60px;
                text-align: center;

                &.fri {
                    width: 64px;
                    margin-left: 0;

                    svg {
                        width: 100%;
                        margin-top: 7px;
                        margin-left: -50px;
                        font-size: 12px;
                        inset-block-start: -6px;

                        &.fa-fri-paydirekt {
                            font-size: 27px;
                            inset-block-start: 9px;
                            margin-left: -35px;
                        }

                        &.fa-fri-comodo-secure {
                            font-size: 18px;
                            inset-block-start: 1px;

                            @include desktop {
                                margin-left: -40px;
                            }
                        }
                    }

                    &-giropay {
                        transform: unset;

                        svg {
                            font-size: 10px;
                            inset-block-start: -10px;
                            margin-left: -22px;
                        }
                    }

                    &-klarna {
                        transform: unset;

                        svg {
                            font-size: 11px;
                            margin-left: -20px;
                        }
                    }
                }
            }

            @include desktop {
                width: 50%;
                padding-inline: 15px;
            }
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-block-end: 30%;

        &:first-child {
            margin-block-start: 10px;
        }
    }

    &-SectionPlaceholder {
        background: rgba(0, 0, 0, 0.2);
    }

    .cookieConsent {
        background: #333 !important;
        color: #fff;
        padding: 18px 20px 25px;
        text-align: start;
        font-size: 13px;
        line-height: 1.4;

        > div {
            margin: 0 !important;
            flex: 100% !important;
        }

        a.footer-btn {
            text-decoration: underline;
            margin-left: 4px;
        }

        button {
            margin: 10px auto 0 !important;
            border: 2px solid rgba(0, 0, 0, 0.2) !important;
            line-height: 28px;
            padding: 0 10px !important;
            font-family: "regular-bold", Helvetica Neue;
            text-transform: uppercase;
            font-size: 14px !important;
        }
    }
    .footer-info {
        p:first-child {
            .footer-info-text {
                text-transform: lowercase;
            }
        }
    }
}

/**
 * Home page subscription popup style
 *
 */

.popup-content {
    width: 90% !important;
    border: none !important;
    inset-block-start: 20px;
    padding: 0 !important;
    border-radius: 4px;

    .Overlay_outer {
        position: fixed;
        inset: 0;
        z-index: 1;
        display: none;

        @include mobile {
            display: block;
        }
    }

    .social_popup_wrapper {
        display: flex;
        flex-direction: column;
        background-size: cover !important;
        background-repeat: no-repeat;
        background-position: 50% 0%;
        padding: 15px !important;
        height: auto !important;
        min-height: 100%;
        border-radius: 4px;
        z-index: 2;

        @include desktop {
            background-position: 40% 100%;
        }

        .social_newsletter_popup {
            h2 {
                font-family: "Intro", sans-serif;
                text-align: center;
                font-size: 28px;
                font-weight: 400;
                margin-block: 25px 20px;
                color: var(--text-red-color);
                text-transform: uppercase;
                line-height: 1.1;

                @include mobile {
                    font-size: 28px;
                }
            }

            p {
                font-family: "regular-book";
                text-shadow: 0 0 2px #f6f0f0;
                font-weight: 300;
                text-align: center;
                font-size: 20px;
                line-height: 1.2;
                color: var(--text-dark-gray-color);
                text-transform: uppercase;

                @include mobile {
                    font-size: 22px;
                    text-shadow: 0 0 10px #000;
                    font-family: "regular-bold";
                    color: #fff;
                }

                &.Field-Message {
                    text-align: start;
                    font-size: 13px;
                    margin-block-start: 3px;
                    font-family: open sans, sans-serif;
                    color: var(--text-red-color);
                    text-transform: none;
                    font-weight: 500;
                    text-shadow: none;
                }
            }

            form.EmailFriend {
                margin: 30px auto 0;
                text-align: center;

                .EmailFriend_wrap {
                    max-width: 420px;
                    width: 100%;
                    margin: 0 auto;

                    @include desktop {
                        display: flex;
                        // flex-wrap: wrap;
                        justify-content: center;

                        .Field:first-child {
                            margin-inline-end: 8px;
                        }
                    }
                }

                input[type="text"],
                input[type="email"] {
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    box-sizing: border-box;
                    color: #333;
                    width: 100%;
                    height: 32px;
                    font-family: open sans, sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.42857143;
                    padding: 0 9px;
                    border-radius: 0;
                    background-clip: padding-box;
                    background-color: rgba(255, 255, 255, 0.7);
                    backdrop-filter: blur(5px);
                }

                .subs_popup-subs_popup {
                    display: flex;
                    margin-block-start: 45px;
                    text-align: center;
                    padding: 0 25px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 1.3;

                    .Field.Field_type_checkbox {
                        margin: 0 5px 0 0;
                    }

                    .Field [type="checkbox"] + .input-control {
                        background: transparent;
                        margin: 0;
                    }

                    .subs_popup_consent-Link {
                        cursor: pointer;

                        a {
                            color: inherit;

                            &:hover {
                                color: var(--text-red-color);
                            }
                        }
                    }
                }

                button {
                    font-family: regular-bold, sans-serif;
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    line-height: 30px;
                    display: inline-block;
                    font-size: 14px;
                    transition: all 0.24s linear 0s;
                    color: var(--btn-text-color);
                    padding: 0 12px;
                    text-transform: uppercase;
                    background-color: var(--text-red-color);
                    margin-block-start: 25px;
                    min-width: 160px;
                    text-align: center;

                    &.fr-popup-btns {
                        // margin-top: 125px;
                        margin-block-start: 45px;

                        @include mobile {
                            margin-block-start: 50px;
                        }
                    }

                    &:hover {
                        background-color: var(--text-red-hover-color);
                        text-decoration: none;
                    }

                    @include mobile {
                        margin-block-start: 30px;
                    }
                }
            }

            .subs_popup_once {
                text-shadow: 0 0 2px #ffffff;
                text-transform: uppercase;
                font-family: "regular-bold";
                text-align: center;
                font-size: 14px;
                color: #444;
                margin: 20px 0 40px;
                cursor: pointer;

                &:hover {
                    color: var(--text-red-color);
                }

                svg {
                    margin-left: 5px;
                }

                @include desktop {
                    margin: 45px 0 40px;
                }
            }

            .MenuOverlay-Link {
                text-align: center;
                border: none;
                padding-left: 0;
                margin-block-start: 25px;
                color: #333;

                &:hover {
                    color: var(--text-red-color);
                }
            }
        }

        .cross_subs_popup {
            position: absolute;
            inset-block-start: 0px;
            right: -8px;
            z-index: 9;

            @include mobile {
                inset-block-start: -8px;
            }
        }

        .FrontRunnerMember-Popup {
            flex: 1;

            .cross_subs_popup {
                inset-block-start: 0px;

                @include mobile {
                    inset-block-start: -8px;
                }
            }

            .NotificationList {
                position: static;
                max-width: 100%;
                display: block;

                .Notification {
                    max-width: 100%;
                    width: 100%;
                    display: block;
                    background: transparent;
                    margin: 0;
                    padding: 0;

                    p.Notification-Text {
                        margin: 0;
                        max-width: 100%;
                        color: var(--notification-error-background);
                    }
                }

                .Notification-Button {
                    display: none;
                }
            }
            
            .Loader {
                inset: -15px;
            }

            h2 {
                font-family: "Intro", sans-serif;
                text-align: center;
                font-size: 48px;
                margin-block: 20px 10px 10px 10px;
                color: var(--text-red-color);
                text-transform: uppercase;
                line-height: 0.9;
                padding: 0 10px;

                span {
                    margin-block-start: 3px;
                    display: block;
                    font-size: 25px;
                    line-height: 1;
                }

                @include mobile {
                    font-size: 32px;

                    span {
                        font-size: 24px;
                    }
                }
            }

            p {
                font-family: "regular-bold";
                //text-shadow: 0 0 2px #f6f0f0;
                font-weight: 300;
                text-align: center;
                font-size: 16px;
                line-height: 1.2;
                color: #fff;
                text-transform: uppercase;
                max-width: 350px;
                margin: 0 auto;

                @include mobile {
                    font-size: 16px;
                }

                &.Field-Message {
                    text-align: start;
                    font-size: 13px;
                    margin-block-start: 3px;
                    font-family: open sans, sans-serif;
                    color: var(--text-red-color);
                    text-transform: none;
                    font-weight: 500;
                    text-shadow: none;
                }

                &.fr-email-text-header {
                    color: var(--text-red-color);
                    text-transform: uppercase;
                    font-size: 22px;
                    line-height: 1;
                    font-family: "Intro", sans-serif;
                    text-align: center;
                    margin-top: 5px;
                }

                &.fr-email-text-color {
                    text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
                }
            }

            form {
                //max-width: 370px;
                //margin: 5px auto 0 auto;
                //background-color: rgba(255, 255, 255, 0.5);
                //backdrop-filter: blur(10px);
                //-webkit-backdrop-filter: blur(10px);
                padding: 10px !important;
                //border-radius: 10px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                gap: 8px;

                .EmailFriend_wrap {
                    max-width: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;

                    @include mobile {
                        flex-direction: column;
                        align-items: stretch !important;
                    }

                    .Field {
                        margin-top: 0;
                        flex: 1;
                    }

                    input[type="text"],
                    input[type="email"] {
                        width: 100%;
                        border-radius: 999px;
                    }
                }
            }

            &-Wrapper {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                justify-content: center;
                max-width: 340px;

                .Field {
                    margin: 5px;
                    flex: 1;

                    input {
                        width: 100% !important;
                    }
                }

                button {
                    font-family: regular-bold, sans-serif;
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    line-height: 30px;
                    display: inline-block;
                    font-size: 15px;
                    transition: all 0.24s linear 0s;
                    color: var(--btn-text-color);
                    padding: 4px 12px;
                    text-transform: uppercase;
                    background-color: var(--text-red-color);
                    margin-block-start: 10px;
                    min-width: 120px;
                    text-align: center;
                    border-radius: 9999px;

                    &:hover {
                        background-color: var(--text-red-hover-color);
                        text-decoration: none;
                    }
                }

                .Field_type_checkbox span {
                    font-weight: normal;
                    font-size: 16px;
                }

                .text_consent-text_consent-Checkbox {
                    font-family: "regular-book", sans-serif;
                    font-weight: normal;
                    padding: 4px 0 2px 0;
                }
            }

            &-Actions {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                width: 100%;
                text-align: center;
                position: absolute;
                inset-block-end: 0;
                margin: 0 auto;
            }

            &-SkipButton {
                display: block;
                text-transform: uppercase;
                font-family: "regular-bold", sans-serif;
                text-align: center;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.2);
                padding: 8px 15px;
                border-radius: 999px;
                backdrop-filter: blur(2px);
                margin-bottom: 10px;

                &:hover {
                    color: var(--text-red-color);
                }

                svg {
                    path {
                        color: #fff;
                    }

                    margin-left: 5px;
                }
            }

            &-Login {
                text-align: center;
                margin-block: 20px 10px;

                a {
                    font-family: "regular-book", sans-serif;
                    color: #000;
                    padding: 8px 15px;
                    border-radius: 999px;
                    font-size: 16px;

                    span {
                        margin-left: 5px;
                        text-decoration: underline;
                    }
                }
            }

            button.Header-Button_type_close {
                inset-block-start: 5px !important;
            }

            &.fr-en_us {
                .fr-email-text-header {
                    font-size: 18px;
                }
            }
        }
    }
}

.popup-overlay {
    overflow-y: auto;
}

.newsletter_wrap-overlay {
    height: 0;
    transform: translateX(100%);
    animation: newsletter_show;
    animation-delay: 15s;
    animation-fill-mode: forwards;
    z-index: 9999 !important;
    
    .Overlay_outer {
        @include mobile {
            display: none;
        }
    }
}

#popup-root .newsletter_wrap-content {
    //width: 935px !important;
    height: 600px !important;
    max-width: 90%;
    padding: 0 !important;

    @media (min-width: 1600px) {
        margin-block-start: 0 !important;
    }

    button.Header-Button_type_close {
        position: static;
        background: #111;
        border-radius: 50%;
        width: 30px !important;
        height: 30px !important;
        padding: 3px;
        text-align: center;

        .CloseIcon {
            fill: #fff;
        }

        &:hover {
            background: #333;
        }
    }

    @media (max-height: 700px) {
        height: 100% !important;
    }

    @include mobile {
        height: 80% !important;
        transform: unset;
        inset-block-start: 20px;
        margin-block-end: 20px !important;
    }
}

.NewVersionPopup {
    z-index: 999999999 !important;
    background: var(--popup-overlay);

    &-DismissButton {
        display: none;
    }

    .Popup-Content {
        border: 1px solid #ddd;
    }
}

@keyframes newsletter_show {
    from {
        height: 0;
        transform: translateX(100%);
    }

    to {
        height: auto;
        transform: translateX(0);
    }
}

iframe#launcher {
    margin: 4px 8px !important;
    z-index: 9998 !important;

    @include mobile {
        // bottom: var(--navigation-tabs-height) !important;
    }
}

.hiddenNavigationTabs iframe#launcher {
    // display: none;
}

.NavigationTabs {
    display: block;

    &_isHidden {
        display: none;
    }
}

.footerLink {
    margin: 10px;
}

button.btt-button-top {
    position: fixed;
    inset-end: 62px 8px;
    z-index: 9;
    height: 45px;
    font-family: regular-book, sans-serif;
    color: var(--text-dark-gray-color);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 999px;
    text-align: vertical;
    min-width: 136px;

    svg {
        margin-inline-end: 10px;
    }
}

.b2b_Popup_wrap-overlay {
    height: 0;
    animation: newsletter_show;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    z-index: 9999 !important;
}

.b2b_Popup_content {
    font-size: 16px;
    line-height: 20px;

    h2 {
        margin-block-start: 0;
        padding-block-start: 0;
    }

    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;

        li {
            margin-left: 0;
            padding-left: 0;
            line-height: 24px;

            &::before {
                content: '';
            }
        }
    }
}

#popup-root {
    .b2b_Popup_wrap-content {
        width: 600px!important;
        max-width: 90%;
        padding: 0!important;
        margin-block-start: 10px!important;

        .PopUpWrap {
            min-height: auto;
            padding: 24px;
        }

        button.Header-Button_type_close {
            inset-block-start: 10px;
            inset-inline: unset 10px;
            z-index: 999;
            position: absolute;
            background: #111;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 3px;

            .CloseIcon {
                fill: #fff;
            }

            &:hover {
                background: #333;
            }
        }
    }
}

#popup-root div.newsletter_wrap-overlay {
    height: 600px;
    .social_newsletter_popup {
        min-height: 600px;
    }

    @include desktop {
        left: unset !important;
        right: 10px !important;
        inset-block: unset 10px !important;
        width: 430px;
        height: 590px;
        overflow: hidden;
        background: transparent;
        //animation: newsletter_show1;
        animation: fadeIn 1s, slideIn .8s linear;
        animation-delay: 15s;
        animation-fill-mode: forwards;
        border-radius: 8px;
        //transition: all .3s ease-out;
        box-shadow: 0px 5px 25px rgba(0,0,0, 0.4);
        display: none;
        visibility: hidden;
        z-index: 991 !important;
        
        .social_newsletter_popup {
            min-height: 590px;
        }

        .newsletter_wrap-content {
            max-width: 100%;
        }
    }

    @media (max-height: 700px) {
        height: 100% !important;
        right: 0px !important;
        inset-block-end: 0 !important;
        border-radius: 0;

        .social_newsletter_popup {
            min-height: 100%;
        }
    }

    @include mobile {
        height: 100% !important;

        .social_newsletter_popup {
            min-height: 100%;
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(400px);
        animation-timing-function: ease-out;
        visibility: block;
    }

    60% {
        transform: translateY(-30px);
        animation-timing-function: ease-in;
    }

    80% {
        transform: translateY(10px);
        animation-timing-function: ease-out;
    }

    100% {
        transform: translateY(0px);
        animation-timing-function: ease-in;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    99% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes newsletter_show1 {
    from {
        height: 0;
        animation-timing-function: ease-out;
    }

    to {
        animation-timing-function: ease-out;
        height: 600px;
    }
}
