/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.QuickOrder {
    padding-block-end: 20px;

    @include mobile {
        max-width: 490px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 12px;
    }

    .price-amznstyle {
        color: var(--text-red-color);
        font-size: 30px;
        font-family: regular-bold, sans-serif;
        font-weight: 400;
        text-align: start;
        padding-block-start: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include mobile {
            font-size: 24px;
        }

        .price-symbol {
            inset-block-start: -5px;
            right: 1px;
            font-size: 15px;
        }

        .price-whole {
            font-size: 26px;
        }

        .price-decimal {
            top: -5px;
            left: 1px;
            font-size: 15px;
        }
    }

    h1 {
        font-size: 24px;
        margin: 10px 0;
        text-transform: uppercase;
        color: var(--text-light-black-color);
    }

    .quick-order-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .errorMessage {
            margin-top: 10px;
        }

        .box {
            width: 100%;
            max-width: 700px;

            .Field {
                margin-block-start: 0;
            }

            .Button.find {
                line-height: 36px;
                margin-left: 10px;
            }

            .fields.deletable-item,
            .deletable-item-title {
                display: flex;

                .sku {
                    flex: 1;
                }

                .qty {
                    width: 80px;
                }

                input[type="text"],
                input[type="number"] {
                    width: 100%;
                    pointer-events: unset;
                    border: 2px solid var(--button-border);
                }
            }

            label, .note {
                margin-block-end: 10px;
            }

            .actions-toolbar {
                margin-block-end: 30px;
            }

            .SearchIcon {
                position: absolute;
                inset-end: 9px 8px;
                fill: #aaa;
                width: 20px;
            }
        }

        del.ProductPrice-HighPrice {
            margin-left: 5px;
        }

        .note-bt {
            margin-block-start: 6px;
            font-size: 13px;
        }
    }

    .SingleSkuSection {
        button.add_to_cart {
            opacity: .3;
            pointer-events: none;
        }

        &>.QuickOrderItems {
            &~ .actions-toolbar {
                button {
                    opacity: 1;
                    pointer-events: unset;
                }
            }
        }

        &>.ProductCard-Type {
            display: none;

            &~ .actions-toolbar {
                button.add_to_cart {
                    opacity: .3;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        }
    }

    .QuickOrderItems {
        display: flex;
        align-items: flex-start;
        border: 1px solid #e2e2e2;
        margin-block-start: 12px;
        padding: 10px;
        background: #ededed;
        max-width: 636px;

        @include mobile {
            flex-wrap: wrap;
        }

        .ProductCard-Picture {
            width: 90px;
            padding: 8px;
            height: auto;
            margin-right: 12px;

            img.Image-Image {
                height: auto !important;
                position: static;
            }
        }

        .price-box-text {
            display: block;
            font-size: 10px;
            line-height: 1;
            color: var(--text-light-black-color);
            font-weight: normal;
            margin-block-start: 2px;
        }

        .ItemTopSection {
            input {
                padding: 2px;
                height: 35px;
                width: 45px;
                min-width: unset;
                font-weight: normal;
                font-size: 18px;
                color: #333;
                background: transparent;
                margin: 0;
                border: none;

                @include mobile {
                    text-align: center;
                }

                &[type="number"] ~ button {
                    width: 28px;
                    height: 28px;
                    border-radius: 0;
                    border: 2px solid var(--primary-base-color);
                    &:disabled {
                        opacity: .2;
                        cursor: not-allowed;                
                    }
                }
            }
        }

        .ItemContent {
            flex: 1;
            margin-inline-end: 20px;
            font-size: 16px;
            font-family: regular-book, sans-serif;
            text-transform: uppercase;

            @include mobile {
                margin-inline-end: 0;
            }

            p {
                text-transform: inherit;
                margin-block-end: 4px;
                line-height: 1.1;
            }

            .SkuView {
                margin-block-end: 6px;
                color: #777;
            }

            .ProductPrice {
                font-size: 14px;

                .offscreen {
                    display: none;
                }
            }
        }

        .ItemTopSection {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 105px;

            .ProductCard-Type {
                color: #666;
                border: 2px solid #ccc;
                line-height: 24px;
                margin: 10px auto;
                padding: 0 8px;
                font-size: 10px;
                text-transform: uppercase;

                @include mobile {
                    margin-block-start: 0;
                }
            }

            .Field {
                margin-block-start: 0;
            }

            button.remove {
                position: absolute;
                right: -62px;

                * {
                    color: #fff;
                }

                @include mobile {
                    position: static;
                    margin-left: 10px;
                    margin-left: auto;
                }
            }

            @include mobile {
                margin-left: 100px;
                margin-top: 10px;
                width: 100%;
            }
        }
    }

    .actions-toolbar.add-to-cart {
        margin: 30px 0;
        width: 100%;
    }

    &~ .Breadcrumbs {
        display: none;
    }
}
