/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.Breadcrumbs {
    background: transparent !important;
    font-family: regular-book, sans-serif;
    text-transform: uppercase;
    font-size: inherit;
    color: var(--text-gray);

    @include desktop {
        max-width: 1170px;
        margin-inline: auto;
        margin-block-start: var(--header-height-desktop);

        nav {
            border-bottom: 1px solid #e2e2e2;
            display: flex;
            align-items: center;
            padding-block-start: 3px;

            @media (max-width: 1200px) {
                margin-inline: 15px;
            }
        }

        .ContentWrapper {
            padding: 0;
            max-width: 100%;
        }

        .Breadcrumbs-List {
            padding: 8px 0;

            li {
                a {
                    text-decoration: underline;
                }

                &:last-child a {
                    text-decoration: none;
                }
            }
        }
    }

    @media (min-width: 1600px) {
        max-width: 1405px;
        width: 100%;
    }

    .Breadcrumb {
        font-size: inherit;
        margin: 0;
        padding-inline-end: 0;
        text-transform: uppercase;

        .Breadcrumb-Link {
            font-size: inherit;
            text-transform: uppercase;
            color: var(--text-gray-color);

            &:hover {
                color: var(--text-red-color);
            }
        }

        .ChevronIcon {
            margin: 0 10px;
        }

        &:last-child .ChevronIcon {
            display: none;
        }
    }

    .Breadcrumbs-Crumb {
        padding-inline-end: 6px;
        font-size: 12px;

        &:not(:last-child)::after {
            content: "/";
            transform: unset;
            width: auto;
            height: auto;
            border: none;
            margin-left: 8px;
            color: #999;
            position: static;
        }
    }
}
