/* Intro Font */
@font-face {
    font-family: 'Intro';
    src: url('https://www.frontrunneroutfitters.com/media/fonts/Intro-Black-Caps.woff2') format('woff2'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Intro-Black-Caps.woff') format('woff'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Intro-Black-Caps.ttf') format('truetype'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Intro-Black-Caps.eot') format('embedded-opentype');
    font-weight: normal;
    font-display: swap;
}
/* Regular-Bold Font */
@font-face {
    font-family: 'Regular-Bold';
    src: url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Bold.woff2') format('woff2'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Bold.woff') format('woff'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Bold.ttf') format('truetype'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Bold.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Regular-Book Font */
@font-face {
    font-family: 'Regular-Book';
    src: url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Book.woff2') format('woff2'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Book.woff') format('woff'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Book.ttf') format('truetype'),
        url('https://www.frontrunneroutfitters.com/media/fonts/Regular-Book.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
