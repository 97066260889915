@mixin button {
    font-family: regular-bold, sans-serif;
    border: 2px solid rgba(0,0,0,.2);
    line-height: 30px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--btn-color);
    transition: all .24s linear 0s;
    color: var(--btn-text-color);
    padding: 0 12px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 999px;

    &:hover, &:focus {
        background-color: var(--text-red-hover-color);
        text-decoration: none;
        color: var(--btn-text-color);
    }
}

