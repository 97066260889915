/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.ApiDta_PriceList {
    display: flex;
    position: static;
    
    .CompanyPriceList {
        flex: 1;

        &:first-child {
            padding-inline-end: 15px;
        }

        &:nth-child(2) {
            padding-left: 15px;
        }

        .pricelist {
            margin-block-end: 25px;
            font-family: 'regular-book', sans-serif;

            button.btn-primary {
                width: auto;
            }

            &_heading {
                font-family: 'Regular-Bold', sans-serif;
                font-size: 18px;
                text-transform: uppercase;
                margin-block-end: 8px;
                color: var(--text-light-black-color);
            }

            span.or {
                margin: 0 9px;
                font-size: 16px;
            }
        }

        select {
            border: 2px solid var(--button-border);
            height: 32px;
            padding: 0 35px 0 9px;
        }

        .Field {
            margin: 0 0 5px;

            &_type_checkbox .input-control {
                margin-right: 10px;
            }

            label {
                font-weight: normal;
                font-size: 16px;
                font-family: 'regular-book', sans-serif;
                color: var(--text-light-black-color);
                display: flex;

                .input-control {
                    order: -1;
                    inset-block-start: 2px;
                }
            }
        } 
    }
}

.error-message {
    margin-bottom: 10px;
    color: var(--text-red-color);
    font-size: 17px;
    font-family: 'regular-book', sans-serif;
}
