/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --gdpr-cookies-box-shadow-color: rgba(0, 0, 0, 0.3);
    --gdpr-cookies-button-padding: 5px 15px;
    --gdpr-cookies-group-scrollbar-thumb-color: #e8e8e8;
    --gdpr-cookies-group-scrollbar-track-color: #fff;
}

body .GdprCookiesPopup {
    align-items: flex-start;
    justify-content: start;
    z-index: 99999;

    @include mobile {
        inset-block-start: 0;
    }

    .Popup {
        &-Content {
            position: relative;
            border-radius: 0;
            inset-block: 20% 0;
            // transform: translateY(-50%);
            height: auto;
            max-height: 785px;
            max-width: 600px;
            overflow-y: hidden;
            padding: 14px;
            margin: 0 auto;

            .GdprCookieGroupItem-Description {
                font-size: 15px;
            }

            @include mobile {
                box-shadow: 0 0 12px 2px var(--gdpr-cookies-box-shadow-color);
                height: 100vh;
                position: unset;
                inset-block: 0 unset;
                overflow-y: scroll;
                transform: none;
            }

            &::before {
                content: "";
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 999;
            }
        }

        &-Header {
            display: none;
        }
    }

    &.classic {
        .Popup-Content {
            transform: unset;
            position: fixed;
            inset-inline: 0;
            max-width: 100%;
            height: auto;
            background: #333;
            color: #fff;
            padding: 18px 25px 40px;
            inset-block-start: unset;

            &::before {
                content: "";
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 999;
            }

            @include mobile {
                inset-block-end: 0;
                padding: 18px 25px 25px;
            }

            .GdprCookiesPopup-Header {
                display: none;
            }

            .GdprCookiesPopup-Wrapper {
                max-width: 970px;
                margin: 0 auto;
                padding-block-start: 0;
                z-index: 9991;

                p {
                    color: inherit;
                    font-size: 14px;

                    a {
                        color: var(--text-red-color);
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .GdprCookiesPopup-Buttons {
                flex-direction: row-reverse;
                justify-content: center;
                flex-wrap: wrap;

                @include mobile {
                    row-gap: 15px;
                }

                button {
                    margin: 0 5px;
                    white-space: nowrap;
                    border-radius: 999px;
                }
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding-block-start: 24px;
        z-index: 9991;
        
        @include mobile {
            padding-block-start: 35px;
        }
    }

    &-Actions {
        display: grid;
        gap: 15px;
        height: max-content;
        padding-block-start: 14px;

        @include mobile {
            padding: 0 0 15px;
        }
    }

    &-Buttons {
        display: flex;
        flex-direction: column;

        .Button {
            margin-block-start: 15px;
        }
    }

    &-Description {
        font-size: 16px;
        margin: 0;

        @include mobile {
            max-height: unset;
        }

        a {
            color: var(--gdpr-cookie-group-more-info-color);
        }
    }

    &-Groups {
        @include desktop {
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            @include mobile {
                direction: rtl;
                margin-left: 5px;
                padding-block-start: 25px;
            }

            &::-webkit-scrollbar {
                width: 7px;

                &-thumb {
                    background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                    border-radius: 4px;

                    &:hover {
                        background: #bbb;
                    }
                }

                &-track {
                    border: 1px solid #eee;
                    border-radius: 4px;
                }
            }
        }
    }
}
