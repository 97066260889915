/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

@import "../../style/abstract/functions";

.PrivacyConsentCheckboxes {
    margin-block-start: 12px;

    &-Consents {
        margin: 0 0 0 -7px;
    }

    &-Consent {
        padding: 0;
        display: flex;
        font-size: 12px;
        align-items: center;

        &,
        &:last-of-type {
            margin-block-end: 10px;
        }

        &::before {
            display: none;
        }
    }

    &-Box {
        margin: 0;
    }

    &-BoxLabel {
        font-size: 12px;
        margin-inline-start: 10px;

        p:first-child::before {
            content: "*";
            color: var(--primary-error-color);
        }
    }

    &-RequiredMessage {
        color: var(--primary-error-color);
        display: none;
    }

    &-Wrapper {
        display: flex;
    }

    &-SignUp {
        &,
        &:last-of-type {
            margin-block: 10px 5px;
            font-size: 14px;

            @include mobile {
                text-align: center;
            }
        }
    }

    &-Tooltip {
        text-align: center;
        display: flex;
        height: 100%;
        inset-block-start: 40px;
        margin-inline-start: 19px;
        cursor: pointer;

        &::before {
            content: '?';
        }

        &:hover {
            &::before {
                content: '?';
            }
        }
    }

    .Tooltip {
        &-Content {
            text-align: start;
            inset-inline-end: 0;
        }
    }

    .Button[disabled] {
        background: blue;
        opacity: .5;
    }
}

.Privecy ~ .CheckoutPayments .InjectedStripeCheckoutForm {
    opacity: .4;
    pointer-events: none;
}
