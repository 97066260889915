/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.customerGargeHeader {
    &Data {
        max-height: 270px;
        overflow-y: auto;
    }

    .Garage-Link {
        display: block;
        padding: 0 20px;

        &:hover {
            background: var(--light-bg-color);

            svg {
                color: var(--text-red-color);
            }
        }
    }

    .my-garage-list {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-top: 1px solid var(--border-color-light);

        .garageName {
            font-family: "regular-bold", sans-serif;
            text-transform: uppercase;
            font-size: 17px;
            color: #444;

            .subTitle {
                font-size: 15px;
                color: #888;
                margin-block-start: 3px;
            }
        }

        .actions {
            margin-left: auto;

            svg {
                font-size: 18px;
            }
        }
    }

    .GargeButton {
        margin: 25px 0 12px 20px;
        font-size: 15px;
        padding: 0 20px;
    }

    .NoVehicles {
        font-family: "regular-book", sans-serif;
        font-size: 20px;
        margin: 10px 0 0 20px;
    }
}
