/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.my-interests {
    margin-block-end: 25px;

    .my-interests-wrap {
        display: flex;
        flex-wrap: wrap;

        .field-checkbox {
            flex: 0 0 50%;
            max-width: 50%;

            .Field {
                margin-block-start: 10px;
            }

            label {
                font-size: 16px;
                font-family: 'regular-book', sans-serif;
                color: var(--text-dark-gray-color);
                font-weight: normal;
                vertical-align: middle;
                display: flex;
                align-items: center;

                .input-control {
                    order: -1;
                    margin: 0 10px 0 0;
                }
            }
        }

        button {
            margin-block-start: 25px;
        }                
    }
}

