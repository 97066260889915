/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */

.EmailToFriend {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: regular-book, sans-serif;
    color: var(--text-light-black-color);

    &-links {
        span {
            display: none;
        }
    }

    > div {
        flex: 1;
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        grid-gap: 6px;
        gap: 6px;

        @include desktop {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            grid-gap: 2px 5px;
            gap: 2px 5px;
        }
    }

    @include mobile {
        .react-sharing-button__text {
            display: none;
        }
    }

    .react-sharing-button__text {
        padding-left: 0;
    }

    button {
        //margin: 5px 0 5px 10px;
        line-height: 1;

        svg {
            vertical-align: top;
        }

        @include mobile {
            span {
                display: none;
            }
        }

        @include desktop {
            &:hover {
                color: var(--text-red-color) !important;
            }

            &[aria-label="whatsapp"] {
                svg {
                    width: 22px;
                    height: 28px;
                    margin-inline-end: 3px;
                }
            }

            &[aria-label="telegram"] {
                svg {
                    width: 24px;
                }
            }

            &.react-sharing-button--pinterest {
                background: transparent;
                height: 28px;
                padding: 0;
                width: auto;
                color: #444;

                svg.react-sharing-button__icon {
                    fill: #444;
                }
            }

            display: flex;
            line-height: 1 !important;
            height: auto;
            max-height: 20px;
            margin: 0 0 0 6px;
            align-items: center;

            svg {
                height: auto;
                width: 28px;

                path {
                    fill: #444;
                    width: auto;
                }

                rect {
                    height: auto;
                    width: auto;
                    fill: transparent;
                }
            }

            &:hover {
                path {
                    fill: var(--text-red-color);
                }
            }
        }
    }

    .react-sharing-button__link {
        margin: 0;
        height: 40px;
        width: 40px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        @include desktop {
            margin-left: 6px;
            background: transparent !important;
            height: 28px;
            padding: 0;
            width: auto;
            color: #444;
            text-decoration: none;

            svg.react-sharing-button__icon {
                fill: #444;
            }

            &:hover {
                color: var(--text-red-color);

                svg.react-sharing-button__icon {
                    fill: var(--text-red-color);
                }
            }
        }

        .react-sharing-button__icon {
            width: 25px;
            height: 15px;
        }
    }
}
