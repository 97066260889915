/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CompanyStatements {
    .CompanyStatementsBal {
        margin-block-end: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .CreditLimit {
            border: 1px solid #e2e2e2;
            display: inline-block;
            padding: 10px;
            background: var( --light-bg-color);
            text-transform: uppercase;
            font-family: regular-bold, sans-serif;
            color: #333;
            min-width: 175px;
            width: 23%;

            .data {
                font-size: 18px;
                margin-block-start: 7px;
            }
        }

        @media (max-width: 1199px) { 
            display: block;

            .CreditLimit {
                margin: 0 12px 12px 0 ;
            }
        }
    }

    .todate {
        display: flex;
        flex-wrap: wrap;
        margin-block-end: 15px;

        label {
            margin-block-end: 4px;
            width: 100%;
        }

        .to-date {
            width: 200px;
            margin-inline-end: 25px;

            input {
                width: 100%;
            }
        }

        .react-datepicker-popper[data-placement^="bottom"] {
            margin-block-start: -30px;
        }
    }

    .to-date-list {
        @media (max-width: 1199px) {
            table {
                thead {
                    display: none;
                }

                tr {
                    display: block;
                    margin-block-end: 5px;
                    padding-block-end: 5px;

                    td {
                        display: flex;
                        padding: 5px 0;

                        &::before {
                            content: attr(data-th) ": ";
                            min-width: 100px;
                            padding-inline-end: 10px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
