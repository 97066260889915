@mixin after-desktop {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin before-desktop {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1023px) and (min-width: 768px) {
        @content;
    }
}

@mixin after-tablet {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin before-tablet {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin after-mobile {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 810px) {
        @content;
    }
}
