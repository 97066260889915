/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MySavedCard {
    h2 {
        font-family: regular-book, sans-serif;
        font-weight: normal;
        color: var(--text-light-black-color);
        border-bottom: 1px solid var(--border-color-heading);
        font-size: 22px;
        padding-block-end: 10px;
    }

    .new-cc-card {
        input[type="number"] {
            width: 200px;
            pointer-events: unset;
            text-align: unset;
            font-weight: normal;
        }

        .action {
            margin-block-start: 20px;
        }
    }

    .delete {
        display: inline-block;
        cursor: pointer;

        &:hover {
            color: var(--text-red-color);
        }
    }
}
