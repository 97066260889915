/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.AddUserPopup-overlay,
.NoSelectionPopup-overlay,
.SeleteUserPopup-overlay,
.DeleteRolePopup-overlay {
    display: block;

    .cross_subs_popup {
        position: fixed;
        inset-inline: 0;
        inset-block-start: 0;
        height: 50px;
        background-color: var(--header-background);
        border-bottom: 1px solid var(--primary-divider-color);
        z-index: 1;

        @include desktop {
            position: absolute;
        }
    }

    form.FieldForm {
        border: 1px solid #e2e2e2;
        background-color: var(--tab-bg-color);
        padding: 25px;
        margin-block: 50px 30px;

        @include desktop {
            margin-block-end: 0;
        }

        .Field:first-child {
            margin-block-start: 0;
        }

        input[type="text"],
        input[type="email"] {
            width: 100%;
        }
    }

    .selectTeam,.DeleteMessage {
        margin-block-start: 50px;
        font-size: 18px;
        font-family: 'Regular-Bold', sans-serif;
        color: var(--text-dark-gray-color);
        line-height: 1.2;
    }

    .DeleteMessage {
        padding-block-end: 20px;
    }

    .title_subs_popup {
        font-size: 18px;
        font-family: 'Regular-Bold', sans-serif;
        color: var(--text-light-black-color);
        text-align: center;
        line-height: 50px;
    }

    .Field label {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-light-black-color);
    }

    .Button.AddUser-Button,
    .Button.AddTeam-Button,
    .Button.OK-Button {
        margin-left: auto;
        display: block;
    }    
}

.SeleteUserPopup-overlay,
.DeleteRolePopup-overlay {
    button.btn {
        margin-inline-end: 15px;
    }    
}

.inactiveUser-Button {
    background: #999;
}

#popup-root .AddUserPopup-overlay .popup-content,
#popup-root .NoSelectionPopup-overlay .popup-content,
#popup-root .SeleteUserPopup-overlay .popup-content,
#popup-root .DeleteRolePopup-overlay .popup-content {
    padding: 14px !important;

    @include desktop {
        max-width: 430px;
    }

    button.Header-Button {
        top: 7px;
        z-index: 1;
        left: 12px;

        @include desktop {
            inset-inline: unset 12px;
            text-align: end;
        }
    }

    button.cancelUser-Button {
        position: absolute;
        bottom: 70px;
        left: 42px;

        @include desktop {
            bottom: 40px;
        }
    }
}
