/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.SearchOverlay {
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    background: var(--bg-search-overlay);

    @include desktop {
        transition-property: width, opacity, transform, border-color;
        transition-duration: 200ms;
        transform-origin: 0 0;
        transform: scaleY(0);
        will-change: width, transform, border;
        //width: var(--header-desktop-search-bar-width);
        width: 100%;
        max-width: var(--header-desktop-search-bar-max-width);
        text-align: start;
        inset-block-start: calc(100% - 2px);
        inset-inline: 0;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);

        &::before {
            display: none;
        }
    }

    &_isVisible {
        @include desktop {
            transform: scaleY(1);
            // box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
            border: none;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 2px);
        height: 1px;
        background-color: var(--search-criteria-background);
    }

    &-Criteria {
        margin-block-end: 0;
        background-color: var(--search-criteria-background);
        color: var(--text-dark-gray-color);
        padding: 0;
        max-height: 0;
        opacity: 0;
        transition: opacity 200ms;
        will-change: max-height, padding, opacity;
        overflow: hidden;
        margin: 0 !important;

        &_isVisible {
            opacity: 1;
            padding: 0.7rem 1rem 0.5rem;
            max-height: 100px;
            position: sticky;
            inset-block-start: 0px;
            z-index: 1;
        }

        strong {
            color: var(--search-criteria-search-term-color);
            margin-left: 5px;
        }
    }

    &-Results {
        padding: 1rem;
    }

    &-Item {
        padding: 0.5rem 0;
        margin-block-end: 0;

        &::before {
            content: none;
        }

        &::after {
            content: "";
            position: absolute;
            inset-block-end: -1px;
            inset-inline: 0;
            height: 1px;
            background-color: var(--search-item-divider-color);
        }

        &:last-child {
            margin-block-end: 0;

            &::after {
                content: none;
            }
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 4rem auto;
        grid-column-gap: 1rem;

        @include desktop {
            grid-template-columns: 7rem auto;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > div {
            font-size: 10px;
            color: #444;
            line-height: 1.2;
        }
    }

    &-Title {
        color: var(--search-item-title-color);
        font-size: 16px;
        font-weight: 400;
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        margin-block-end: 0 !important;
        max-width: calc(100vw - 7rem);

        &_isLoaded {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include desktop {
                white-space: normal;
            }
        }

        @include desktop {
            font-size: 16px;
            line-height: 1.1;
        }
    }

    .ProductActions {
        &-Section {
            display: flex;

            .separator {
                margin: 0 5px;
                color: #CCC;

                &::before {
                    content: '|';
                }
            }
        }

        &-ProductCode,
        &-Brand {
            font-size: 11px;
            color: var(--text-gray-color);
            margin-block-end: 2px;
            display: block;
            text-transform: uppercase;
        }
    }

    .ProductPrice {
        color: var(--text-red-color);
        font-size: 20px;
        font-family: regular-bold, sans-serif;
        min-height: auto;

        &-HighPrice {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            margin-left: 0;
        }

        &-SaveDiscount {
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            background-color: #222;
            border-radius: 5px;
            color: #fff;
            padding: 3px 4px;
            margin-left: 5px;
        }

        .price-new {
            display: none;
        }

        .price-box-text {
            display: none;
        }

        .price-amznstyle {
            .price-symbol {
                inset-block-start: -6px;
                right: 1px;
                font-size: 14px;
                font-weight: 400;
            }

            .price-whole {
                font-size: 24px;
                font-weight: 400;
            }

            .price-decimal {
                top: -6px;
                left: 1px;
                font-size: 14px;
                font-weight: 400;
            }

            .offscreen {
                position: absolute !important;
                inset-inline-start: 0 !important;
                inset-block-end: -1px !important;
                z-index: -1 !important;
                opacity: 0;
            }
        }
    }

    &-Link {
        color: inherit;
        text-decoration: none !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    p.SearchOverlay-Criteria_isVisible,
    p.ProductPrice.ProductCard-Price {
        margin-block-end: -3px;
    }

    .SearchOverlay-Results {
        padding: 5px 8px 8px;

        @include desktop {
            > p {
                margin: 0 10px 0 0;
            }

            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                flex-wrap: wrap;

                li {
                    // flex: 0 0 50%;
                    // max-width: 50%;
                    overflow: hidden;
                    width: 100%;
                }
            }
        }
    }

    .SearchOverlay-Item {
        padding: 12px 8px;

        &:hover {
            background: var(--light-bg-color);
        }
    }
}

@include mobile {
    .Overlay:not(.Overlay_isStatic) {
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(100% - var(--header-height));
        padding-block-end: var(--navigation-tabs-height);
        overflow: scroll;
        overflow-x: hidden;
        max-width: 100%;
    }
}
