/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.MyAccount {
    .my-subscrption-list {
        font-size: 13px;
        line-height: 1.3;
        padding: 18px 0;
        color: #303030;
        border-bottom: 1px solid #dddddd;
        display: flex;

        &:last-child {
            border-bottom: none;
        }

        @include desktop {
            &:first-child {
                padding-block-start: 0;
            }
        }

        & > div {
            border-bottom: 1px solid #dddddd;
            padding: 8px 0;

            .Subscrption-Link {
                color: inherit;
            }

            span {
                padding: 0 10px;
            }
        }

        .StockImage {
            width: 50px;
            min-width: 50px;
            border-bottom: none;
            margin-right: 10px;
        }

        .subscrption_wrap {
            color: #444;
            border-bottom: none;

            .des {
                font-family: regular-bold, sans-serif;
                font-size: 20px;
                text-transform: uppercase;

                @include desktop {
                    font-weight: 600;
                }
            }
            
            .price-container {
                font-family: regular-bold, sans-serif;
                font-size: 24px;
            }

            .ProductCard-Price span {
                padding: 0;
                color: var(--text-red-color);
                
                &.offscreen {
                    display: none;
                }

                &.price-symbol {
                    font-size: 15px;
                    top: -4px;
                    margin-right: 2px;
                }

                &.price-decimal {
                    top: -4px;
                    left: 1px;
                    font-size: 15px;
                }
            }

            .ProductCard-Price .price-box-text {
                display: block;
                font-size: 12px;
                font-weight: normal;
                margin-block-start: 2px;
                color: #555;
            }

            .ProductCard-Price {
                margin-block-end: 8px;
            }

            .notify_status {
                margin-block-end: 3px;
            }

            .subscribed_on {
                margin-block-end: 3px;
            }

            .view_remove {
                > span {
                    cursor: pointer;
                }

                > span:first-child {
                    padding-left: 0;
                }

                > span:hover {
                    color: var(--text-red-color);
                }
            }
        }
    }
}
