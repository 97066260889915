/* stylelint-disable indentation */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */

html {
    font-size: 14px;
    scroll-behavior: smooth;

    body {
        font-family: $font-helvetica;
        letter-spacing: unset;
        overflow-x: hidden;
        margin-block-start: 0 !important;
        text-align: start;
        inset-block-start: 0 !important;
    }
}

a {
    font-weight: normal;
    font-size: unset;
}

header,
footer,
main {
    font-size: inherit;
}

h1,
h2 {
    font-family: regular-bold, sans-serif;
}

.grecaptcha-badge {
    display: none !important;
}

.Popup .Popup-CloseBtn {
    right: 0;
}

h4,
p,
li,
dl {
    font-size: inherit;
}

li {
    * {
        color: inherit;
    }
    a {
        color: var(--link-color);
    }
}

li::before, ul > li::before {
    display: none;
}

.Field {
    [type="checkbox"]:checked + label,
    [type="radio"]:checked + label {
        background-color: var(--text-red-color);
        border-color: var(--text-red-color);
    }
}

div.yotpo .standalone-bottomline .star-clickable {
    flex-wrap: wrap;
}

.Image-Content {
    inset-inline-start: 0;
}

.Field-SelectWrapper::after,
.FieldSelect::after {
    display: none;
}

.noError p.Field-Message {
    display: none;
}

.FieldSelect-Option {
    font-size: 14px;
    // text-transform: uppercase;
    line-height: 1;
    padding: 10px;
}

select:not(.fr-cart-quantity-select) {
    background: #fff url(../../images/outfitter_arrows.png) no-repeat right
        bottom !important;
    padding-inline-end: 33px;
}

.Return-page {
    margin-left: auto;
    font-size: 12px;
    color: #444;
    text-decoration: none;
    background-image: url(../../images/return.png);
    background-repeat: no-repeat;
    padding-left: 12px;
    background-position: left center;
    font-weight: normal;
    font-family: regular-book, sans-serif;
    text-transform: uppercase;

    &:hover {
        color: var(--text-red-color);
    }
}

main,
.popup-overlay,
.Popup-Content,
.LoginAccount,
.CreateAccount,
.ForgotPassword,
.MyAccountOverlay {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
        border: 2px solid var(--button-border);
        height: 40px;
        padding: 0 9px;
        font-size: 14px;
    }

    textarea {
        max-width: 100%;
        height: auto;
        padding: 10px;
        width: 100%;
    }
}

.main_wrapper {
    display: flex;
    flex-direction: column;

    .Breadcrumbs {
        order: -1;
        width: 100%;
    }
}

input,
textarea,
select {
    caret-color: unset;
}

input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: auto;
    appearance: auto;
    min-height: unset;
    width: auto;
    min-width: unset;
}

select:disabled {
    opacity: 0.5;
}

.Field-Label_isRequired {
    &::after, &_notEmpty::after, &_notEmpty\,email::after {
        content: "*";
        color: var(--text-red-color);
        padding-left: 2px;
    }
}

.Field-SubLabel {
    font-weight: 400;
}

.Button.classDis {
    pointer-events: none;
    opacity: .5;
}

ul > li,
ol > li {
    margin: 0;
    line-height: 20px;
    list-style: unset;
    padding-left: 0;
}

ol {
    padding-left: 40px;
}

p {
    line-height: 1.42;
}

ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

h3 {
    text-transform: unset;
}

section.LoginAccount,
section.CreateAccount,
.ForgotPassword {
    margin-top: var(--header-height-TopStrip);

    @include desktop {
        margin-block-start: var(--header-height-desktop);
        max-width: 1170px;
        width: 100%;
        margin-inline: auto;
        padding-inline: 10px;

        .ContentWrapper {
            padding: 25px 0;
        }

        h3 {
            color: var(--text-gray-color);
            font-size: 26px;
            line-height: 1.3;
            text-transform: uppercase;
            font-family: regular-bold, sans-serif;
        }
    }
}

div .CreateAccount-InnerWrapper,
div .ForgotPassword-InnerWrapper {
    button {
        min-width: unset;
        width: auto;
    }
}

.Overlay > * {
    background-color: var(--overlay-content-bg);
}

input,
select,
textarea {
    min-height: 2rem;
    font-size: 16px;
}

input:focus,
textarea:focus,
select:focus {
    border: 1px solid var(--input-border-color);
}

div.owl-carousel .owl-nav {
    font-size: 30px;
    position: static;
    margin: 0;

    button.owl-prev {
        background: rgba(0, 0, 0, 0.4);
        width: 40px;
        height: 40px;
        color: #fff;
        margin: 0;
        position: absolute;
        inset-block-start: 50%;
        margin-block-start: -20px;
        left: 15px;
        border-radius: 0;
        text-align: center;

        span {
            color: inherit;
        }

        &:hover {
            background: #000;
        }
    }

    button.owl-next {
        background: rgba(0, 0, 0, 0.4);
        width: 40px;
        height: 40px;
        color: #fff;
        margin: 0;
        position: absolute;
        inset-block-start: 50%;
        margin-block-start: -20px;
        right: 15px;
        border-radius: 3px;
        text-align: center;

        span {
            color: inherit;
        }

        &:hover {
            background: #000;
        }
    }
}

.NotificationList {
    inset-block-start: 0;
    z-index: 9999999999;
    order: -1;
    position: fixed;
}

div .Notification {
    &-Button {
        right: 0;
    }

    @include desktop {
        min-height: unset;
        padding: 12px 30px 12px 14px;

        &-Button {
            inset-block-start: 5px;
        }
    }

    &_type_error ~ .Notification_type_error {
        display: none;
    }
}

table td,
table th {
    font-size: 1rem;
    min-width: 10rem;
    word-break: break-word;
    word-wrap: break-word;
}

h4,
p,
li,
dl,
button {
    font-size: unset;
    text-align: unset;
}

h4 {
    line-height: unset;
}

button {
    cursor: pointer;
}

.fr-link-gear {
    display: none;
}

div .Field {
    label {
        padding-block-end: 0.3rem;
        font-weight: 400;
        font-size: 1.05rem;
    }

    [type="checkbox"] + label,
    [type="radio"] + label,
    [type="checkbox"] + .input-control,
    [type="radio"] + .input-control {
        cursor: pointer;
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        border: 2px solid var(--input-border-color);
        transition-duration: 0.25s;
        margin-inline-end: 7px;

        &::after {
            left: 0;
            top: 0.28rem;
            width: 0.12rem;
            height: 0.12rem;
        }
    }

    &Form label {
        min-width: unset;
    }
}

div .Field-Message {
    font-size: 12px;
    margin-block-start: 3px;
    background-image: url(../../images/validation_advice_bg.gif);
    background-repeat: no-repeat;
    padding-left: 15px;
    background-position: 0 center;
}

.scrollDisabled {
    position: static;
}

.Popup {
    &-Heading {
        font-size: 20px;
    }

    &-Content {
        overflow: unset;
    }

    &_isVisible {
        overflow-y: auto;
    }
}

.FirstVisitPrivacyPolicyPopup,
.GdprCookiesPopup {
    background: rgba(0, 0, 0, 0.16);

    .Popup-CloseBtn {
        inset-block-start: -12px;
        right: -12px;
    }
}

.CartOverlay {
    inset-block-start: calc(var(--header-nav-height) + 2px);
    right: 0;
}

.ProductLinks {
    border-bottom: none;
}

.Image-Image {
    inset-inline-start: 0;

    &::after {
        display: none;
    }
}

main div.Loader {
    position: absolute;
}

div .PasswordChangePage-Wrapper {
    padding: 2.8rem 1.6rem;

    h1 {
        color: var(--text-gray-color);
        font-size: 28px;
        line-height: 1.3;
        text-transform: uppercase;
        border-bottom: 1px solid #efefef;
        margin-block-end: 25px;
        padding: 0 0 5px;
    }

    .Field label {
        font-weight: normal;
    }

    input[type="password"] {
        border: 2px solid rgba(0, 0, 0, 0.2);
        height: 40px;
        padding: 0 9px;
        font-size: 14px;
    }

    button {
        line-height: 32px;
        height: auto !important;
        padding: 0 12px !important;
        border-radius: 0 !important;
        margin-block-start: 20px;
    }
}

div.Loader {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    inset: 0;
    z-index: 7;

    .Loader-Scale {
        left: 50%;
        position: absolute;
    }

    &-Main {
        border-radius: 50%;
        border: 6px solid #f3f3f3;
        border-top-color: #dd3c26;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        top: 45%;
        left: 45%;
        position: absolute;
        z-index: 23;
        margin: -18px 0 0 -20px;
        background: transparent;

        * {
            display: none;
        }

        &::before,
        &::after {
            display: none;
        }
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.amazon_pay_button div#AmazonPayButton,
.bread-checkout-btn {
    max-width: 260px;
}

#bread-checkout-btn-minicart-bread-iframe,
#mini-cart-breadcheckout-bread-iframe {
    height: 40px !important;
    margin-block-start: 16px !important;
}

.amazon_pay_button {
    max-width: 260px;
    margin: 10px auto 0;
}

div#bred_div {
    margin: 0 auto;
}

.FieldSelect .ChevronIcon {
    display: none;
}

main {
    min-height: 300px;
    margin-top: var(--header-height);
    margin-top: var(--header-height-TopStrip);

    // &.HomePage {
    //     margin-top: var(--header-height-TopStrip);
    // }

    @include desktop {
        &.HomePage {
            margin-top: var(--header-height-desktop);
        }

        margin-top: var(--header-height-desktop);
    }
}

.noticActive ~ {
    .HomePage main, .CategoryPage, .Breadcrumbs, .CartPage, [class^='AdventurePage-'], .contactPage,
    section.LoginAccount, section.CreateAccount, .ForgotPassword, .MyAccount, .QuickOrder,
    .CmsPage--vehicles-2020-land-rover-defender-roof-rack {
        // margin-block-start: var(--header-height-mb);
        @include desktop {
            margin-block-start: var(--header-height-desktop-mg);
        }
    }

    // .ProductPage, .Checkout, .CmsPage, .MenuPage {
    //     @include mobile {
    //         margin-block-start: var(--header-height-mb); 
    //     }
    // }
    
    .HomePage main, main, section.LoginAccount, section.CreateAccount, .ForgotPassword {
        @include mobile {
            margin-top: var(--header-height-hp-mb);
        }
        &.Checkout {
            @include mobile {
                margin-top: var(--header-height-TopStrip);
            }
        }
    }
}

.mg_customer {
    main.MyAccount {
        @include desktop {
            margin-block-start: var(--header-height-desktop-mg);
        }
    }
}

#zonos {
    display: none !important;

    &.intl {
        display: block !important;
    }

    @include mobile {
        &.intl {
            display: none !important;
        }
    }
}

@include mobile {
    .xs-hidden {
        display: none;
    }

    .Popup_isVisible {
        height: calc(100% - var(--header-height));
        inset-block-start: var(--header-height);
    }

    div#checkout_pay {
        margin: 15px 0 0 15px;
    }
}

@include desktop {
    .container {
        width: 750px;
        margin-inline: auto;
        padding-inline: 15px;
    }

    .lg-hidden {
        display: none;
    }
}

@include before-tablet {
    .sm-hidden {
        display: none;
    }
}

@include after-tablet {
    .container {
        width: 970px;
    }

    .xlg-hidden {
        display: none;
    }
}

@include large-desktop {
    .container {
        width: 1170px;
    }
}

@include after-desktop {
    .container {
        max-width: 1405px;
        width: 100%;
    }
}
