/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable property-no-unknown */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

div.GdprCookieGroupItem {
    &-Wrapper {
        margin-end: 40px 24px;

        &:last-child {
            margin-block-end: 10px;
        }

        @include mobile {
            direction: ltr;
            margin-inline: 0;
        }
    }

    &-Toggle {
        display: inline-block;
        height: 29px;
        position: relative;
        width: 50px;

        input {
            height: 0;
            opacity: 0;
            width: 0;
        }
    }

    &-Slider {
        background-color: #ccc;
        cursor: pointer;
        position: absolute;
        inset: 0;
        transition: .4s;
        border-radius: 33px;

        &::before {
            background-color: white;
            inset-block-end: 4px;
            content: "";
            height: 22px;
            inset-inline-start: 4px;
            position: absolute;
            transition: .4s;
            width: 22px;
            border-radius: 50%;
        }
    }

    input:checked + &-Slider {
        background-color: var(--button-background);
    }

    input:checked + &-Slider_isEssential {
        background-color: #ccc;
        opacity: .5;
    }

    input:checked + &-Slider::before {
        transform: translateX(20px);
    }

    &-Description {
        margin-block-end: 10px;
        font-size: 16px;
    }

    &-HeadingAndToggle {
        display: flex;
        justify-content: space-between;
        margin-block-end: 15px;

        h4 {
            font-weight: 600;
            font-size: 17px;
        }
    }

    &-MoreInfo {
        color: var(--button-background);
        display: block;
        text-align: end;

        &:hover {
            color: var(--button-hover-background);
            cursor: pointer;
        }
    }
}
