/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CompanyUsers {
    .box-actions {
        display: flex;
        flex-wrap: wrap;
        margin-block-end: 20px;

        button.action_bt {
            border: 2px solid var(--button-border);
            display: inline-block;
            width: auto;
            padding: 0 10px;
            height: 32px;
            margin: 0 5px 6px 0;
            color: var(--text-gray-color);
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'regular-bold', sans-serif; 

            &._hidden {
                display: none;
            }

            &:hover {
                color: var(--text-red-color);
            }       
        }

        @include mobile {
            justify-content: space-around;
            margin-block-end: 15px;

            button.action_bt {
                width: 48%;
                margin-inline-end: 0;
            }
        }
    }

    @include desktop {
        table {
            td,th {
                font-size: 12px;
                padding: 8px;
                min-width: unset;
            }
        }
    }

    .action {
        width: auto;
        cursor: pointer;

        &::after {
            content: "|";
            padding: 0 8px;
        }

        &:last-child::after {
            display: none;
        }

        &:hover {
            color: var(--text-red-color);
        }
    }

    // &Data {
    //     max-height: 550px;
    //     overflow-y: auto;
    // }

    .add-actions {
        margin-block-start: 25px;

        @include mobile {
            margin-block-start: 0;
        }
    }

    @include mobile {
        table {
            thead {
                display: none;
            }    

            tr {
                background: transparent;
                margin-block-end: 20px;
                display: block;

                td {
                    font-size: 13px;
                    display: flex;

                    &::before {
                        content: attr(data-th) ': ';
                        min-width: 100px;
                        padding-inline-end: 10px;
                        font-weight: 600;
                    }

                    &:nth-child(even) {
                        background-color: var(--table-secondary-color);
                    }  
                }
            }
        }
    }
}
// .CompanyUs {
//     min-height: 200px;
// }
