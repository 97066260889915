/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
/* stylelint-disable csstools/use-logical */

main.CmsPage {
    &--download-brochures {
        padding-inline: 15px;
        width: 100%;

        @include mobile {
            max-width: 380px;
            margin-inline: auto;
        }

        @include desktop {
            max-width: 970px;
            margin-inline: auto;
            margin-block-start: 0;
        }

        @media (min-width: 992px) {
            padding-inline: 0;
        }

        @media (min-width: 1200px) {
            max-width: 1170px;
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
            width: 100%;
        }

        .search-container {
            margin: 30px 0 20px;

            @include desktop {
                padding: 0 15px;
            }

            input {
                width: calc(100% - 72px);
                border: 2px solid rgba(0, 0, 0, 0.2);
                font-size: 14px;
                vertical-align: top;
                height: 32px;
                padding: 0 9px;

                @include desktop {
                    width: 240px;
                }
            }

            button {
                line-height: 28px;
                margin-left: 3px;         
            }
        }

        .brochure-container { 
            @include desktop {
                display: flex;
                flex-wrap: wrap;
            }     

            .brochure {
                padding: 0 10px;
                margin-block-end: 30px;
                position: relative;

                @include desktop {
                    width: 50%;
                    padding: 0 15px;
                }

                @media (min-width: 992px) {
                    width: 33.33%;
                }

                @media (min-width: 1200px) {
                    width: 25%;
                }

                .brochure-top {
                    position: static;
                }

                .Brochure-Picture {
                    padding-block-start: 70%;
                    vertical-align: bottom;

                    .Image-Image {
                        object-fit: cover;
                    }
                }

                .brochure-image-back {
                    position: static;
                    display: none;

                    img {
                        opacity: .2;
                    }
                }

                .brochure-action {
                    position: absolute;
                    inset-inline: 0;
                    inset-block-start: 50%;
                    margin-block-start: -70px;
                    padding: 0 50px;
                    text-align: center;

                    a {
                        color: var(--btn-text-color);
                        width: 100%;

                        &+a {
                            margin: 5px 0 0;
                        }
                    }
                }

                .brochure-bottom {
                    background-color: var(--light-bg-color);
                    color: var(--text-dark-gray-color);
                    padding: 10px;
                    font-weight: 800;
                    text-align: start;
                    font-size: 12px;
                    line-height: 1.42;
                }        

                .brochure-subtitle {
                    font-weight: 400;
                    color: var(--text-gray);
                }

                &:hover {
                    .brochure-top > .Brochure-Picture {
                        display: none;
                    }

                    .brochure-image-back {
                        display: block;
                    }
                }
            }
        }

        .brochureBottmText {
            margin: 20px 0 55px;
            font-size: 22px;
            text-align: center;
            display: none;
            font-family: "regular-bold", sans-serif;
            
            a {
                color: var(--text-red-color);
            }
        }
    }
}

.LocalizationWrapper {
    &-de_DE,
    &-fr_FR,
    &-nl_NL,
    &-es_ES {
        .brochureBottmText {
            display: block !important;
        }
    }
}
