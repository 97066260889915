/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.MyAccount {
    form.Form.Vehicle-Finder {
        padding: 0;

        @include desktop {
            display: block;
        }

        select,
        input[type="text"] {
            border: 2px solid rgba(0, 0, 0, 0.2);
            height: auto;
            border-radius: 0;
            font-size: 12px;
            font-family: "Open Sans", Arial, sans-serif;
            text-transform: none;
            color: #333;
            padding: 7px;
            width: 100%;
        }

        .react-datepicker-wrapper,
        .Field {
            margin-block-end: 16px;
        }

        @include desktop {
            .Field {
                width: 48%;
                margin: 0 0 15px;
            }

            .VehicleFinder-Wrapper {
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        .VehicleFinder-Wrapper {
            label.Field-Label {
                display: block;
                font-size: 14px;
                color: var(--text-gray-color);
                margin-block-end: 5px;
            }
        }

        @include desktop {
            .Field {
                width: 24%;
            }

            button.Button {
                width: auto;
                padding: 0 20px;
            }
        }

        button.Button {
            height: auto !important;
        }
        // .react-datepicker-wrapper {
        //     width: 100%;
        //     margin-top: 7px;
        // }

        .FrontrunnerLoader-Wrapper {
            position: fixed;

            .FrontrunnerLoader {
                position: absolute;
            }
        }
        // .react-datepicker__navigation {
        //     position: absolute;
        //     width: 0;
        // }
        // .react-datepicker__triangle {
        //     position: absolute;
        // }
    }

    .customerGarge {
        .Vehicle-Finder {
            div.Loader {
                display: none;
            }
        }
    }

    .my-garage-list {
        font-size: 13px;
        line-height: 1.3;
        margin: 25px 0;
        color: #303030;

        & > div {
            border-bottom: 1px solid #dddddd;
            padding: 8px 0;

            &:last-child {
                border-bottom: 2px solid #dddddd;
            }

            .Garage-Link {
                color: inherit;
            }

            span {
                padding: 0 10px;
            }
        }

        &:last-child > div:last-child {
            border-bottom: none;
        }

        .actions {
            span {
                cursor: pointer;

                &:hover {
                    color: var(--text-red-color);
                }
            }
        }

        @media (min-width: 992px) {
            display: flex;
            margin: 0;
            padding: 2px 0;

            strong {
                display: none;
            }

            > div {
                width: 20%;

                &:last-child {
                    border-width: 1px;
                }

                span:nth-child(2) {
                    padding-left: 0;
                }
            }

            &:first-child strong {
                display: block;
                margin-block-end: 18px;
            }

            &:nth-last-child(2) {
                margin-block-end: 30px;
            }
        }
    }
}
