/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MenuOverlay-Link.disabled {
    pointer-events: none;
}

.SideMenu {
    .SideMenu-PromoBlock {
        margin-block-start: 15px;
        border: 1px solid #e2e2e2;
        padding: 15px 12px;
        font-family: regular-bold, sans-serif;
        font-size: 16px;
        text-transform: uppercase;

        .SideMenu-PromoText {
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            flex-direction: column;

            .PromoText {
                &-Wrapper {
                    text-align: center;

                    .promo-link {
                        font-family: regular-book, sans-serif;
                        color: var(--text-gray);
                        text-decoration: underline;
                        font-size: 14px;
                        margin-block-start: 5px;
                        display: block;

                        &:hover {
                            color: var(--text-gray-color);
                            text-decoration: underline;
                        }
                    }
                }

                &-Image {
                    margin-block-end: 8px;

                    svg {
                        color: #fff;
                        background: var(--text-blue-color);
                        padding: 8px;
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        border-radius: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .bread-promo {
        margin-block-start: 15px;
    }
}
