/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable number-max-precision */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    .owl-carousel {
        .content {
            position: absolute;
            z-index: 1;
            inset-inline: 15px;
            text-align: center;
        }

        .bottom {
            inset-block-end: 0;
            margin-block-end: 50px;
        }

        .top {
            inset-block-start: 0;
            margin-block-start: 50px;
        }

        .fr_slider_content_bottom {
            color: #fff;
            font-size: 40px;
            font-family: intro, sans-serif;
            line-height: 110%;
            margin-block: 10px 20px;

            @include mobile {
                zoom: 0.62;
                -moz-transform: scale(0.62);
            }
        }

        .text-shadow {
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        }

        .owl-item img {
            height: 500px;
            object-fit: cover;

            @include mobile {
                height: 280px;
            }
        }
    }

    div#fitting-instructions {
        display: none;
    }

    .installation-guides {
        background-color: #fff;
        padding: 20px;
        top: 0;
        left: 0;
        margin-block-end: 50px;

        @include mobile {
            padding: 0;
        }

        #ftg-search-sku {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-block-end: 28px;
            border-bottom: 1px solid #eee;

            input[type="text"] {
                height: 34px;
                margin-right: 10px;

                @include mobile {
                    flex: 1;
                }
            }
        }

        #ftg-results-loading {
            text-align: center;
            font-family: Regular-Book, Helvetica, Arial, sans-serif;
            font-size: 22px;
            padding: 30px 0;
            display: none;

            &.errorMessage {
                display: block;
            }
        }

        .Loader {
            display: none;

            & ~ {
                #ftg-results-loading {
                    display: block;
                }
            }

            & ~ .GuideItem {
                display: none;
            }
        }

        .GuideItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-block-start: 20px;

            .ft-block {
                padding: 0 8px;
                max-width: 20%;
                margin-block-end: 20px;

                @include mobile {
                    width: 50%;
                    max-width: 50%;
                    padding: 0;
                }
            }

            .ft-block-image {
                width: 200px;
                max-width: 100%;
            }

            .ft-block-title {
                font-size: 18px;
                font-weight: bold;
                font-family: Regular-Bold, sans-serif;
                text-align: center;
            }

            .ft-block-content {
                text-align: center;
                margin-block-start: 10px;
            }
        }
    }

    @include mobile {
        .container {
            margin: 0 auto;
            max-width: 460px;
            width: 100%;
            padding: 0 20px;
        }
    }

    .row {
        max-width: none;
        margin-inline: -15px;
        width: auto;

        &::before,
        &::after {
            content: " ";
            display: table;
        }

        &::after {
            clear: both;
        }
    }

    .col-md-12,
    .col-md-6 {
        position: relative;
        min-height: 1px;
        padding-inline: 15px;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-4 {
        width: 33.33%;

        @include mobile {
            width: 100%;
        }
    }

    .row-fluid {
        display: flex;
        flex-wrap: wrap;

        .col-12,
        .fr_slider_carousel_init {
            width: 100%;
        }
    }

    @media (min-width: 992px) {
        .col-md-6 {
            float: left;
            width: 50%;
        }
    }
    // camp page css

    .sl_t2 {
        font-size: 24px;
        margin-block-end: 0;
        color: var(--text-red-color);
        font-family: helvetica neue, Helvetica, Arial, sans-serif;
        font-weight: 400;
        text-align: center;
        padding-block-end: 10px;
        margin-bottom: 15px;

        @media (min-width: 992px) {
            padding-block-end: 0;
            margin-block-end: 0;
        }

        &::after {
            content: "";
            width: 70%;
            height: 1px;
            background-color: var(--divider-color);
            position: absolute;
            inset-block-end: -10px;
            inset-inline: 0;
            margin-inline: auto;
            display: none;
        }

        @include mobile {
            margin-block-start: 5px;
        }
    }

    .sl_sec {
        box-sizing: content-box;

        @include mobile {
            padding: 30px 1rem;
        }

        .title_with_stripes h2.sl_h2 {
            text-align: start;
            color: var(--text-light-black-color);
            margin-block-start: 0;
            font-size: 32px;
            line-height: 1.2;

            @include mobile {
                font-size: 24px;
            }

            &::after {
                display: none;
            }
        }

        .sl_h2 {
            font-family: "intro", sans-serif;
            font-size: 32px;
            line-height: 1.2;
            text-align: center;
            margin-block-end: 20px;
            padding-block-end: 10px;
            color: var(--btn-text-color);

            @media (min-width: 992px) {
                padding-block-end: 0;
            }

            &::after {
                content: "";
                width: 70%;
                height: 1px;
                background-color: var(--divider-color);
                position: absolute;
                inset-block-end: -10px;
                inset-inline: 0;
                margin-inline: auto;
                display: none;
            }

            @include mobile {
                font-size: 26px;
                line-height: 26px;
                margin-block-end: 30px;
            }
        }

        p {
            font-family: "regular", sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.42857143;
            color: var(--btn-text-color);

            a {
                color: var(--text-red-color);
            }

            @media (max-width: 992px) {
                font-weight: 400;
            }
        }

        .ad_head_cont p {
            line-height: 1.3;
            margin-block-end: 20px;
        }
    }

    .fr_img_layout_cont {
        border-bottom: 1px solid #fff;
        width: 100%;

        @include desktop {
            display: flex;
        }

        h4 {
            color: #fff;
            font-size: 22px;
            font-family: "Regular-bold", sans-serif;
            line-height: 1.2;
            margin: 0;

            @media (max-width: 992px) {
                font-size: 16px;
            }

            @include mobile {
                font-size: 14px;
            }
        }

        .text-capitalize {
            font-size: 12px;
            text-transform: capitalize;

            @include mobile {
                font-size: 10px;
            }
        }

        .fr_img_cont_left {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            width: 50%;

            @include mobile {
                width: 100%;
            }

            .fr_img_sm {
                float: inline-start;
                position: relative;
                box-sizing: border-box;
                width: 50%;
                max-width: 49.97%;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                max-height: 316px;
                overflow: hidden;
                margin-left: 0;

                .fr_img_sm img {
                    width: 100%;
                    vertical-align: top;
                }

                .fr_img_overlay {
                    position: absolute;
                    inset: 0;
                    border: 5px solid transparent;
                    background-color: rgba(0, 0, 0, 0.4);
                    text-align: center;
                    padding-block-start: 24%;
                    transition: all 0.3s ease-in 0s;

                    @media (max-width: 992px) {
                        padding-block-start: 18%;
                    }

                    .fr_text_overlay {
                        color: #fff;
                        text-transform: uppercase;
                        display: inline-block;
                        padding: 15px;
                        transition: all 0.3s ease-in 0s;
                        position: static;

                        @media (max-width: 992px) {
                            padding: 8px;
                        }
                    }
                }
            }

            .fr_img_lg {
                width: 100%;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                box-sizing: border-box;
                float: inline-start;
                position: relative;
                margin-left: 0;

                .fr_img_lg img {
                    width: 100%;
                    vertical-align: top;
                }

                .fr_img_overlay {
                    position: absolute;
                    inset: 0;
                    border: 5px solid transparent;
                    background-color: rgba(0, 0, 0, 0.4);
                    text-align: center;
                    padding-block-start: 24%;
                    transition: all 0.3s ease-in 0s;

                    @media (max-width: 992px) {
                        padding-block-start: 18%;
                    }

                    .fr_text_overlay {
                        color: #fff;
                        text-transform: uppercase;
                        display: inline-block;
                        padding: 15px;
                        transition: all 0.3s ease-in 0s;
                        position: static;

                        @media (max-width: 992px) {
                            padding: 8px;
                        }

                        h2 {
                            margin: 0;
                            text-transform: uppercase;
                            font-family: "Intro", sans-serif !important;
                            font-size: 40px;
                            line-height: 40px;
                            color: #fff;

                            @include mobile {
                                font-size: 24px;
                                line-height: 1.2;
                                margin-block-start: 8%;
                            }
                        }

                        .fr_bot_right_corner {
                            position: absolute;
                            inset-block: initial 15px;
                            right: 15px;
                            text-transform: uppercase;
                            color: #fff;

                            @include mobile {
                                text-align: end;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .fr_img_cont_right {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            width: 50%;

            @include mobile {
                width: 100%;
            }

            .fr_img_sm {
                float: inline-start;
                position: relative;
                box-sizing: border-box;
                width: 50%;
                max-width: 49.97%;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                max-height: 316px;
                overflow: hidden;
                margin-left: 0;

                .fr_img_sm img {
                    width: 100%;
                    vertical-align: top;
                }

                .fr_img_overlay {
                    position: absolute;
                    inset: 0;
                    border: 5px solid transparent;
                    background-color: rgba(0, 0, 0, 0.4);
                    text-align: center;
                    padding-block-start: 24%;
                    transition: all 0.3s ease-in 0s;

                    @media (max-width: 992px) {
                        padding-block-start: 18%;
                    }

                    .fr_text_overlay {
                        color: #fff;
                        text-transform: uppercase;
                        display: inline-block;
                        padding: 15px;
                        transition: all 0.3s ease-in 0s;
                        position: static;

                        @media (max-width: 992px) {
                            padding: 8px;
                        }
                    }
                }
            }

            .fr_img_lg {
                width: 100%;
                border-left: 1px solid transparent;
                border-top: 1px solid transparent;
                box-sizing: border-box;
                float: inline-start;
                position: relative;
                margin-left: 0;

                .fr_img_lg img {
                    width: 100%;
                    vertical-align: top;
                }

                .fr_img_overlay {
                    position: absolute;
                    inset: 0;
                    border: 5px solid transparent;
                    background-color: rgba(0, 0, 0, 0.4);
                    text-align: center;
                    padding-block-start: 24%;
                    transition: all 0.3s ease-in 0s;

                    @media (max-width: 992px) {
                        padding-block-start: 18%;
                    }

                    .fr_text_overlay {
                        color: #fff;
                        text-transform: uppercase;
                        display: inline-block;
                        padding: 15px;
                        transition: all 0.3s ease-in 0s;
                        position: static;

                        @media (max-width: 992px) {
                            padding: 8px;
                        }

                        h2 {
                            margin: 0;
                            text-transform: uppercase;
                            font-family: "Intro", sans-serif !important;
                            font-size: 40px;
                            line-height: 40px;
                            color: #fff;

                            @include mobile {
                                font-size: 24px;
                                line-height: 1.2;
                                margin-block-start: 8%;
                            }
                        }

                        .fr_bot_right_corner {
                            position: absolute;
                            inset-block: initial 15px;
                            right: 15px;
                            text-transform: uppercase;
                            color: #fff;

                            @include mobile {
                                text-align: end;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .sl_sec {
        min-height: 600px;
        padding: 50px 0;
    }

    @include mobile {
        .sl_sec {
            min-height: 450px;
            padding: 30px 0;
            background-image: none;
        }
    }

    #sl_sec_buy_sl {
        .col-md-8 {
            width: 66.66%;
            padding: 0 20px;
            margin-inline: auto;

            @media (max-width: 992px) {
                width: 80%;
                padding: 0;
            }

            @include mobile {
                width: 100%;
                padding: 0 20px;
            }
        }

        .sl_h2 {
            color: var(--btn-text-color);
        }

        p {
            color: var(--btn-text-color);

            .fr_link {
                color: var(--text-red-color);
                border-bottom: 1px solid var(--text-red-color);
            }
        }

        .sl_outfitter_cont {
            border: 1px solid #555;
            padding: 15px 25px 5px;
            margin-block-start: 30px;
            background: rgba(0, 0, 0, 0.35);

            @include mobile {
                padding-block-end: 20px !important;
            }

            h1 {
                color: var(--text-red-color);
                line-height: 1.7;
                font-size: 24px !important;
                margin: 0;
                text-transform: uppercase;

                @include mobile {
                    padding-block-end: 10px;
                }
            }

            .amfinder-horizontal {
                border: none !important;
                background: none !important;
                padding: 0 !important;

                .amfinder-title {
                    display: none;
                }

                .amfinder-toggle {
                    float: none !important;
                    padding: 0 !important;
                    display: flex !important;
                    flex-direction: row;

                    @include mobile {
                        flex-direction: column;
                        border: none;
                    }

                    .am-item {
                        display: inline-block;
                        min-width: 160px;
                        margin: 0 5px 20px 0;
                        vertical-align: top;

                        @include mobile {
                            display: block;
                            margin-block-end: 20px;
                            width: 100% !important;
                            padding: 0;
                        }

                        .dropdown-title {
                            display: none;
                        }

                        .amfinder-select-wrapper {
                            position: relative;

                            .amfinder-select {
                                width: 100%;
                                border-color: #8c8c8c !important;
                                cursor: pointer;
                                padding: 5px !important;
                                padding-inline-end: 33px !important;
                                min-height: 32px;
                                font-size: 15px !important;
                                font-weight: 700;
                                background-color: #fff !important;
                                height: 40px;
                                border: 2px solid #ccc !important;
                                font-family: helvetica neue, Helvetica,
                                    sans-serif;
                            }
                        }
                    }

                    .last.-list-item {
                        width: auto !important;
                        padding-inline-end: 0;
                        min-width: unset;
                        margin: 10px 0 0;
                        text-align: end;
                        display: block;

                        @include mobile {
                            margin-block-end: 0;
                            padding: 0;
                        }

                        .amfinder-buttons {
                            display: flex;

                            .button.action.primary {
                                background-color: var(--text-red-color);
                                border: 2px solid rgba(0, 0, 0, 0.2) !important;
                                padding: 0 10px !important;
                                line-height: 28px;
                                text-transform: uppercase !important;
                                flex: 1;
                                margin-inline-end: 5px;

                                @include mobile {
                                    padding: 0 35px !important;
                                    margin-left: 0;
                                }

                                span {
                                    display: block;
                                    line-height: 28px;
                                    font-family: helvetica neue, Helvetica,
                                        sans-serif;
                                    font-size: 14px;
                                    color: var(--btn-text-color) !important;
                                }
                            }

                            .button:last-child {
                                background-color: #939393;
                                font-family: regular-bold, sans-serif;
                                padding: 0 10px !important;
                                line-height: 28px;
                                border: 2px solid rgba(0, 0, 0, 0.2) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .text-center {
        text-align: center;
    }

    a.fr_text-dashed {
        border-bottom: 1px dotted rgba(222, 47, 12, 0.4);
        color: var(--text-red-color);
        text-transform: uppercase;
        margin: 0;
        text-decoration: none;
    }

    .Image_ratio_square {
        padding-block-end: 0;
        height: auto;
        vertical-align: top;

        .Image-Image {
            position: static;
        }
    }

    .fr_img_overlay:hover {
        border-color: #de3f0c !important;
        background: transparent !important;

        .fr_text_overlay {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    .fr_a_hide_text:hover {
        .fr_text_overlay {
            opacity: 0;
        }
    }

    a,
    a:visited {
        color: #444;
        text-decoration: none;
    }

    .fr_text_blue {
        color: var(--text-blue-color) !important;
    }

    .fr_text_green {
        color: var(--text-orange-color) !important;
    }

    .fr_text_red {
        color: var(--text-red-color) !important;
    }

    .hidden-xs,
    .hidden-sm,
    .visible-lg,
    .visible-md {
        @include mobile {
            display: none;
        }
    }

    .visible-xs,
    .hidden-lg,
    .hidden-md,
    .visible-sm {
        @include desktop {
            display: none;
        }
    }

    .visible-xs {
        @include mobile {
            display: block;
        }
    }

    .visible-lg {
        @include desktop {
            display: block;
        }
    }

    .CmsProductSlider {
        .owl-dots {
            display: none;
        }
    }

    .CmsPage-Wrapper {
        display: inline-block;
        width: 100%;

        h1.CmsPage-Heading {
            margin: 20px 0 25px;
            padding-block-end: 10px;
            line-height: 1.3;
            color: #666669;
            text-transform: uppercase;
            font-size: 28px;
            font-weight: 400;
            border-bottom: 1px solid #efefef;
        }

        .staticContentPage {
            color: #333;
            font-size: 18px;
            font-family: "regular-book", sans-serif;
            font-weight: 300;
            margin-block: 24px;
            line-height: 1.2;

            p {
                font-size: inherit;
                line-height: unset;
                word-break: break-word;
            }
        }

        li::before {
            display: none;
        }

        .btn-primary {
            color: var(--btn-text-color);
        }

        .fr_slider_content_layout {
            position: absolute;
            max-width: 820px;
            z-index: 2;
            inset-block-start: 50px;
            inset-inline: 0;
            padding: 0 10px;
            margin: 0 auto;
        }

        .fr_position_x_right {
            text-align: end;
        }

        .fr_slider_top_text {
            font-family: regular-bold;
        }

        .fr_slider_bottom_text {
            margin-block-start: 10px;

            @include mobile {
                zoom: 0.62;
            }
        }

        .fr_position_x_center {
            text-align: center;
        }
    }

    padding-inline: 15px;

    @include mobile {
        max-width: 490px;
        margin-inline: auto;
    }

    @include desktop {
        max-width: 1170px;
        width: 100%;
        margin-inline: auto;
        padding-inline: 10px;
        margin-block-start: 0;
    }

    @media (min-width: 1600px) {
        max-width: 1405px;
        width: 100%;
    }

    &--jobs,
    &--vehicles-2020-land-rover-defender-roof-rack,
    &--rewards {
        max-width: 100%;
        padding-inline: 0;

        @include desktop {
            margin-block-start: 0;
        }
    }
    
    &--jobs,
    &--vehicles-2020-land-rover-defender-roof-rack {
        @include desktop {
            margin-block-start: var(--header-height-desktop);
        }
    }

    &--important-notice {
        color: #303030;

        .CmsPage-Content {
            font-size: 15px;

            * {
                font-size: inherit !important;
            }
        }

        a:hover {
            color: var(--link-color);
        }
    }

    &--privacy-policy ul {
        padding-left: 1.5em;
        list-style: disc outside;
    }

    &--outfit-your-vehicle,
    &--vehicle 
    &--rewards {
        & ~ .Breadcrumbs {
            display: none;
        }
    }

    &--bread-finance,
    &--outfit-your-vehicle,
    &--vehicle 
    &--rewards {
        max-width: 100%;
        width: 100%;
        padding: 0;

        @include desktop {
            margin-block-start: var(--header-height-desktop);
        }

        & ~ .Breadcrumbs {
            display: none;
        }

        .bread-blocks {
            margin-block-start: 60px;

            img.Image-Image {
                width: auto !important;
                max-width: 40px;
            }
        }

        .bread-promo-btn {
            height: 40px;
            width: 180px;
            margin: 30px auto 0px auto;
            transition: all 0.5s ease;
            font-size: 18px;
            padding-block-start: 2px;

            &:empty {
                display: none;
            }
        }

        #banner_1 {
            .fr_slider_img img.Image-Image,
            .fr_slider_img_container {
                height: 480px !important;
                object-fit: cover;
                object-position: 50% 88%;

                @include mobile {
                    height: 300px !important;

                    img {
                        height: 300px;
                    }
                }
            }

            .fr_slider_content_bottom {
                text-align: center;
            }

            .fr_slider_bottom_text {
                font-family: intro, sans-serif;
                line-height: 110%;
                font-size: 50px;
                margin-block-start: 45px;
                letter-spacing: 1px;
                color: #ffffff;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            }

            .fr_slider_top_text {
                font-family: 'regular-book';
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
                letter-spacing: 0.5px;
                color: #ffffff;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            }
        }

        .container.bread-content {
            max-width: 750px;
            text-align: center;

            .row {
                @include mobile {
                    margin-inline: -10px;
                }
            }

            h2 {
                text-transform: uppercase;
                margin: 0 0 5px;
                font-size: 26px;
            }

            .subtext {
                font-size: 16px;
                color: #999999;
            }

            h3 {
                font-size: 20px;
                text-transform: uppercase;
                font-family: regular-bold, sans-serif;
                margin: 0 0 5px;
            }
        }

        .section {
            margin: 60px 0;
            padding: 60px 0;

            @include mobile {
                margin: 30px 0;
                padding: 30px 0;
            }
        }

        .bread-footer {
            background: #f9f9f9;
            color: #666666;
            font-size: 16px;
        }

        .bread-content-inner {
            position: relative;
            max-width: 750px;
            padding: 80px 25px 70px 25px;
            width: 100%;
            margin: 0 auto;
            text-align: center;

            .subtext {
                font-size: 12px;
            }

            h2 {
                font-size: 20px;
                text-transform: uppercase;
            }

            p {
                color: inherit;
            }
        }

        .bread-promo.btn.btn-primary {
            opacity: 0;
        }

        div#bread-finance-btn,
        div#bread-finance-btn-2,
        div#bread-promo-1 {
            opacity: 1;
            visibility: unset !important;
            padding: 0;

            iframe {
                position: absolute !important;
                inset-block-start: 0;
                opacity: 0;
            }

            > div a {
                font-size: 0;
                color: #fff;
                display: block;

                &::before {
                    content: "GET MY RATE";
                    top: 3px;
                    position: relative;
                    font-size: 18px;
                }
            }
        }

        div#bread-promo-1 {
            padding-block-start: 2px;
           
            & > div::before {
                display: none;
            }
        }

        div#bred_div {
            display: none;
        }

        div#bread-promo-0 {
            opacity: 1;
        }
    }

    &--workshop {
        .CmsPage-Content {
            display: inline-block;
            width: 100%;
            padding-block-end: 40px;

            &> .Image {
                img {
                    max-height: 470px;
                    object-fit: cover;
                }
            }

            .below-image-content img {
                width: auto !important;
            }

            .contact-form {
                display: none;
            }

            .contact_form {
                width: 50%;
                float: inline-end;
                margin-block-start: -600px;
                padding-left: 15px;

                input[type="text"],
                input[type="email"],
                input[type="tel"],
                textarea,
                select {
                    width: 100%;
                    padding: 5px 10px;
                    border: 2px solid rgba(0,0,0,.2);
                    color: var(--text-gray-color);
                    font-size: 14px;
                }

                input[type="text"],
                input[type="email"],
                input[type="tel"],
                select {
                    height: 40px;
                }

                select {
                    padding-inline-end: 34px;
                }

                .Field label {
                    font-weight: normal;
                    color: var(--text-light-black-color);
                    font-size: 14px;
                    font-family: regular-book, sans-serif;
                }

                .FieldSelect-Select {
                    pointer-events: unset;
                }

                ul.FieldSelect-Options {
                    display: none;
                }

                .cnt_vehicle {
                    display: flex;
                    justify-content: space-between;

                    .Field {
                        width: 24%;
                        margin-block-start: 5px;
                    }

                    @include mobile {
                        display: block;

                        .Field {
                            width: 100%;
                            margin-block-start: 10px;
                        }
                    }
                }

                .Field_type_checkbox {
                    &.vehicle {
                        margin-block-start: 15px;

                        input[type="text"] {
                            display: none;
                        }
                    }

                    &:last-child {
                        margin: 15px 0;
                    }
                }

                @media (max-width: 992px) {
                    margin-block-start: 0;
                    width: 100%;
                    padding: 0;
                }
            }

            .staticContentPage {
                @include mobile {
                    margin-block-end: 0;

                    h2 {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .sl_h1,
    .sl_h2,
    .sl_h3 {
        font-family: "intro", "Regular-bold", sans-serif;
    }

    .sl_t1,
    .sl_t2,
    .sl_h1,
    .sl_btn,
    .sl_h1,
    .sl_h2,
    .sl_h3 {
        text-align: center;
        text-transform: uppercase;
    }

    .sl_t1 {
        font-size: 32px;
        font-weight: normal;
        line-height: 32px;
        margin: 40px 0 10px;
        color: var(--text-red-color);
        font-family: Helvetica\Neue, Helvetica, sans-serif;

        @include mobile {
            font-size: 26px;
        }

        @include tablet {
            margin: 40px 0 110px;
        }
    }

    @include desktop {
        div#sl_sec_m_or.sl_sec.hidden-xs .container {
            position: absolute;
            display: block;
            z-index: 2;
            padding: 50px;
            width: 100%;
            max-width: 100% !important;
        }

        div#sl_sec_m_or {
            min-height: unset;
            padding: 0;
        }
    }

    .sl_h1 {
        font-size: 52px;
        font-weight: normal;
        line-height: 1;
        margin-block-end: 20px;
        color: #fff;

        @include mobile {
            font-size: 38px;
        }
    }

    .sl_btn_cont {
        text-align: center;
    }

    .sl_btn,
    .sl_gallery > a {
        background: rgba(0, 0, 0, 0.8);
        padding: 20px 25px;
        margin: 4px;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.3) !important;
        display: inline-block;
        text-decoration: none;
        transition: all 0.3s ease-in-out 0s;

        @include mobile {
            display: block;
            margin-block-start: 30px;
        }

        @include tablet {
            margin: 15px;
        }

        &:hover {
            background: #000;
            color: #fff !important;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
        }
    }

    .col-md-8.sl_de_cont.col-md-offset-2 {
        max-width: 800px;
        width: 100%;
        margin-inline: auto;
    }

    .sl_top,
    .sl_bot {
        border: 1px solid #555;
        padding: 15px 25px;

        @include mobile {
            padding: 5px 10px;
        }
    }

    .sl_bot {
        padding: 0;
        margin-block-start: 15px;
    }

    .nav_tab_warap {
        display: flex;
        flex-wrap: wrap;

        ul.sl_nav_tabs {
            display: none;
        }

        .tab-content {
            width: 100%;
            background: linear-gradient(#555, transparent);

            .tab-pane {
                display: none;
                padding: 20px 25px;
                min-height: 440px;

                &.active {
                    display: block;
                }

                &#sl_tab_modular {
                    background: url(../../images/slimline2/tab/racks.png)
                        no-repeat bottom center;
                    background-size: 95%;
                }

                &#sl_tab_strong {
                    .sl_inner_content {
                        width: 50%;
                        float: inline-start;
                        box-sizing: border-box;
                    }
                }

                &#sl_tab_lightweight {
                    background: url(../../images/slimline2/tab/light_weight.jpg)
                        no-repeat bottom center;
                    background-size: cover;

                    .sl_inner_content {
                        width: 50%;
                    }
                }

                &#sl_tab_low_profile {
                    background: url(../../images/slimline2/tab/low_profile.png)
                        no-repeat bottom center;
                    background-size: contain;
                }

                &#sl_tab_versatile {
                    background: url(../../images/slimline2/tab/versatile.jpg)
                        no-repeat bottom center;
                    background-size: cover;

                    .sl_inner_content {
                        width: 55%;
                        float: inline-end;
                    }
                }

                &#sl_tab_durable {
                    background: url(../../images/slimline2/tab/durable.jpg)
                        no-repeat bottom center;
                    background-size: cover;

                    .sl_inner_content {
                        width: 65%;
                    }
                }
            }
        }

        input[type="radio"] {
            display: none;
        }

        label.sl_tab_ico,
        input[type="radio"]:checked ~ label#nav_tab_modular {
            height: 135px;
            color: #fff;
            width: 16.66%;
            max-width: 106px;
            text-align: center;
            cursor: pointer;
            background-image: url(../../images/tab_nav_icons.png);
            background-repeat: no-repeat;
            margin: 0 11px;
            padding-block-end: 8px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 12px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                border-right: 1px solid #555;
                right: -11px;
                inset-block: 0;
            }

            &::before {
                display: none;
            }

            span {
                display: none;
            }

            &.nav_tab_durable::after {
                border-right: none;
            }

            &.nav_tab_modular {
                background-position-x: 0;
                order: -1;
            }

            &.nav_tab_strong {
                background-position-x: -106px;
            }

            &.nav_tab_lightweight {
                background-position-x: -210px;
            }

            &.nav_tab_low_profile {
                background-position-x: -420px;
            }

            &.nav_tab_versatile {
                background-position-x: -316px;
            }

            &.nav_tab_durable {
                background-position-x: -524px;
            }
        }

        input[type="radio"]:checked + label.sl_tab_ico,
        label.nav_tab_modular,
        input[type="radio"]:checked + label#nav_tab_modular {
            background-image: url(../../images/tab_nav_icons_active.png);
            color: var(--text-red-color);
            // background-color: rgba(0, 0, 0, .8);
            cursor: default;

            &::before {
                display: block;
                content: "";
                position: absolute;
                inset-inline: -11px;
                inset-block-end: 0;
                border-bottom: 5px solid var(--text-red-color);
            }

            span {
                display: block;
                position: absolute;
                z-index: 1;
                inset-block-end: 0;

                &::before {
                    content: "";
                    display: block;
                    border-width: 10px;
                    border-color: var(--text-red-color) transparent transparent
                        transparent;
                    border-style: solid;
                    inset-block-end: -20px;
                    position: absolute;
                    inset-inline-start: 50%;
                    margin-left: -10px;
                }
            }
        }

        input[type="radio"]:checked
        + .nav_tab_modular
        ~ .tab-content
        #sl_tab_modular,
        input[type="radio"]:checked
        + .nav_tab_strong
        ~ .tab-content
        #sl_tab_strong,
        input[type="radio"]:checked
        + .nav_tab_lightweight
        ~ .tab-content
        #sl_tab_lightweight,
        input[type="radio"]:checked
        + .nav_tab_low_profile
        ~ .tab-content
        #sl_tab_low_profile,
        input[type="radio"]:checked
        + .nav_tab_versatile
        ~ .tab-content
        #sl_tab_versatile,
        input[type="radio"]:checked
        + .nav_tab_durable
        ~ .tab-content
        #sl_tab_durable {
            display: block;
        }

        input[type="radio"]:checked + .sl_tab_ico ~ .tab-content .active {
            display: none;
        }
    }

    div#sl_sec_d_e {
        @include mobile {
            background: #161616;
        }

        .row {
            @include mobile {
                margin: 0;
            }
        }
    }

    #sl_sec_acc_prod .row.no-space {
        margin: 0;
    }

    .sl_h3 {
        font-size: 20px;
        line-height: 20px;
        margin-block-end: 10px;
    }

    .sl_de_cont {
        background: rgba(0, 0, 0, 0.8);
        padding: 15px;

        @include mobile {
            padding: 5px;
        }
    }

    &--slimline2 {
        .sl_h3 {
            color: grey;
        }

        .sl_sec p {
            font-size: 16px;
            font-family: "regular-book", sans-serif;
            margin-block-end: 10px;
            line-height: 1.3;
            font-weight: normal;
        }

        .sl_sec .sl_h2 {
            margin-block-end: 10px;
        }

        .k_main_cont {
            @include mobile {
                width: 100%;
                border: 1px solid var(--divider-color);
                margin: 20px auto 0;

                .k_header {
                    background: rgba(245, 245, 245, 0.1);
                    overflow: hidden;
                    border-bottom: 1px solid var(--divider-color);

                    .k_header_title {
                        text-align: center;
                        padding: 14px;
                        display: block;
                        float: inline-start;
                        font-weight: 700;
                        font-family: regular-bold, sans-serif;
                        font-size: 18px;
                        text-transform: uppercase;
                        color: var(--text-red-color);
                        min-height: 50px;
                    }
                }

                .k_back_btn,
                .k_next_btn {
                    padding: 10px;
                    cursor: pointer;
                    overflow: hidden;
                    width: 48px;
                    text-align: center;
                    font-size: 21px;
                    color: gray;
                }

                .k_next_btn {
                    float: inline-end;
                }

                .k_back_btn {
                    float: inline-start;
                }

                .k_content {
                    min-height: 278px;

                    .k_section.k_tabs_cont {
                        display: flex;
                        flex-direction: column;

                        li {
                            border-bottom: 1px solid var(--divider-color);
                            position: relative;
                            padding: 0;
                            line-height: 20px;
                            margin: 0;

                            a {
                                padding: 16px;
                                display: block;
                                cursor: pointer;
                                text-decoration: none;
                                color: var(--text-red-color);
                                font-size: 15px;
                                font-weight: 700;
                                text-transform: uppercase;
                                font-family: regular-bold, sans-serif;
                                position: relative;
                                z-index: 2;
                            }

                            .k_tab_arrow {
                                position: absolute;
                                inset-block-start: 15px;
                                right: 10px;
                                font-size: 12px;
                                color: var(--divider-color);
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }

    &--jobs {
        .CmsPage-Content {
            .row-fluid {
                display: block;
            }

            img.Image-Image {
                vertical-align: unset;
            }

            .row {
                margin: 0;
            }

            .jobs_text {
                line-height: 1.42;
                padding: 60px 90px;
                font-size: 28px;
                color: #333;
                text-align: center;

                @include mobile {
                    font-size: 20px;
                    padding: 40px 15px;
                }
            }

            .jobs_text_bold {
                font-family: "Regular-Bold", sans-serif;
                font-size: 36px;
                text-transform: uppercase;

                @include mobile {
                    font-size: 26px;
                }
            }

            p:empty {
                display: none;
            }

            .vacanciesWrap {
                margin-bottom: 50px;
            }

            .accordion {
                padding: 0;

                h2 {
                    font-size: 18px;
                    margin: 10px 0;

                    @include desktop {
                        font-size: 26px;
                        margin: 5px 0 2px;
                    }
                }

                .accordion-heading {
                    padding: 5px 15px;

                    a {
                        text-decoration: none;
                        display: block;
                        pointer-events: none;
                        padding-inline-end: 40px;
                    }

                    p {
                        margin-block-end: 8px;
                    }
                }

                .accordion-inner {
                    padding: 20px 25px;
                    display: flex;
                    flex-direction: column;

                    .btn.right {
                        margin-left: auto;
                    }
                }

                .accordion-group {
                    background-color: #f5f5f5;
                    margin-block-end: 20px;
                    border-bottom: 1px solid #e2e2e2;

                    &:hover {
                        .accordion-heading {
                            background: #eaeaea;
                        }
                    }

                    .accordion-body {
                        ul {
                            li {
                                list-style: disc;
                                margin-left: 25px;
                                padding-left: 5px;
                            }
                        }
                    }

                    .accordion-body,
                    input[type="checkbox"] {
                        display: none;
                    }

                    input[type="checkbox"]:checked ~ {
                        .accordion-body {
                            display: block;
                        }

                        .accordion-heading {
                            background: #eaeaea;
                        }
                    }

                    label {
                        position: absolute;
                        inset-block-start: 0;
                        inset-inline: 0;
                        height: 77px;
                        z-index: 1;
                        cursor: pointer;
                    }

                    .arrow {
                        position: absolute;
                        right: 5px;
                        inset-block-start: 7px;

                        svg {
                            color: #ccc;
                            font-size: 38px;
                        }

                        .fa-chevron-up {
                            display: none;
                        }
                    }

                    input[type="checkbox"]:checked ~ .accordion-heading .arrow {
                        .fa-chevron-up {
                            display: block;
                        }

                        .fa-chevron-down {
                            display: none;
                        }
                    }
                }
            }

            .fr_slider_img img,
            #banner_2 .fr_slider_img_container {
                height: 280px !important;

                @include desktop {
                    height: 500px !important;
                }
            }
        }
    }

    &--adventure-fish {
        #sl_sec_camping_gear {
            padding-block-end: 135px;
            background-size: 160% !important;
            background-position: bottom 0 center !important;
        }
    }

    &--legal-notice {
        .CmsPage-Wrapper h1.CmsPage-Heading {
            margin-block-end: 0;
        }
    }

    &--shipping-policies,
    &--warranty {
        @include mobile {
            .col-md-12 > .Image.Image_ratio_square.Image_hasSrc:first-child {
                display: none;
            }
        }

        @include desktop {
            .col-md-12 > .Image.Image_ratio_square.Image_hasSrc:nth-child(2) {
                display: none;
            }
        }
    }

    &--jobs div#banner_1 {
        .fr_slider_content_layout {
            .fr_slider_top_text {
                color: #fff !important;
                font-size: 34px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: bold;
                margin-block-start: 40px;

                @include mobile {
                    zoom: 0.8;
                    margin-block-start: 0;
                }
            }

            .fr_slider_content_bottom {
                color: #fff;
            }

            .fr_slider_bottom_text {
                color: #fff !important;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-family: "Regular", sans-serif;
            }
        }

        br.hide-mobile {
            display: none;
        }

        .fr_slider_img_container {
            img.Image-Image {
                @include desktop {
                    height: 500px !important;
                }
            }
        }
    }

    .bg-light-grey {
        background-color: #d0d0d0;
    }

    div#dlp2020 {
        font-size: 15px;

        div#banner_1 {
            .fr_slider_content_layout {
                @include mobile {
                    position: static;

                    .fr_slider_content_container {
                        width: 100%;
                        padding: 30px 20px;
                        background-color: #fff;
                        text-align: center;
                    }

                    .fr_slider_bottom_text {
                        font-size: 40px;
                        color: #303030;
                        font-family: intro, sans-serif;
                        line-height: 110%;
                        margin-block-start: 8px;
                    }

                    .fr_slider_top_text {
                        font-size: 24px;
                        line-height: 28px;
                        color: #303030;
                        margin-block-start: 6px;
                        text-transform: uppercase;

                        &.uppercase {
                            text-transform: uppercase;
                            margin-block-start: 6px;
                        }
                    }
                }

                br.hide-mobile {
                    display: none;
                }
            }

            .fr_slider_img_container {
                @include mobile {
                    height: 220px;
                }

                img.Image-Image {
                    height: 220px !important;

                    @include desktop {
                        height: 500px !important;
                    }
                }
            }
        }

        .section-thin {
            padding-block: 35px;
            margin: 0;
            overflow: visible;

            h2 {
                font-size: 32px;
                color: #303030;
                margin-block-start: 0;
            }
        }

        .fr_slider_content_layout {
            z-index: 2;
            max-width: 85%;

            @include mobile {
                position: static;
                text-align: center;
            }
        }

        #banner_2,
        #banner_3 {
            .fr_slider_content_container {
                width: 420px;
                background-color: rgba(255, 255, 255, 0.75);
                padding: 20px 30px 30px 30px;
                float: inline-end;

                @include mobile {
                    max-width: 100%;
                    padding: 20px 10px 30px;
                }
            }

            .fr_slider_top_text {
                font-family: helvetica neue, sans-serif;
                white-space: normal;
                font-size: 16px;
                line-height: 22px;
                margin-block-start: 10px;
                color: #000;
            }
        }

        #banner_3 .fr_position_x_left {
            float: inline-start;
        }

        .fr_slider_bottom_text {
            line-height: 110%;
        }

        .col-md-3,
        .col-md-9 {
            padding: 0 15px;
        }

        #scroll-to-form {
            color: #fff;
        }

        #stay_tuned {
            h2 {
                margin-block: 10px;
            }

            .field.newsletter label.label,
            .block.newsletter .title {
                display: none;
            }

            .field.newsletter input {
                border: 2px solid #ccc;
                height: 32px;
                padding: 0 15px;
                box-shadow: none;
                color: #555555;
                font-size: 12px;
                width: 100%;
            }

            .btn-primary.fr_newsletter_signup_btn {
                width: 100%;
                margin-block-start: 10px;
            }
        }

        .fr_products_carousel_init {
            display: flex;
            overflow-y: auto;
            margin: 0 -5px;

            .fr_product_item {
                min-width: 162px;
                margin-inline-end: 10px;
                text-align: center;
            }
        }
    }

    #engineering_container {
        .tab_icon {
            height: 112px;
            width: 105px;
            background: url(../../images/tab_nav_icons_dark.png) no-repeat;

            &:hover {
                background: url(../../images/tab_nav_icons_active.png) no-repeat;
            }
        }

        li {
            display: inline-block;
            zoom: 0.75;
            width: auto;
            padding: 0;
            margin: 0;

            a {
                cursor: pointer;
                position: relative;
                display: block;
                border-bottom: 5px solid #d0d0d0;
                text-decoration: none;
                pointer-events: none;

                .uppercase {
                    line-height: 20px;
                }
            }

            &:hover {
                .tab_icon {
                    background: url(../../images/tab_nav_icons_active.png)
                        no-repeat;
                }

                a {
                    border-bottom: 5px solid var(--text-red-color);
                    color: var(--text-red-color);

                    &::after {
                        content: "";
                        display: block;
                        border-width: 10px;
                        border-color: var(--text-red-color) transparent
                            transparent transparent;
                        border-style: solid;
                        inset-block-end: -24px;
                        position: absolute;
                        inset-inline-start: 50%;
                        margin-left: -10px;
                        z-index: 3;
                    }
                }
            }
        }

        .engineering_content {
            display: none;
            position: absolute;
            z-index: 2;
            background-color: rgba(255, 255, 255, 0.95);
            padding: 30px 30px 20px 30px;
            box-shadow: 1px 20px 15px rgba(0, 0, 0, 0.3);
            margin-inline-end: 0;
        }

        > li:nth-child(1):hover + li + li + li + div,
        > li:nth-child(2):hover + li + li + div + div,
        > li:nth-child(3):hover + li + div + div + div,
        > li:nth-child(4):hover + div + div + div + div {
            display: block;
        }
    }

    #banner_3 .fr_slider_content_layout {
        max-width: 92%;
    }

    #banner_2 .fr_slider_top_text {
        color: #333 !important;
    }

    #banner_3 .fr_slider_top_text {
        @include desktop {
            max-width: 380px;
            float: inline-end;
            text-align: start;
            line-height: 1.4 !important;
            letter-spacing: 1px;
        }
    }

    #dlp2020 h3 {
        font-weight: normal;
        inset-block-start: unset;
    }

    #banner_2 .fr_slider_bottom_text,
    #banner_3 .fr_slider_bottom_text {
        font-size: 28px !important;
        zoom: 1;
    }

    #banner_2 .fr_slider_img img,
    #banner_2 .fr_slider_img_container {
        height: 350px !important;

        @include desktop {
            height: 500px !important;
        }
    }

    #dlp2020 .section {
        padding-block: 60px;
        margin: 0;
        overflow: visible;
    }

    .bg-offwhite {
        background-color: #f6f6f6;
    }

    .bg-black {
        background-color: #222222;
    }

    .topo-texture-black {
        background: #222222 url(../../images/topo-texture-black.jpg) top left
            repeat;
    }

    .text-blue {
        color: #5188ae;
    }

    .text-white {
        color: #fff;
    }

    #dlp2020 {
        h2 {
            font-size: 32px;

            @include mobile {
                line-height: 1;
            }
        }

        .ProductCard-Content h2 {
            font-size: 14px;

            a:hover {
                color: var(--text-red-color);
            }
        }
    }

    #banner_3 .fr_slider_img img,
    #banner_3 .fr_slider_img_container {
        height: 600px !important;

        @include mobile {
            height: 300px !important;
        }
    }

    .pa-none {
        padding: 0 !important;
    }

    #banner_2 .fr_slider_bottom_text {
        font-size: 28px;
        font-family: "regular-bold" !important;
    }

    &--jobs #banner_2 .fr_slider_top_text {
        font-family: "Regular", sans-serif !important;
        font-size: 28px;
        line-height: 36px;
        margin: 140px 50px 0 50px;

        @include mobile {
            font-size: 24px;
            margin: 50px 40px 0 40px;
            zoom: 0.8;
            -moz-transform: scale(0.8);
        }
    }

    .topo-texture-grey {
        background: #f6f6f6 url(../../images/topo-texture-grey.jpg) top left
            repeat;
    }

    .text-red {
        color: var(--text-red-color);
    }

    .uppercase {
        text-transform: uppercase;
    }

    #dlp2020 h3 {
        font-size: 18px;
        line-height: 22px;
    }

    #banner_3 .fr_slider_bottom_text {
        font-family: "regular-bold" !important;
    }

    &--jobs #banner_3 .fr_slider_top_text {
        font-family: "Intro", sans-serif;
        color: #fff;
        font-size: 26px;
        line-height: 36px;
        max-width: 380px;
        float: inline-end;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: start;
        margin-block-start: 80px;

        @include mobile {
            margin-block-start: 90px;
            font-size: 26px;
            line-height: 34px;
            zoom: 0.8;
            -moz-transform: scale(0.8);
        }
    }

    .fr_slider_carousel_init_static.owl-theme {
        width: 100%;
    }

    .bg-grey {
        background-color: #484848;
    }

    #stay_tuned {
        padding-block: 25px !important;
    }

    #scroll-to-form {
        background-color: var(--text-red-color);
        width: 100%;
        text-align: center;
        padding: 10px;
        font-size: 24px;
        font-weight: bold;
    }

    #dlp2020 img.Image-Image {
        display: block;
    }

    .owl-carousel .item_14,
    #deals .owl-carousel .item {
        .content {
            max-width: 85%;
            margin: 50px auto 0;

            .fr_slider_content_top {
                font-size: 50px;
                font-family: Intro, sans-serif;
                line-height: 1;
            }

            .fr_slider_content_bottom {
                margin-block-start: 60px;
                font-size: 32px;
                font-family: Regular-Bold, sans-serif;
            }

            @include mobile {
                position: static;
                color: #333;
                padding: 30px 20px;
                margin-block: 0 !important;
                background-color: #fff;

                .fr_slider_content_top {
                    font-size: 40px;
                    margin-block-start: 0;
                    color: #303030 !important;
                    font-family: intro, sans-serif;
                    line-height: 110%;
                    zoom: 0.62;
                    text-shadow: none;
                }

                br.hide_mobile {
                    display: none;
                }

                .fr_slider_content_bottom {
                    font-size: 24px;
                    line-height: 28px;
                    color: #303030 !important;
                    font-family: regular-bold;
                    zoom: 0.8;
                    text-shadow: none;
                    text-transform: uppercase;
                    margin: 6px 0 0;
                }
            }
        }

        img {
            height: 700px;

            @include mobile {
                height: 290px;
            }
        }
    }

    .owl-carousel .item_14 {
        @include mobile {
            display: flex;
            flex-direction: column;

            .content {
                order: 1;
            }
        }
    }

    div#stay_tuned {
        display: none;
    }

    .social_newsletter_cont {
        background-color: #484848;
        display: inline-block;
        width: 100%;
        padding: 25px 20px;

        .social_newsletter_wrap {
            max-width: 85%;
            margin: 0 auto;
        }

        .title_1 h2 {
            color: var(--text-red-color);
            font-size: 38px;
            margin-block: 0 15px;
        }

        .title_2 {
            color: #fff;
            font-size: 17px;
            text-transform: uppercase;

            p {
                color: inherit;
                text-transform: inherit;
            }
        }

        form.Form.EmailFriend {
            display: flex;
            align-items: flex-start;

            .Field {
                margin-block-start: 0;
                min-width: 240px;
            }

            @include mobile {
                display: block;
            }
        }

        .EmailFriend input {
            border: 2px solid #ccc;
            height: 34px;
            padding: 0 12px;
            box-shadow: none;
            color: #555555;
            font-size: 12px;
            width: 100%;
            border-radius: 0;
        }

        button.btn.btn-primary {
            margin-left: 5px;

            @include mobile {
                margin: 10px 0 0;
                width: 100%;
                text-align: center;
            }
        }
    }

    .fr_products_carousel_init.owl-theme {
        display: flex;
        overflow-x: auto;
        width: 100%;

        .fr_product_item.item {
            min-width: 155px;
            margin-inline-end: 10px;

            a.btn.btn-primary {
                line-height: 20px;
                margin-block-start: 0;
            }
        }
    }

    .col-md-8.grid-images-right {
        padding-inline: 0;
        padding-block-start: 15px;

        .col-md-12.text-h3.text-red {
            margin-block-end: 15px;
        }

        .row-fluid {
            .col-md-12 {
                padding: 0;
            }
        }

        a.btn.btn-danger {
            margin-block-start: 20px;
            line-height: 25px;
        }
    }

    .col-md-8.grid-images-left {
        padding-inline: 0;
        padding-block-start: 15px;

        .col-md-12.text-h3.text-red {
            margin-block-end: 15px;
        }

        .row-fluid {
            .col-md-12 {
                padding: 0;
            }
        }

        a.btn.btn-danger {
            margin-block-start: 20px;
            line-height: 25px;
        }
    }

    img.Image-Image {
        vertical-align: top;
    }

    #deals .section-container {
        h3.text-h3 {
            padding: 0 10px;
            line-height: 1.1;
            margin-block-end: 8px !important;
            font-size: 26px;
        }

        h4 {
            font-size: 25px;
            color: #444;
            inset-block-start: 0;
        }
    }

    .information {
        padding: 30px 20px !important;
    }

    ul.CmsProductSlider-List {
        display: flex;
        overflow-y: auto;
        width: 100%;
        margin-block-start: 20px;

        > li {
            overflow: hidden;
            min-width: 155px;
            margin-end: 0 10px;
            background: #ededed;
            border: 1px solid #e2e2e2;
            border-bottom: 2px solid #e2e2e2;
            padding: 5px 5px 10px;

            button.AddToCart {
                min-width: unset;
                width: 100%;
            }

            .ProductCard-Stock.outofstock {
                line-height: 30px;
            }

            .ProductCard-SKU {
                margin-block-start: 7px;
            }
        }
    }

    div#cms_pro_slider_modal {
        width: 100%;
        padding: 0 12px;

        @include mobile {
            padding: 0;
        }
    }

    .container.section-container .row {
        margin-inline: 0;
    }

    #deals .btn.btn-lg {
        color: #fff;
        padding: 5px 10px;
        line-height: 25px;
        margin: 20px 5px 0 !important;
    }

    #deals .btn.btn-lg.btn-danger {
        background-color: var(--button-background);
    }

    .text-section {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1px;

        @include mobile {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    #first.information {
        background-color: #898989;
    }

    #second.information {
        background-color: #282828;
    }

    .text-h3 {
        font-size: 30px;
        font-family: "Regular-Bold", sans-serif;
    }

    .font-intro {
        font-family: Intro, sans-serif;
    }

    .ad_head_cont {
        max-width: 700px;
        margin: 0 auto;
    }

    .text-section-header {
        font-size: 30px;
        line-height: 34px;
        font-family: Intro, sans-serif;
        margin-block-end: 10px;
    }

    .information {
        color: #fff !important;

        p {
            font-size: 15px !important;
        }
    }

    .grid-main-image {
        padding: 0;
    }

    .img-fit {
        width: 100% !important;
        height: 100% !important;
    }

    .fr_banner_main_title_cont {
        h1 {
            margin: 20px 0;
        }
    }

    .bg-grey {
        background-color: #ededed;
    }

    .bg-dark-grey {
        background-color: #484848;
        color: #fff;

        h2 {
            color: #fff;
        }
    }

    #deals {
        h2 {
            font-size: 66px;
            font-size: 40px;
            margin-block-start: 15px;
        }

        h3 {
            margin: 0 !important;
        }

        h4 {
            margin: 0 !important;
        }

        p {
            font-size: 30px;
            font-size: 20px;
        }

        section {
            padding: 0;
        }

        .aligned-row {
            display: flex;
            flex-flow: row wrap;

            &::before {
                display: block;
            }

            [class*="col-"] {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }

        .aligned-row.reverse {
            flex-direction: row;
        }

        .section-container {
            margin-left: 0 !important;
            padding: 20px 0;
        }
    }

    .fr_banner_item {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
    }

    #deals-section-1 {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;

        .block-image {
            order: 2;
        }

        .block-products {
            order: 1;
        }
    }

    #deals-section-3 {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;

        .block-image {
            order: 2;
        }

        .block-products {
            order: 1;
        }
    }

    .fr_slider_img_container {
        order: 2;
        width: 100%;
    }

    @include mobile {
        .fr_slider_content_layout {
            order: 1;
            position: relative;
            max-width: 100% !important;
            padding: 20px 0;
        }
    }

    .fr_slider_content_layout.fr_position_y_bottom {
        margin: 0 auto !important;
    }

    .grid-images-left {
        padding-left: 0;
    }

    .grid-images-right {
        padding-left: 0;
    }

    .grid-image-item {
        padding-inline: 10px;
    }

    .aligned-row.reverse {
        flex-direction: column-reverse;
    }

    #deals-terms {
        font-size: 16px !important;
    }

    .aligned-row {
        [class*="col-"] {
            width: 100%;
        }
    }

    .text-danger {
        color: var(--text-red-color);
    }

    .returns_btn {
        display: none;
    }
    
    .btn-Webshop-Fitment {
        margin-bottom: 50px;
    }
}

#sl_sec_camping_banner {
    background: url(../../images/camp-banner.jpg) no-repeat bottom center;
    background-size: cover;
}

#sl_sec_buy_sl {
    background: url(../../images/defender_ls.jpg) no-repeat bottom center;
    background-size: cover;
    min-height: 700px;
}

.fr_img_lg .fr_img_overlay:hover .fr_text_overlay {
    opacity: 0;
}

hr.sl_divider {
    border-top: 1px solid var(--divider-color);
    border-bottom: 0;
    width: 70%;
    margin: 20px auto 20px;
}

.fr_slider_img {
    display: block;
    position: relative;
}

.fr_slider_img_container {
    z-index: 1;
    height: 500px;
    overflow: hidden;

    @include mobile {
        height: 280px;
    }
}

.fr_slider_img img {
    width: 100%;
    object-fit: cover;
    height: 500px;

    @include mobile {
        height: 280px;
    }
}

.fr_logos_carousel_init.lSHidden {
    display: flex;

    @include mobile {
        flex-wrap: wrap;

        li.promo {
            width: 33.33%;
        }
    }

    @media (max-width: 450px) {
        li.promo {
            width: 50%;
        }
    }
}
// .CmsPage--slimline2 {
//     @include mobile {
//         .k_main_cont {
//             .k_content {
//                 .k_section.k_tabs_cont {
//                     input[type="radio"] {
//                         display: none;
//                         &:checked + .nav_tab_modular ~ div #k_tab_1,
//                         &:checked + .nav_tab_strong ~ div #k_tab_2,
//                         &:checked + .nav_tab_lightweight ~ div #k_tab_3,
//                         &:checked + .nav_tab_low_profile ~ div #k_tab_4,
//                         &:checked + .nav_tab_versatile ~ div #k_tab_5,
//                         &:checked + .nav_tab_durable ~ div #k_tab_6 {
//                             display: block !important;
//                         }
//                     }
//                     .nav_tab_modular,
//                     input[type="radio"]:checked + .nav_tab_modular ~ div {
//                         order: 1;
//                     }
//                     .nav_tab_strong,
//                     input[type="radio"]:checked + .nav_tab_strong ~ div {
//                         order: 2;
//                     }
//                     .nav_tab_lightweight,
//                     input[type="radio"]:checked + .nav_tab_lightweight ~ div {
//                         order: 3;
//                     }
//                     .nav_tab_low_profile,
//                     input[type="radio"]:checked + .nav_tab_low_profile ~ div {
//                         order: 4;
//                     }
//                     .nav_tab_versatile,
//                     input[type="radio"]:checked + .nav_tab_versatile ~ div {
//                         order: 5;
//                     }
//                     .nav_tab_durable,
//                     input[type="radio"]:checked + .nav_tab_durable ~ div {
//                         order: 6;
//                     }
//                     label.slm_tab_ico {
//                         padding: 16px;
//                         cursor: pointer;
//                         color: var(--text-red-color);
//                         font-size: 15px;
//                         text-transform: uppercase;
//                         font-family: regular-bold, sans-serif;
//                         border-bottom: 1px solid #555;
//                         .k_tab_arrow {
//                             position: absolute;
//                             top: 15px;
//                             right: 10px;
//                             font-size: 12px;
//                             color: #555;
//                         }
//                     }
//                     ul {
//                         display: none;
//                     }
//                     .k_tab_content {
//                         > div {
//                             padding: 10px;
//                             border-bottom: 1px solid var(--divider-color);
//                         }
//                         a.fr_link {
//                             color: var(--link-color);
//                             border-bottom: 1px solid;
//                         }
//                     }
//                 }
//             }
//             .k_header {
//                 .k_back_btn {
//                     display: none;
//                 }
//                 .k_next_btn {
//                     display: none;
//                 }
//             }
//         }
//     }
//     .mounting_options_img {
//         a[id^="fr_point_obj"] {
//             width: 20px;
//             height: 20px;
//             border-radius: 50%;
//             z-index: 10;
//             position: absolute;
//             box-shadow: 0 0 0 1px #2db0bc, 0 0 0 0 #2db0bc,
//                 0 0 0 0 rgba(45, 176, 188, 0.5);
//             animation: pulse 1.5s infinite ease-in-out;
//         }
//         .fr_points_label {
//             position: absolute;
//             top: 0;
//             left: 140%;
//             white-space: nowrap;
//             font-family: "regular-bold", Helvetica Neue, Helvetica, arial,
//                 sans-serif;
//             color: #ffffff;
//             font-size: 20px;
//             text-shadow: 1px 2px 5px black;
//             text-transform: uppercase;
//         }
//         .fr_points:hover .fr_points_label {
//             color: #de2f0c;
//         }
//         .fr_points:hover {
//             animation: pulse 0.4s infinite ease-in-out;
//         }
//         @keyframes pulse {
//             50% {
//                 box-shadow: 0 0 0 1px rgba(222, 47, 12, 1),
//                     0 0 0 4px rgba(222, 47, 12, 0.5),
//                     0 0 0 6px rgba(225, 47, 12, 0.2);
//             }
//         }
//     }
// }
