/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.HomePage {
    .mobile-slide-down-nav {
        display: flex;
        flex-direction: column;
        background: var(--text-light-black-color);
        color: var(--btn-text-color);

        @include desktop {
            display: none;
        }

        .adventure {
            display: none;
        }

        .whats_new {
            order: 1;
        }

        form.Form.Vehicle-Finder {
            padding: 20px;

            .Field-SelectWrapper::after {
                display: none;
            }

            .Field {
                margin: 0 0 20px;
            }            

            select {
                height: 40px;
            }

            button.Button {
                line-height: 32px;
            }

            .Button[disabled] {
                opacity: .5;
            }
        }

        a {
            color: inherit;        

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.accordion__heading,.MenuOverlay-Link {
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid var(--text-dark-gray-color);
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    font-family: regular-bold, sans-serif;
}

.toggle-nav {
    inset-block: -15px;
    right: 0;
    border-left: 1px solid var(--text-dark-gray-color);
    min-width: 51px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-family: serif;
    font-weight: 100;
}

.accordion__panel {
    background-color: #3c3c3c;

    &[aria-hidden="true"] {
        display: none;
    }
    
    .accordion__heading {
        padding-left: 30px;
        font-family: regular-book, sans-serif;
    }

    &>div>.MenuOverlay-Link {
        padding-left: 30px;
        font-family: regular-book, sans-serif;
    }

    .accordion__panel {
        background-color: #4c4c4c;

        &>div>.MenuOverlay-Link {
            padding-left: 40px;
        }
    }
}
