/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.product-slider {
    .owl-carousel .owl-nav {
        position: static;

        button {
            &.owl-prev,
            &.owl-next {
                position: absolute;
                top: 50%;
                z-index: 1;
                width: 25px;
                height: 86px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 40px;
                margin: -51px 0 0 0;
                left: -25px;
                background: var(--primary-accent-color);
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;

                &.disabled {
                    display: none;
                }

                svg {
                    height: 30px;
                    width: 30px;
                    fill: #fff;
                }
            }

            &.owl-next {
                left: unset;
                right: -25px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }

    .child {
        background: #ededed;
        border: 1px solid #e2e2e2;
        border-bottom: 2px solid #e2e2e2;
        overflow: hidden;
        margin-block-end: 10px;
        position: relative;
        width: 200px;
        max-width: 200px;

        .ProductCard-Name {
            padding: 0 5px;
            line-height: 1.2;
            min-height: 104px;
            font-size: 13px;

            @include desktop {
                font-family: regular-bold, sans-serif;
                font-size: 14px;
                line-height: 1.1;

                > div {
                    font-family: regular-book, sans-serif;
                    margin-block-start: 2px;
                    
                    &.ProductCard-Name-Text {
                        font-family: regular-bold, sans-serif;
                    }
                }
            }
        }

        .ProductCard {
            .pro-inner-wrap {
                min-height: 30px;

                del.ProductPrice-HighPrice {
                    // display: none;
                }
            }
        }

        .item_added {
            min-width: 102px;
        }

        .ProductCard-SKU {
            margin-block-start: 8px;
        }
    }

    .pro-image {
        background: #fff;
    }

    .owl-dots {
        @include desktop {
            display: none;
        }
    }

    .owl-nav {
        @include mobile {
            display: none;
        }
    }
}

.CmsProductSlider {
    @media (max-width: 1220px) {
        padding: 0 20px;
    }

    @include mobile {
        padding: 0;
    }
}


.UpSellPopup,
#popup-root {
    .popup-overlay {
        background: var(--popup-overlay);
    }

    .popup-content {
        width: 100% !important;
        top: 0;
        padding: 30px !important;
        background-color: #fff;
        max-width: 502px;

        @include desktop {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute !important;
            border-radius: 4px;
        }

        h4 {
            font-family: regular-bold, sans-serif;
            text-align: start;
            line-height: 18px;
            font-size: 16px;
            color: #444;
            text-transform: uppercase;
            margin-block-end: 8px;

            @include desktop {
                font-size: 20px;
            }
        }

        button.Header-Button {
            position: absolute;
            right: -15px;
            inset-block-start: -20px;
            z-index: 2;
            width: 35px;
            opacity: 1;
            pointer-events: unset;
        }
    }

    .CartPopupButtons {
        &-link {
            color: var(--color-black);
            margin-block-start: 20px;
            display: block;
            text-decoration: underline;

            &:hover {
                color: var(--text-red-color);
            }
        }

        &-option {
            display: flex;
            gap: 16px;

            button {
                flex: auto;
                border-radius: 999px;
            }

            button.cart-Button {
                flex: 1;
                margin-inline-end: 10px;
                background-color: var(--btn-color);
                border-color: var(--button-border);
                color: var(--btn-text-color);
            }
        }
    }

    .popup-overlay {
        display: block !important;
    }
}

.product_also_like {
    .product-slider {
        .owl-stage {
            margin: 0 auto;
        }

        .owl-dots {
            display: block;
        }

        .owl-nav {
            @include mobile {
                display: none;
            }
        }

        .child {
            padding: 6px;
            width: 250px;
            max-width: 250px;

            @include mobile {
                width: 157px;
                max-width: 157px;
            }
        }
    }
}

.UpSellPopup .popup-content,
#popup-root .popup-content {
    .child {
        width: 147px;
        max-width: 147px;

        @include mobile {
            width: 159px;
            max-width: 159px;
        }
    }
}

#popup-root .popup-content {
    .product-slider {
        div.Loader {
            position: relative;
            margin: 60px 0;
            inset-block-start: -20px;
        }
    }
}

.productLinks_wrp .product-slider .child {
    width: 157px;
    max-width: 157px;
}

@media (min-width: 811px) and (max-width: 1160px) {
    div.ProductPage-Wrapper .product_also_like .product-slider .child {
        width: 157px;
        max-width: 157px;

        .pro_inner_top,
        .pro_inner_bot {
            display: block;

            .pro-inner-wrap {
                margin-block-end: 8px;
                padding: 0;
            }

            .ProductCard-SKU {
                margin: 0;
            }
        }
    }
}
