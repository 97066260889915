/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.Maintenance {
    .ContentWrapper {
        text-align: center;

        h1 {
            text-transform: unset;
        }

        h2 {
            font-family: regular-book, sans-serif;
            font-weight: normal;
            text-transform: unset;
        }
    }

    .mnt-logo {
        width: 300px;
        display: inline-block;
        margin-block-end: 50px;
    }

    .coffee-container {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-block-start: 50px;

        .coffee-cup {
            width: 80px;
        }
    }

    .steam-container {
        width: 60px;
        height: 50px;
        margin-left: -20px;
        display: inline-block;
    }

    .squiggle-container {
        width: 14px;
        height: 30px;
        display: inline-block;
    }

    .squiggle-container-1 {
        transform: translateY(10px);
    }

    .squiggle-container-1 .squiggle {
        animation: move-and-fade 2.5s linear infinite;
        animation-delay: 0.2s;
        width: 10px;
    }

    @keyframes move-and-fade {
        0% {
            opacity: 0;
            transform: translateY(0);
        }

        50% {
            opacity: 1;
        }

        75% {
            opacity: 0;
        }

        100% {
            transform: translateY(-10px);
            opacity: 0;
        }
    }

    .squiggle-container-2 {
        transform: translateY(0px);
    }

    .squiggle-container-2 .squiggle {
        animation: move-and-fade 2.5s linear infinite;
        animation-delay: 0s;
        width: 10px;
    }

    @keyframes move-and-fade {
        0% {
            opacity: 0;
            transform: translateY(0);
        }

        50% {
            opacity: 1;
        }

        75% {
            opacity: 0;
        }

        100% {
            transform: translateY(-20px);
            opacity: 0;
        }
    }

    .squiggle-container-3 {
        transform: translateY(15px);
    }

    .squiggle-container-3 .squiggle {
        animation: move-and-fade 2.5s linear infinite;
        animation-delay: 0.4s;
        width: 10px;
    }

    @keyframes move-and-fade {
        0% {
            opacity: 0;
            transform: translateY(0);
        }

        50% {
            opacity: 1;
        }

        75% {
            opacity: 0;
        }

        100% {
            transform: translateY(-15px);
            opacity: 0;
        }
    }

    .squiggle {
        stroke-dasharray: 100;
    }

    .squiggle path {
        stroke: #5D5D5D;
    }

    .a {
        fill: #DE2F0C;
    }

    .b {
        fill: #272727;
    }

    &~div, 
    &~section, 
    &~footer,
    &~main {
        display: none;
    }
}
