/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

form.Form.Vehicle-Finder {
    padding: 15px;

    label.Field-Label {
        display: none;
    }

    .ShowProducts {
        display: none;
    }
    
    @include desktop {
        display: flex;
        padding-block-start: 3px;

        .VehicleFinder-Wrapper {
            display: flex;
            max-width: calc(100% - 120px);

            .Field {
                margin: 0 12px 0 0;
                width: 265px;
                max-width: 24%;

                &:first-child:last-child {
                    max-width: 100%;
                }
            }

            .Field-SelectOption {
                padding-left: 5px;
            }
        }

        button.clear {
            margin-left: 5px;
        }

        .VehicleFinder-Button.Button {
            width: auto;
            padding: 0 10px;
        }
    }

    .Field-SelectWrapper::after {
        display: none;
    }

    .Field {
        margin: 0 0 20px;
    }

    select {
        cursor: pointer;
        padding: 5px;
        min-height: 32px;
        font-size: 15px;
        color: var(--text-gray-color);
        height: 36px;
        border: 1px solid #8c8c8c;
        font-family: regular-bold, sans-serif;
        border-radius: 3px;
        text-transform: uppercase;
        padding-inline-end: 33px;
        pointer-events: unset;

        option {
            font-family: regular-book, sans-serif;
        }
    }

    ul.FieldSelect-Options {
        display: none;
    }

    .VehicleFinder-Button {
        @include mobile {
            width: 100%;
        }
    }

    button.clear {
        text-align: center;
        border-radius: 999px;

        @include mobile {
            width: 100%;
            margin-block-start: 10px;
            line-height: 32px;
        }
    }

    .Button {
        font-size: 16px;
        line-height: 32px;
        padding: 0 10px;
        width: 100%;
        color: var(--btn-text-color);
    }

    button[disabled] {
        opacity: 0.5;
        pointer-events: none;
        display: none;
    }
}

.MenuPage form.Form.Vehicle-Finder {
    button.Button {
        @include mobile {
            line-height: 32px;
        }
    }
}

header form.Form.Vehicle-Finder ul.FieldSelect-Options {
    position: absolute;
    background: #fff;
    padding: 0;
    border: none;

    li {
        padding: 10px;
    }
}

.LocalizationWrapper {
    &-de_DE,
    &-fr_FR,
    &-es_ES {
        @include desktop {
            form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
                max-width: calc(100% - 190px);
            }

            .HomePage form.Form.Vehicle-Finder .VehicleFinder-Wrapper {
                max-width: 100%;
            }
        }
    }
}

.vehicleSelected {
    display: flex;
    min-height: 53px;
    align-items: center;

    .vehicleText {
        font-family: regular-bold,sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        color: #333;
    }

    .VehicleFinder-Button {
        margin-left: 20px;
        font-size: 15px;

        &.showPro {
            a {
                /* stylelint-disable-next-line declaration-no-important */
                font-size: 15px !important;
            }
        }
    }
}
