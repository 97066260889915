/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.CompanyRoles {
    .box-actions {
        display: flex;
        flex-wrap: wrap;
        margin-block-end: 20px;

        button.action_bt {
            border: 2px solid var(--button-border);
            display: inline-block;
            width: auto;
            padding: 0 10px;
            height: 32px;
            margin: 0 5px 6px 0;
            color: var(--text-gray-color);
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'regular-bold', sans-serif; 

            &._hidden {
                display: none;
            }

            &:hover {
                color: var(--text-red-color);
            }       
        }

        @include mobile {
            justify-content: space-around;
            margin-block-end: 15px;

            button.action_bt {
                width: 48%;
                margin-inline-end: 0;
            }
        }
    }

    .action {
        cursor: pointer;
        width: auto;

        &::after {
            content: "|";
            padding: 0 8px;
        }

        &:last-child::after {
            display: none;
        }

        &:hover {
            color: var(--text-red-color);
        }
    }

    @include desktop {
        table td,th {
            font-size: 12px;
            padding: 8px;
            min-width: unset;
        }
    }    

    @include mobile {
        table {
            thead {
                display: none;
            }    

            tr {
                background: transparent;
                margin-block-end: 20px;
                display: block;

                td {
                    font-size: 13px;
                    display: flex;

                    &::before {
                        content: attr(data-th) ': ';
                        min-width: 100px;
                        padding-inline-end: 10px;
                        font-weight: 600;
                    }

                    &:nth-child(even) {
                        background-color: var(--table-secondary-color);
                    }  
                }
            }
        }
    }
}

.addRoles {
    .Field {
        .ExpandableContent-Heading {
            border-bottom: 1px solid var(--border-color-heading);
            padding-block-end: 10px;
            font-size: 22px;
            font-family: 'regular-book', sans-serif;
            font-weight: normal;
        }

        input[type=text] {
            width: 100%;
        }

        label {
            font-size: 14px;
            line-height: 18px;
            color: var(--text-light-black-color);
            font-weight: 400;
            margin-block-end: 4px;
        }
    }

    .box-actions {
        display: flex;
        flex-wrap: wrap;
        margin-block-start: 20px;

        button.action {
            border: 2px solid var(--button-border);
            display: inline-block;
            width: auto;
            padding: 0 10px;
            height: 32px;
            margin: 0 5px 6px 0;
            color: var(--text-gray-color);
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'regular-bold', sans-serif; 

            &._hidden {
                display: none;
            }

            &:hover {
                color: var(--text-red-color);
            }       
        }

        @include mobile {
            justify-content: space-around;
            margin-block-end: 15px;

            button.action {
                width: 48%;
                margin-inline-end: 0;
            }
        }
    }

    .jstree-container-ul {
        font-size: 12px;

        .jstree-anchor {
            display: inline-block;
            padding: 6px 0 0 6px;
            height: auto;
            color: var(--text-light-black-color);
            margin-block-start: 3px;
            inset-block-start: -6px;
            max-width: calc(100% - 42px);
            vertical-align: top;

            &.company-admin {
                margin-left: -.2rem;
            }

            .jstree-checkbox {
                background-color: transparent;
                background-image: url(../../images/icon-tree.png);
                background-position: top right;
                background-repeat: no-repeat;
                height: 16px;
                margin: 5px 8px -2px 4px;
                position: relative;
                width: 16px;
                display: inline-block;
            }

            &.true .jstree-checkbox {
                background-position: -18px 0;
            }

            svg {
                margin-inline-end: 10px;
                vertical-align: middle;
            }

            div.name {
                display: inline-block;
            }

            .description {
                display: none;
                position: absolute;
                background: #f4f4f4;
                border: 1px solid #999999;
                border-radius: 1px;
                color: var(--text-light-black-color);
                padding: 7px 15px;
                z-index: 1;
                top: 36px;
                left: -23px;
                font-size: 12px;
                min-width: 200px;

                &::before {
                    content: "";
                    border: 10px solid transparent;
                    height: 0;
                    width: 0;
                    position: absolute;
                    margin-block-start: -21px;
                    border-bottom-color: #eeeeee;
                    top: 1px;
                    left: 30px;             
                }

                @include desktop {
                    left: unset;
                    top: 0;
                    right: -246px;

                    &::before {
                        top: 50%;
                        left: -21px;
                        border-bottom-color: transparent;
                        border-right-color: #eeeeee;
                        margin-block-start: -10px;
                    }
                }
            }      
        }

        .Selected > .jstree-anchor {
            background-color: #808080;
            color: #fff;

            svg {
                color: #fff;
            }
        }

        li {
            &.jstree-node > .jstree-icon {
                &::before {
                    content: "";
                    width: 0;
                    height: 25px;
                    display: inline-block;
                    border-radius: 50%;
                    margin-left: 8px;
                    opacity: 0;
                }
            }   
        }

        ul li {
            background: none;
            border-left: 1px dashed var(--tree-border-color);
            margin-left: 24px;
            padding: .5rem 0;
            position: relative;
            margin-block-end: 0;

            &:last-child {
                border-left: none;
                padding-block-end: 0;

                &::after {
                    border-left: 1px dashed var(--tree-border-color);
                    content: '';
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            &.jstree-node > .jstree-icon {
                &::after {
                    border-top: 1px dashed var(--tree-border-color);
                    content: '';
                    height: .1rem;
                    position: absolute;
                    left: 0;
                    top: 1px;
                    width: 14px;
                }
            }

            &.jstree-leaf.jstree-node > .jstree-icon {
                width: 41px;
                display: inline-block;
                height: 25px;
                inset-block-start: -12px;

                &::after {
                    right: 0;
                    inset-block-start: 27px;
                    width: 100%;
                }

                &::before {
                    display: none;
                }
            }
        }

        &.collapse {
            ul ul {
                display: none;
            }
        }
    }

    .add-actions {
        display: flex;
        justify-content: space-between;

        button {
            width: auto;
        }
    }
}
