/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable block-closing-brace-empty-line-before */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable indentation */
/* stylelint-disable declaration-no-important */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable rule-empty-line-before */

#meetOurRacks {
    .img_sec .Image {
        padding-bottom: 0 !important;
        display: block;
        img.Image-Image {
            width: 100% !important;
            position: static;
            height: 500px !important;
            object-fit: cover;
            object-position: top right;
            display: block;
            @include mobile {
                height: 520px !important;
            }
        }
    }
    .imgSec {
        background-color: #ECECEC;
        img {
            width: auto;
            height: 600px;
            display: block;
            margin: 0 auto;
            @include mobile {
                height: 520px;
                object-fit: contain;
            }
        }
    }
    .content-wrapper {
        position: absolute;
        top: 20px;
        right: 80px;
        z-index: 2;
        max-width: 400px;
        margin-top: 15px;
        color: #303030;
        background-color: rgba(236, 236, 237, 0.85);
        padding: 0px 20px 20px 20px;
        border-radius: 10px;
        h3 {
            font-size: 18px;
            font-family: regular-bold;
            text-shadow: 0 0 5px #ebebeb;
            text-transform: uppercase;
            @include mobile {
                margin-bottom: 10px;
            }
        }
        h4 {
            font-family: Intro;
            text-shadow: 0 0 17px #ebebeb;
            font-size: 28px;
            margin: 12px 0 4px 0;
            line-height: 1.1;
        }
        p {
            font-family: regular-book;
            font-size: 16px;
        }
        @include mobile {
            top: 0;
            left: 25px;
            right: 25px;
            margin-top: 0;
            background-color: transparent;
        }
    }

    .slick-dots {
        bottom: 10px;
        background: rgba(0, 0, 0, 0.2);
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 999px;
        li {
            margin: 0 2px;
            button::before {
                color: #fff;
                opacity: .8;
                font-size: 13px;
            }
        }
    }
    .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #aaa;
    }
}
