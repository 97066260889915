/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 2rem;
        }
    }

    &-ProductName {
        font-weight: 700;
        font-size: 2rem;

        @include mobile {
            font-size: 1.8rem;
        }
    }

    &-Content {
        @include mobile {
            margin-block-start: 2rem;
        }
    }

    &-Rating {
        margin: 1rem 0 1.3rem;
        text-align: start;
        direction: rtl;
        border: 0;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        &:first-child {
            @include mobile {
                margin-block-start: 0;
            }
        }
    }

    &-Legend {
        border-bottom: 0;
        padding-block-end: .3rem;
        font-weight: 700;
        font-size: 1.05rem;
    }

    & &-Button {
        margin-block-start: 1rem;

        @include mobile {
            margin-block-start: 2rem;
            width: 100%;
        }
    }

    input,
    textarea {
        width: 100%;
    }
}

.EmailFriend {
    .hide {
        display: none;
    }
    // &-Buttons {
    //   .hide {
    //     display: none;
    //   }
    // }

    & > .Remove:first-child {
        display: none;
    }
}
