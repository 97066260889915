/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.flag {
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
}
  
.flag.flag-32 {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url('../../images/sprite-flags-32x32.png');
    background-repeat: no-repeat;
    zoom: .8;
    margin-inline-end: 6px;
}
    
.flag.flag-32.flag-abkhazia {background-position: 0 0}

.flag.flag-32.flag-basque-country {background-position: -32px 0}

.flag.flag-32.flag-british-antarctic-territory {background-position: -64px 0}

.flag.flag-32.flag-commonwealth {background-position: -96px 0}

.flag.flag-32.flag-england {background-position: -128px 0}

.flag.flag-32.flag-gosquared {background-position: -160px 0}

.flag.flag-32.flag-kosovo {background-position: -192px 0}

.flag.flag-32.flag-mars {background-position: -224px 0}

.flag.flag-32.flag-nagorno-karabakh {background-position: -256px 0}

.flag.flag-32.flag-nato {background-position: -288px 0}

.flag.flag-32.flag-northern-cyprus {background-position: -320px 0}

.flag.flag-32.flag-olympics {background-position: -352px 0}

.flag.flag-32.flag-red-cross {background-position: -384px 0}

.flag.flag-32.flag-scotland {background-position: -416px 0}

.flag.flag-32.flag-somaliland {background-position: -448px 0}

.flag.flag-32.flag-south-ossetia {background-position: -480px 0}

.flag.flag-32.flag-united-nations {background-position: -512px 0}

.flag.flag-32.flag-unknown {background-position: 0 -32px}

.flag.flag-32.flag-wales {background-position: -32px -32px}

.flag.flag-32.flag-ad {background-position: -64px -32px}

.flag.flag-32.flag-ae {background-position: -96px -32px}

.flag.flag-32.flag-af {background-position: -128px -32px}

.flag.flag-32.flag-ag {background-position: -160px -32px}

.flag.flag-32.flag-ai {background-position: -192px -32px}

.flag.flag-32.flag-al {background-position: -224px -32px}

.flag.flag-32.flag-am {background-position: -256px -32px}

.flag.flag-32.flag-an {background-position: -288px -32px}

.flag.flag-32.flag-ao {background-position: -320px -32px}

.flag.flag-32.flag-aq {background-position: -352px -32px}

.flag.flag-32.flag-ar {background-position: -384px -32px}

.flag.flag-32.flag-as {background-position: -416px -32px}

.flag.flag-32.flag-at {background-position: -448px -32px}

.flag.flag-32.flag-au {background-position: -480px -32px}

.flag.flag-32.flag-aw {background-position: -512px -32px}

.flag.flag-32.flag-ax {background-position: 0 -64px}

.flag.flag-32.flag-az {background-position: -32px -64px}

.flag.flag-32.flag-ba {background-position: -64px -64px}

.flag.flag-32.flag-bb {background-position: -96px -64px}

.flag.flag-32.flag-bd {background-position: -128px -64px}

.flag.flag-32.flag-be {background-position: -160px -64px}

.flag.flag-32.flag-bf {background-position: -192px -64px}

.flag.flag-32.flag-bg {background-position: -224px -64px}

.flag.flag-32.flag-bh {background-position: -256px -64px}

.flag.flag-32.flag-bi {background-position: -288px -64px}

.flag.flag-32.flag-bj {background-position: -320px -64px}

.flag.flag-32.flag-bl {background-position: -352px -64px}

.flag.flag-32.flag-bm {background-position: -384px -64px}

.flag.flag-32.flag-bn {background-position: -416px -64px}

.flag.flag-32.flag-bo {background-position: -448px -64px}

.flag.flag-32.flag-br {background-position: -480px -64px}

.flag.flag-32.flag-bs {background-position: -512px -64px}

.flag.flag-32.flag-bt {background-position: 0 -96px}

.flag.flag-32.flag-bw {background-position: -32px -96px}

.flag.flag-32.flag-by {background-position: -64px -96px}

.flag.flag-32.flag-bz {background-position: -96px -96px}

.flag.flag-32.flag-ca {background-position: -128px -96px}

.flag.flag-32.flag-cc {background-position: -160px -96px}

.flag.flag-32.flag-cd {background-position: -192px -96px}

.flag.flag-32.flag-cf {background-position: -224px -96px}

.flag.flag-32.flag-cg {background-position: -256px -96px}

.flag.flag-32.flag-ch {background-position: -288px -96px}

.flag.flag-32.flag-ci {background-position: -320px -96px}

.flag.flag-32.flag-ck {background-position: -352px -96px}

.flag.flag-32.flag-cl {background-position: -384px -96px}

.flag.flag-32.flag-cm {background-position: -416px -96px}

.flag.flag-32.flag-cn {background-position: -448px -96px}

.flag.flag-32.flag-co {background-position: -480px -96px}

.flag.flag-32.flag-cr {background-position: -512px -96px}

.flag.flag-32.flag-cu {background-position: 0 -128px}

.flag.flag-32.flag-cv {background-position: -32px -128px}

.flag.flag-32.flag-cw {background-position: -64px -128px}

.flag.flag-32.flag-cx {background-position: -96px -128px}

.flag.flag-32.flag-cy {background-position: -128px -128px}

.flag.flag-32.flag-cz {background-position: -160px -128px}

.flag.flag-32.flag-de {background-position: -192px -128px}

.flag.flag-32.flag-dj {background-position: -224px -128px}

.flag.flag-32.flag-dk {background-position: -256px -128px}

.flag.flag-32.flag-dm {background-position: -288px -128px}

.flag.flag-32.flag-do {background-position: -320px -128px}

.flag.flag-32.flag-dz {background-position: -352px -128px}

.flag.flag-32.flag-ec {background-position: -384px -128px}

.flag.flag-32.flag-ee {background-position: -416px -128px}

.flag.flag-32.flag-eg {background-position: -448px -128px}

.flag.flag-32.flag-eh {background-position: -480px -128px}

.flag.flag-32.flag-er {background-position: -512px -128px}

.flag.flag-32.flag-es {background-position: 0 -160px}

.flag.flag-32.flag-et {background-position: -32px -160px}

.flag.flag-32.flag-eu {background-position: -64px -160px}

.flag.flag-32.flag-fi {background-position: -96px -160px}

.flag.flag-32.flag-fj {background-position: -128px -160px}

.flag.flag-32.flag-fk {background-position: -160px -160px}

.flag.flag-32.flag-fm {background-position: -192px -160px}

.flag.flag-32.flag-fo {background-position: -224px -160px}

.flag.flag-32.flag-fr {background-position: -256px -160px}

.flag.flag-32.flag-ga {background-position: -288px -160px}

.flag.flag-32.flag-gb {background-position: -320px -160px}

.flag.flag-32.flag-gd {background-position: -352px -160px}

.flag.flag-32.flag-ge {background-position: -384px -160px}

.flag.flag-32.flag-gg {background-position: -416px -160px}

.flag.flag-32.flag-gh {background-position: -448px -160px}

.flag.flag-32.flag-gi {background-position: -480px -160px}

.flag.flag-32.flag-gl {background-position: -512px -160px}

.flag.flag-32.flag-gm {background-position: 0 -192px}

.flag.flag-32.flag-gn {background-position: -32px -192px}

.flag.flag-32.flag-gq {background-position: -64px -192px}

.flag.flag-32.flag-gr {background-position: -96px -192px}

.flag.flag-32.flag-gs {background-position: -128px -192px}

.flag.flag-32.flag-gt {background-position: -160px -192px}

.flag.flag-32.flag-gu {background-position: -192px -192px}

.flag.flag-32.flag-gw {background-position: -224px -192px}

.flag.flag-32.flag-gy {background-position: -256px -192px}

.flag.flag-32.flag-hk {background-position: -288px -192px}

.flag.flag-32.flag-hn {background-position: -320px -192px}

.flag.flag-32.flag-hr {background-position: -352px -192px}

.flag.flag-32.flag-ht {background-position: -384px -192px}

.flag.flag-32.flag-hu {background-position: -416px -192px}

.flag.flag-32.flag-ic {background-position: -448px -192px}

.flag.flag-32.flag-id {background-position: -480px -192px}

.flag.flag-32.flag-ie {background-position: -512px -192px}

.flag.flag-32.flag-il {background-position: 0 -224px}

.flag.flag-32.flag-im {background-position: -32px -224px}

.flag.flag-32.flag-in {background-position: -64px -224px}

.flag.flag-32.flag-iq {background-position: -96px -224px}

.flag.flag-32.flag-ir {background-position: -128px -224px}

.flag.flag-32.flag-is {background-position: -160px -224px}

.flag.flag-32.flag-it {background-position: -192px -224px}

.flag.flag-32.flag-je {background-position: -224px -224px}

.flag.flag-32.flag-jm {background-position: -256px -224px}

.flag.flag-32.flag-jo {background-position: -288px -224px}

.flag.flag-32.flag-jp {background-position: -320px -224px}

.flag.flag-32.flag-ke {background-position: -352px -224px}

.flag.flag-32.flag-kg {background-position: -384px -224px}

.flag.flag-32.flag-kh {background-position: -416px -224px}

.flag.flag-32.flag-ki {background-position: -448px -224px}

.flag.flag-32.flag-km {background-position: -480px -224px}

.flag.flag-32.flag-kn {background-position: -512px -224px}

.flag.flag-32.flag-kp {background-position: 0 -256px}

.flag.flag-32.flag-kr {background-position: -32px -256px}

.flag.flag-32.flag-kw {background-position: -64px -256px}

.flag.flag-32.flag-ky {background-position: -96px -256px}

.flag.flag-32.flag-kz {background-position: -128px -256px}

.flag.flag-32.flag-la {background-position: -160px -256px}

.flag.flag-32.flag-lb {background-position: -192px -256px}

.flag.flag-32.flag-lc {background-position: -224px -256px}

.flag.flag-32.flag-li {background-position: -256px -256px}

.flag.flag-32.flag-lk {background-position: -288px -256px}

.flag.flag-32.flag-lr {background-position: -320px -256px}

.flag.flag-32.flag-ls {background-position: -352px -256px}

.flag.flag-32.flag-lt {background-position: -384px -256px}

.flag.flag-32.flag-lu {background-position: -416px -256px}

.flag.flag-32.flag-lv {background-position: -448px -256px}

.flag.flag-32.flag-ly {background-position: -480px -256px}

.flag.flag-32.flag-ma {background-position: -512px -256px}

.flag.flag-32.flag-mc {background-position: 0 -288px}

.flag.flag-32.flag-md {background-position: -32px -288px}

.flag.flag-32.flag-me {background-position: -64px -288px}

.flag.flag-32.flag-mf {background-position: -96px -288px}

.flag.flag-32.flag-mg {background-position: -128px -288px}

.flag.flag-32.flag-mh {background-position: -160px -288px}

.flag.flag-32.flag-mk {background-position: -192px -288px}

.flag.flag-32.flag-ml {background-position: -224px -288px}

.flag.flag-32.flag-mm {background-position: -256px -288px}

.flag.flag-32.flag-mn {background-position: -288px -288px}

.flag.flag-32.flag-mo {background-position: -320px -288px}

.flag.flag-32.flag-mp {background-position: -352px -288px}

.flag.flag-32.flag-mq {background-position: -384px -288px}

.flag.flag-32.flag-mr {background-position: -416px -288px}

.flag.flag-32.flag-ms {background-position: -448px -288px}

.flag.flag-32.flag-mt {background-position: -480px -288px}

.flag.flag-32.flag-mu {background-position: -512px -288px}

.flag.flag-32.flag-mv {background-position: 0 -320px}

.flag.flag-32.flag-mw {background-position: -32px -320px}

.flag.flag-32.flag-mx {background-position: -64px -320px}

.flag.flag-32.flag-my {background-position: -96px -320px}

.flag.flag-32.flag-mz {background-position: -128px -320px}

.flag.flag-32.flag-na {background-position: -160px -320px}

.flag.flag-32.flag-nc {background-position: -192px -320px}

.flag.flag-32.flag-ne {background-position: -224px -320px}

.flag.flag-32.flag-nf {background-position: -256px -320px}

.flag.flag-32.flag-ng {background-position: -288px -320px}

.flag.flag-32.flag-ni {background-position: -320px -320px}

.flag.flag-32.flag-nl {background-position: -352px -320px}

.flag.flag-32.flag-no {background-position: -384px -320px}

.flag.flag-32.flag-np {background-position: -416px -320px}

.flag.flag-32.flag-nr {background-position: -448px -320px}

.flag.flag-32.flag-nu {background-position: -480px -320px}

.flag.flag-32.flag-nz {background-position: -512px -320px}

.flag.flag-32.flag-om {background-position: 0 -352px}

.flag.flag-32.flag-pa {background-position: -32px -352px}

.flag.flag-32.flag-pe {background-position: -64px -352px}

.flag.flag-32.flag-pf {background-position: -96px -352px}

.flag.flag-32.flag-pg {background-position: -128px -352px}

.flag.flag-32.flag-ph {background-position: -160px -352px}

.flag.flag-32.flag-pirate-black {background-position: -192px -352px}

.flag.flag-32.flag-pirate-white {background-position: -224px -352px}

.flag.flag-32.flag-pk {background-position: -256px -352px}

.flag.flag-32.flag-pl {background-position: -288px -352px}

.flag.flag-32.flag-pn {background-position: -320px -352px}

.flag.flag-32.flag-pr {background-position: -352px -352px}

.flag.flag-32.flag-ps {background-position: -384px -352px}

.flag.flag-32.flag-pt {background-position: -416px -352px}

.flag.flag-32.flag-pw {background-position: -448px -352px}

.flag.flag-32.flag-py {background-position: -480px -352px}

.flag.flag-32.flag-qa {background-position: -512px -352px}

.flag.flag-32.flag-ro {background-position: 0 -384px}

.flag.flag-32.flag-rs {background-position: -32px -384px}

.flag.flag-32.flag-ru {background-position: -64px -384px}

.flag.flag-32.flag-rw {background-position: -96px -384px}

.flag.flag-32.flag-sa {background-position: -128px -384px}

.flag.flag-32.flag-sb {background-position: -160px -384px}

.flag.flag-32.flag-sc {background-position: -192px -384px}

.flag.flag-32.flag-sd {background-position: -224px -384px}

.flag.flag-32.flag-se {background-position: -256px -384px}

.flag.flag-32.flag-sg {background-position: -288px -384px}

.flag.flag-32.flag-sh {background-position: -320px -384px}

.flag.flag-32.flag-si {background-position: -352px -384px}

.flag.flag-32.flag-sk {background-position: -384px -384px}

.flag.flag-32.flag-sl {background-position: -416px -384px}

.flag.flag-32.flag-sm {background-position: -448px -384px}

.flag.flag-32.flag-sn {background-position: -480px -384px}

.flag.flag-32.flag-so {background-position: -512px -384px}

.flag.flag-32.flag-sr {background-position: 0 -416px}

.flag.flag-32.flag-ss {background-position: -32px -416px}

.flag.flag-32.flag-st {background-position: -64px -416px}

.flag.flag-32.flag-sv {background-position: -96px -416px}

.flag.flag-32.flag-sy {background-position: -128px -416px}

.flag.flag-32.flag-sz {background-position: -160px -416px}

.flag.flag-32.flag-tc {background-position: -192px -416px}

.flag.flag-32.flag-td {background-position: -224px -416px}

.flag.flag-32.flag-tf {background-position: -256px -416px}

.flag.flag-32.flag-tg {background-position: -288px -416px}

.flag.flag-32.flag-th {background-position: -320px -416px}

.flag.flag-32.flag-tj {background-position: -352px -416px}

.flag.flag-32.flag-tk {background-position: -384px -416px}

.flag.flag-32.flag-tl {background-position: -416px -416px}

.flag.flag-32.flag-tm {background-position: -448px -416px}

.flag.flag-32.flag-tn {background-position: -480px -416px}

.flag.flag-32.flag-to {background-position: -512px -416px}

.flag.flag-32.flag-tr {background-position: 0 -448px}

.flag.flag-32.flag-tt {background-position: -32px -448px}

.flag.flag-32.flag-tv {background-position: -64px -448px}

.flag.flag-32.flag-tw {background-position: -96px -448px}

.flag.flag-32.flag-tz {background-position: -128px -448px}

.flag.flag-32.flag-ua {background-position: -160px -448px}

.flag.flag-32.flag-ug {background-position: -192px -448px}

.flag.flag-32.flag-us {background-position: -224px -448px}

.flag.flag-32.flag-uy {background-position: -256px -448px}

.flag.flag-32.flag-uz {background-position: -288px -448px}

.flag.flag-32.flag-va {background-position: -320px -448px}

.flag.flag-32.flag-vc {background-position: -352px -448px}

.flag.flag-32.flag-ve {background-position: -384px -448px}

.flag.flag-32.flag-vg {background-position: -416px -448px}

.flag.flag-32.flag-vi {background-position: -448px -448px}

.flag.flag-32.flag-vn {background-position: -480px -448px}

.flag.flag-32.flag-vu {background-position: -512px -448px}

.flag.flag-32.flag-wf {background-position: 0 -480px}

.flag.flag-32.flag-ws {background-position: -32px -480px}

.flag.flag-32.flag-ye {background-position: -64px -480px}

.flag.flag-32.flag-za {background-position: -128px -480px}

.flag.flag-32.flag-zm {background-position: -160px -480px}

.flag.flag-32.flag-zw {background-position: -192px}
