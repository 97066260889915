/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.CompanyProfile {
    .edit {        
        color: var(--text-dark-gray-color);
        font-size: 13px;
        cursor: pointer;
        z-index: 1;

        @include desktop {
            position: absolute;
            top: -52px;
            left: 214px;
        }

        &:hover {
            color: var(--text-red-color);
        }

        @include mobile {
            text-align: end;
            margin-block-end: 15px;
        }
    }

    @include desktop {
        .table_wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            table {
                width: 48%;
            }
        }
    }

    .table_wrap table {
        font-family: 'Regular-Book', sans-serif;
        margin-block-end: 40px;

        th,td {
            font-size: 16px;
        }

        th {
            font-family: 'Regular-Bold', sans-serif ;
        }
    }
}

.CompanyProfileForm {
    form.Form.FieldForm {
        @include desktop {
            max-width: 55%;
        }

        .ExpandableContent-Heading {
            border-bottom: 1px solid #c6c6c6;
            padding-block-end: 10px;
            font-size: 22px;
            font-family: 'regular-book', sans-serif;
            font-weight: normal;
        }

        .Field_type_textlabel:not(:first-child) {
            margin-block-start: 30px;
        }
    }

    .Field label {
        font-size: 14px;
        line-height: 18px;
        color: var(--text-light-black-color);
        font-weight: 400;
        margin-block-end: 4px;
    }
}
