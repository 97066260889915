/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-no-important */
/* stylelint-disable number-max-precision */
/* stylelint-disable indentation */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --header-background: #fff;
    --header-color: #000;
    --header-icon-stroke-width: 1px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: #f26323;
    --header-height: calc(50px + env(safe-area-inset-top));
    --header-height-TopStrip: calc(135px + env(safe-area-inset-top));
    --header-nav-height: 50px;
    --header-logo-width: 106px;
    --header-logo-height: calc(var(--header-height) - 16px);

    @include desktop {
        --header-height: 40px;
        --header-nav-height: 40px;
        --header-logo-height: calc(var(--header-height) - 25px);
        --header-logo-width: 144px;
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7px;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.menuOverlayHeader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.60);
    &Inner {
        height: 100%;
        background: #272727;
        width: 92%;
        display: inline-block;
        overflow-y: auto;
        padding-bottom: 30px;
    }
    .closeBtn {
        display: flex;
        align-items: center;
        color: #fff;
        font-family: regular-book, sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        border-bottom: 1px solid #ccc;
        padding: 0;
        margin: 10px 10px 10px auto;
        .CloseIcon {
            fill: #fff;
            width: 20px;
            height: auto;
        }
    }
    .Header-Button_type_storeSwitcher {
        color: #fff;
        position: static;
        padding-left: 20px;
        padding-top: 12px;
        &Text {
            display: flex;
        }
    }
    .Header-Button_type_close {
        display: none;
    }
    .storeSwitcher {
        position: fixed;
        top: 0;
        ~ .Header-Button_type_close {
            display: block;
            position: fixed;
            top: 0;
            right: 10px;
            z-index: 99;
            .CloseIcon {
                fill: #333;
                width: 28px;
                height: auto;
            }
        }
        .storeSwitcherPopup {
            padding-top: 20px;
        }
    }
    .Overlay.Overlay_isVisible {
        border-top: 1px solid var(--text-dark-gray-color);
    }
}

.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);
    @include mobile {
        z-index: 998;
        display: flex;
        flex-direction: column;
    }
    &_isHiddenOnMobile {
        @include mobile {
            pointer-events: none;
            opacity: 0;
        }

        @at-root .hiddenHeader {
            @include mobile {
                --header-height: env(safe-area-inset-top);
            }
        }
    }

    &_name_menu {
        display: none;
    }

    .dometicLogo {
        color: #fff;
        order: -2;
        margin-left: -13px;
        height: 16px;
        svg {
            width: 120px;
        }
        @media (max-width: 1200px) {
            margin-left: 0;
        }
    }

    .topMobileMenu {
        background: #000;
        display: flex;
        height: 30px;
        padding: 0 15px;
        align-items: center;
        justify-content: center;
        a {
            color: #fff;
            font-size: 12px;
            font-family: 'Regular-Bold', sans-serif;
            display: flex;
            align-items: center;
            svg {
                margin-right: 10px;
            }
            &.shiping-link {
                text-transform: uppercase;
            }
        }
        .dometicLogo {
            margin-right: auto;
            margin-left: 0;
            height: 15px;
            svg {
                height: 14px;
            }
        }
        .login-elem {
            font-size: 12px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            svg {
                margin-right: 5px;
                top: -1px;
            }
            &.myAcct {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 1px;
                    fill: #fff;
                    height: 16px;
                }
            }
        }
    }

    .Header-MyAccount {
        &Wrapper {
            display: flex;
            align-items: center;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 700;
            span {
                color: #fff;
                padding-left: 2px;
                display: flex;
                align-items: center;
            }
            svg path {
                color: #fff;
            }
        }
    }

    .CartIcon {
        fill: #333;
        width: 32px;
        height: 32px;
        @include mobile {
            width: 26px;
            &:hover {
                fill: #333;   
            }
        }
    }

    .Header-Nav {
        .SearchField {
            @include mobile {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 40px;
                padding: 10px 15px;
                background: var(--header-background);
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
            }
        }
        .switcher-language-wrap {
            // position: absolute;
            left: 2px;
            display: flex;
            order: -1;
            height: 100%;
            align-items: center;
            grid-gap: 15px;
            margin-left: auto;
            @media (max-width: 1200px) {
                left: 17px;
            }
            &-item {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                color: #fff;
                text-transform: uppercase;
                font-family: "Helvetica Neue", arial, sans-serif;
                font-size: 11px;
                font-weight: 500;
                gap: 8px;
                grid-gap: 8px;

                a {
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    grid-gap: 8px;
                    letter-spacing: 0.2px;
                    font-size: 11px;
                    font-weight: 500;
                    height: 18px;
                    &:hover {
                        color: #ececec;
                        text-decoration: underline;
                    }
                    img {
                        width: auto;
                        height: auto;
                    }
                }
                svg {
                    font-size: 16px;
                }

                @media (max-width: 811px) {
                    display: none;
                }
            }
        }
        .switcher-language {
            font-size: 13px;
            color: #444;
            display: inline-block;
            margin-right: 15px;
            @include mobile {
                display: none;
            }
            .switcher-trigger {
                border: 2px solid #ccc;
                height: 30px;
                line-height: 26px;
                padding: 0 10px;
                min-width: 100px;
                background: #fff url(../../images/outfitter_arrows.png) no-repeat right bottom;
                padding-right: 33px;
            }
            ul {
                position: absolute;
                background: #fff;
                left: 0;
                right: -25px;
                display: none;
                li {
                    &::before {
                        display: none;
                    }
                    a,
                    button {
                        color: inherit;
                        display: block;
                        padding: 1px 10px;
                        line-height: 20px;
                        width: 100%;
                        &:hover {
                            background-color: #ddd;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            &:hover ul {
                display: block;
            }
        }
        .bnplTxt {
            a {
                text-decoration: underline;
            }
        
            &:empty {
                display: none;
            }

            &-Container {
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                align-items: center;
                gap: 7px;
            }
        
            @media (max-width: 1600px) {
                display: none;
            }
        }
        .aprUptoTxt {
            background-image: url(../../images/payment_icons/bread.svg);
            background-repeat: no-repeat;
            background-size: 18px;
            background-position: center left;
            padding: 3px 0 1px 24px;
        }
        .trusted-shops {
            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    &_isCheckout {
        @include desktop {
            .Header-LogoWrapper {
                position: static;
                max-width: 110px;
                width: 110px;
                display: flex;
                margin: 0;
                height: auto;
                order: -2;
            }
        }
        .Header-MyAccount,
        .Header-MyAccountContainer {
            margin-left: auto;
        }
        .Header-Button {
            &_type_storeSwitcher {
                display: none;
            }
            @include desktop {
                &_type_close {
                    display: none;
                }
            }
        }
        .wishlist-elem {
            display: none;
        }
        .switcher-language-wrap {
            margin-left: 23px !important;
            @include mobile {
                margin-left: auto !important;
            }
        }
    }

    .Header-Button_type_close {
        position: absolute;
        left: 5px;
        top: 3px;
        @include mobile {
            display: none;
        }
    }

    .wishlist-elem,
    .contact-elem,
    .quickorder-elem {
        color: #fff;
        text-decoration: none;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        background-image: url(../../images/top_bar_icons.png);
        background-repeat: no-repeat;
        background-position: -4px -63px;
        padding: 6px 0 6px 20px;
        margin-left: 6px;
        @include mobile {
            display: none;
        }
        .WishlistCounter {
            position: absolute;
            top: -4px;
            right: -17px;
            color: var(--header-background);
            background: var(--primary-base-color);
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-weight: bold;
            border-radius: 50%;
            text-align: center;
            z-index: 1;
        }
    }
    .contact-elem {
        background-image: none;
        padding-left: 6px;
        svg {
            font-size: 12px;
            margin-right: 3px;
        }
    }

    .top_nav {
        @include desktop {
            background: #222;
            .Header-Button_type_account::before,
            .Header-Button_type_account::after {
                border: var(--header-icon-stroke-width) solid #fff;
            }
            .Header-Button_type_close::before,
            .Header-Button_type_close::after {
                background-color: #fff;
            }
        }
    }

    &-TopMenu {
        display: none;
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            .Header-Button_type {
                &_close {
                    @media (min-width: 992px) {
                        @include button-visible;
                    }
                }

                &_menu {
                    @media (min-width: 992px) {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
            }
        }

        &_checkout {
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-Nav {
        height: var(--header-nav-height);
        display: flex;
        align-items: center;
        z-index: 20;
        margin: 0 auto;
        padding: 0 15px;
        max-width: 1170px;
        @media (min-width: 1200px) {
            max-width: 1170px;
            padding: 0 15px;
        }
        @media (min-width: 1600px) {
            max-width: 1405px;
        }

        @include mobile {
            // border-bottom: 1px solid var(--primary-divider-color);
        }
    }

    &-MyAccount {
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        @include desktop {
            position: static;
        }
        @media (max-width: 1200px) {
            right: 20px;
        }
        .UserIcon {
            fill: #fff;
            width: 20px;
        }
        svg {
            margin-right: 5px;
        }
        .Header-MyAccountTitle {
            display: none;
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;

        &_isVisible {
            @include button-visible;
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }
        @include desktop {
            &_type_back {
                display: none;
            }
        }
        &_type {
            &_menu,
            &_account,
            &_minicart {
                @include desktop {
                    @include button-visible;
                    position: absolute;
                    .Header-MinicartTitle {
                        display: none;
                    }
                }
            }

            &_minicart {
                @include button-visible;
                @include desktop {
                    right: 15px;
                    top: 58px;
                    .Header-MinicartButtonWrapper {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        z-index: 2;
                        svg {
                            font-size: 27px;
                            color: #222;
                        }
                    }
                }
                @include mobile {
                    padding: 0;
                    height: 32px;
                }
            }

            &_account {
                @include desktop {
                    position: static;
                }
            }

            &_close {
                // @include close-button;
            }

            &_back {
                // @include back-button;
            }

            &_menu {
                // @include menu-button;

                @include desktop {
                    display: none;
                }
            }

            &_account {
                // @include account-button;

                &.Header-Button_isVisible {
                    overflow: hidden;
                }
            }

            &_minicart {
                // @include minicart-button;
                &::before {
                    width: calc(18px - var(--header-icon-stroke-width) * 2);
                    height: calc(15px - var(--header-icon-stroke-width) * 2);
                }
                &::after {
                    height: 10px;
                }
            }

            &_searchClear {
                // @include clear-search-button;
                right: 10px;
                top: 6px;
            }

            &_clear {
                // @include clear-button;
            }

            &_edit {
                // @include edit-button;
                font-size: 0;
            }

            &_home {
                // @include home-icon;
            }

            &_ok,
            &_cancel {
                font-size: 14px;
                opacity: 0.5;

                &.Header-Button_isVisible {
                    width: 70px;
                }
            }
            &_storeSwitcher {
                // position: absolute;
                // right: 20px;
                padding: 0;
                width: auto;
                display: flex;
                align-items: center;
                color: #fff;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                svg.fa-globe {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    path {
                        color: #fff;
                    }
                }
                span {
                    color: inherit;
                }
                svg {
                    margin-left: 5px;
                    path {
                        @include desktop {
                            color: #fff;
                        }
                    }
                    .fa-chevron-down {
                        margin-left: 5px;
                        transition: transform 0.3s ease-in-out;
                    }
                }
                @include mobile {
                    color: #333;
                    position: absolute;
                    right: 15px;
                    svg.fa-globe {
                        path {
                            color: #333;
                        }
                    }
                }
                @include desktop {
                    z-index: 9;
                    opacity: 1;
                    pointer-events: unset;
                    margin-right: 10px;
                    margin-left: auto;
                    order: -1;
                    // display: none;
                }
                @media (min-width: 1200px) {
                    // left: 0;
                }
            }
        }
    }

    &-MinicartButton {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    &-MinicartItemCount {
        position: absolute;
        color: var(--header-background);
        background: var(--primary-base-color);
        display: inline-block;
        border-radius: 999px;
        z-index: 2;
        width: 17px;
        height: 17px;
        line-height: 17px;
        font-size: 11px;
        font-weight: normal;
        text-align: center;
        top: 2px;
        transform: translateX(3px);
        padding: 0 4px 1px;
        left: 60%;
        @include desktop {
            top: -3px;
            left: 19px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-weight: bold;
        }
    }

    &-LogoWrapper {
        position: absolute;
        width: var(--header-logo-width);
        left: 15px;
        right: 0;
        margin: auto;
        top: 14px;
        bottom: 0;
        opacity: 0;
        display: block;

        @include mobile {
            top: 0;
            left: 0;
        }

        @include desktop {
            @include logo-visible;
            right: unset;
            top: 75px;
            top: calc(var(--header-nav-height) + 8px);
            height: 0;
            z-index: 1;
            left: 15px;
            margin-top: 0;
        }

        @media (min-width: 1200px) {
            left: -1px;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;

        text-align: center;
        font-size: 1.3rem;
        color: var(--header-color);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        pointer-events: none;

        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.5rem;
        line-height: 1.5rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-transform: uppercase;

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 180px);
            }
        }
    }

    a {
        &:hover,
        &:focus {
            @include mobile {
                text-decoration: none;
            }
        }
    }

    .Header-Button_type_searchClear::before,
    .Header-Button_type_searchClear::after {
        background-color: var(--header-color);
    }
    .SearchField-Input[value]:not([value=""]) + .SearchField-Placeholder {
        display: none;
    }
    &.Header_name_cms-page {
        .Header-LogoWrapper {
            max-width: unset;
            opacity: 1;
        }
        .Header-Title {
            display: none;
        }
    }

    @include desktop {
        .Overlay.MyAccountOverlay_isVisible {
            right: 0;
            top: 40px;
            width: auto;
            overflow-y: auto;
            max-height: 75vh;
            background: #fff;
            min-width: 445px;
            box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.2);
        }
    }
    &.Header_name_checkout_account {
        .Header-Button_type_close {
            display: none;
        }
    }
}

.CartOverlay,
.CartOverlay-Additional {
    &-CartButton.Button {
        background-color: var(--btn-color);
        border-color: var(--button-border);
        color: var(--btn-text-color);
        &:hover {
            background-color: var(--button-background);
        }
    }
    &-Items {
        overflow-y: auto;
    }
    &-CheckoutButton.Button:hover {
        background-color: var(--text-red-dark-color);
    }
    &_isVisible {
        border: none;
        box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.5);
    }
    &-Promo {
        background: var(--primary-accent-color) !important;
        min-height: unset;
        color: #fff;
        padding: 10px 10px;
        margin-bottom: 0 !important;
        font-family: regular-bold, sans-serif;
        font-size: 16px;
        a {
            color: #fff;
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
    > dl {
        font-size: 18px;
        font-weight: 400;
        font-family: regular-bold, sans-serif;
        &.CartOverlay-Total {
            font-size: 20px;
            padding-top: 7px;
            text-align: right;
        }
    }
    &::before {
        border-color: var(--primary-accent-color) !important;
        background-color: var(--primary-accent-color) !important;
        top: -7px;
        right: 8px;
    }
    .CartItem {
        &-Price {
            font-family: regular-bold, sans-serif;
            color: var(--color-black);
            font-size: 18px;
            margin-bottom: 0;
        }
        &-Heading {
            font-size: 14px;
            color: var(--text-light-black-color);
            line-height: 1.1;
            margin-bottom: 8px;
            white-space: normal;
        }
        &-Picture {
            padding-bottom: 75%;
        }
        &-Content {
            padding-bottom: 50px !important;
        }
        &-Delete {
            height: 30px;
            top: -3px;
            > span {
                display: none;
            }
            &::before,
            &::after {
                border-color: var(--detete-color);
            }
            &::after {
                box-shadow: 6px 2px 0 -1px var(--detete-color),
                    -6px 2px 0 -1px var(--detete-color),
                    -2px 2px 0 -1px var(--detete-color),
                    2px 2px 0 -1px var(--detete-color);
            }
        }
        .CartItem-Qty {
            button {
                width: 20px;
                height: 20px;
                font-size: 16px;
                border: 2px solid var(--text-dark-gray-color);
                color: var(--text-dark-gray-color);
                svg.AddIcon, svg.MinusIcon {
                    width: 16px;
                    height: 16px;
                }
                &[disabled] {
                    opacity: .3;
                }
            }
            input {
                color: var(--text-dark-gray-color);
                font-size: 14px;
                min-height: 20px;
                height: 20px;
            }
        }
    }
}

.NavigationTabs-menu {
    left: 20px;
    position: absolute;
    svg {
        font-size: 24px;
        color: #333;
        * {
            color: inherit;
        }
    }
    @include desktop {
        display: none;
    }
}

@include mobile {
    .Overlay:not(.Overlay_isStatic) {
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(100% - var(--header-height));
        padding-bottom: var(--navigation-tabs-height);
        overflow: scroll;
        overflow-x: hidden;
        max-width: 100%;
    }
    .Header-Button_type_back {
        padding-left: 0;
        top: 1px;
        width: 25px;
        order: -2;
    }
    .Header-MyAccount {
        right: 0;
        .MyAccountOverlay {
            padding: 14px 25px;
        }
    }
}

.notice-row {
    // position: fixed;
    // width: 100%;
    // z-index: 9999;
    // bottom: var(--nav-tabs-height);
    // left: 0;
    // right: 0;
    @include desktop {
        position: static;
        top: 0;
        bottom: unset;
    }
    .notice-row-wrap {
        font-size: 14px;
        background-color: var(--text-red-color);
        color: #fff;
        padding: 6px 10px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 0;
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        @include desktop {
            justify-content: center;
            font-size: 16px;
            padding: 6px 15px;
            background-color: var(--text-red-color);
            .CheckoutBilling-Link {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        @include mobile {
            gap: 15px;
        }
        button.nt_btn,
        a {
            color: #fff;
            border: 1px solid #fff;
            border-radius: 4px;
            padding: 3px 12px;
            font-size: 14px;
            display: block;
            width: auto;
            margin: 0;
            line-height: 1.4;
            text-transform: uppercase;
            text-decoration: none;
            white-space: nowrap;

            @include desktop {
                width: auto;
                margin: 0 0 0 20px;
                font-size: 15px;
            }
        }
        label.CheckoutBilling-Link {
            @include mobile {
                display: flex;
                align-items: center;
                font-size: 14px;
                a {
                    margin-left: 12px;
                }
            }
        }
        button.nt_btn {
            .CloseIcon {
                display: none;
            }
            @include mobile {
                width: auto;
                margin-left: auto;
                font-size: 0;
                padding: 0;
                .CloseIcon {
                    fill: #fff;
                    display: block;
                }
            }
        }
    }
}

.vehicleGarage {
    position: absolute;
    right: 70px;
    top: 61px;
    cursor: pointer;
    .activeVeh {
        pointer-events: none;
    }
    svg {
        font-size: 28px;
        color: #222;
    }
    &Pop {
        position: absolute;
        top: 48px;
        right: 0;
        width: 421px;
        min-height: 100px;
        background: #fff;
        box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.5);
        &Title {
            background: var(--primary-accent-color);
            color: #fff;
            padding: 10px 10px;
            font-family: regular-bold, sans-serif;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }
        &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid var(--primary-accent-color);
            position: absolute;
            right: 6px;
            top: -11px;
        }
    }
    @media (max-width: 1200px) {
        display: none;
    }
}

.vehicle ~ .Menu-MenuWrapper {
    .showPro {
        display: none;
    }
}
