/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.Header {
    &_name {
        &_storeSwitcher {
            .Header-Button_type_storeSwitcher {
                .fa-chevron-down {
                    transform: rotate(180deg);
                }
            }

            button.Header-Button_type_close {
                @include desktop {
                    z-index: 999;
                    inset-block-start: 52px;
                    left: unset;
                    right: 16px;
                    top: 52px;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    padding: 0;

                    &::before,
                    &::after {
                        inset-block-start: 5px;
                    }

                    .CloseIcon {
                        fill: #000;
                        width: 30px;
                        height: 30px;
                    }
                }

                @include mobile {
                    right: unset;
                }
            }

            .storeSwitcher.Overlay_isInstant {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &-Button_type_storeSwitcher {
        img {
            margin-right: 5px;
            width: 26px;
            height: 26px;
        }
    }
}

.Header-Button {
    &_isVisible {
        @include desktop {
            opacity: 1;
            padding: 0 !important;
            width: auto !important;
            pointer-events: all;
            overflow: visible;
        }
    }

    &_type {
        .activeCountry {
            background-color: red;
            color: #fff;
        }

        &_menu {
            @include desktop {
                width: 100px;
                display: flex;
                align-items: center;
            }
        }

        &_storeSwitcherText {
            white-space: wrap;
            display: inline-block;
            display: flex;
            align-items: center;
            width: auto !important;
            opacity: 1 !important;

            @include mobile {
                display: none;
            }
        }
    }
}

.skiptranslate:not(.goog-te-gadget) {
    display: none!important
}

.skiptranslate .goog-logo-link {
    display: none;
}

.skiptranslate.goog-te-gadget {
    font-size: 0
}

#google_translate_element .goog-te-gadget .goog-logo-link {
    display: none
}

select.goog-te-combo {
    font-size: 13px;
    color: #000;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px;
    height: 32px;
    width: 100%;
    max-width: 270px;
}

.storeSwitcher {
    position: absolute;
    inset-block-start: 40px;
    right: 0;
    z-index: 99;
    display: flex;

    &Overlay {
        position: fixed;
        inset: 0;
        z-index: 1;

        @include mobile {
            display: none;
        }
    }

    @include mobile {
        left: 0;
        top: 50px;
    }

    &#storeSwitcher {
        .switcher-language {
            display: flex;
            flex-wrap: wrap;

            .switcher-trigger {
                background: #e9e9e9;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                font-size: 16px;
                text-transform: uppercase;
                color: #313336;
                font-family: regular-bold, sans-serif;
                padding: 2px 20px;
                min-width: unset;
                height: auto;
                margin: 0 5px 5px 0;
            }

            &-wrap {
                position: static;
            }

            ul {
                display: contents;
                position: static;

                li {
                    margin: 0 5px 5px 0;

                    &:last-child {
                        margin-inline-end: 0;
                    }

                    a {
                        border: 1px solid #eee;
                        border-radius: 4px;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #686A6B;
                        font-family: regular-bold, sans-serif;
                        padding: 2px 20px;
                        line-height: 26px;
                        display: block;
                    }
                }
            }
        }
    }

    &Text {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: regular-book, sans-serif;
        text-transform: uppercase;
        font-size: 16px;

        img {
            width: auto;
            margin: 0 5px;
            height: 22px;
        }

        svg {
            margin: 0 5px;
        }

        &-NoShipping {
            background-color: #f8d7da;
            padding: 0.75rem 1.25rem;
            margin-block-end: 1rem;
            border: 1px solid transparent;
            border-color: #f5c6cb;
            border-radius: 0.25rem;
        }
    }

    h3 {
        font-size: 16px;
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        margin: 10px 0 4px 0;
        padding: 0;
    }

    &Lang {
        margin-block-end: 20px;
        padding-block-end: 15px;
        border-bottom: 1px solid #D9D9D9;
    }

    &Country {
        margin-block-end: 10px;

        .CountSelect {
            span.css-1f43avz-a11yText-A11yText {
                display: none;
            }

            * {
                position: static;
            }

            span.css-7pg0cj-a11yText {
                display: none;
            }
        }
    }

    @include desktop {
        &::before {
            content: "";
            position: fixed;
            inset: 0;
            background-color: rgba(35,35,35,.7);
            z-index: 1;
            display: none;
        }
    }

    .storeSwitcherPopup {
        background: #fff url(../../images/region-map.png) top center no-repeat;
        background-size: contain;
        color: #2C2C2C;
        overflow-y: auto;
        z-index: 2;
        padding: 0 24px 25px;
        width: 540px;
        border-radius: 5px;
        box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.5);

        .CountrySelector-Link {
            cursor: pointer;
        }

        @include desktop {
            // position: fixed;
            // top: 20px;
            // bottom: unset;
            // max-width: 500px;
            // left: 50%;
            // transform: translateX(-50%);
        }

        @media (min-width: 1600px) {
            // max-width: 1430px;
        }

        @media (max-width: 1199px) {
            // max-width: 1000px;
        }

        @media (max-width: 1024px) {
            // max-width: 96%;
        }

        @include mobile {
            max-width: 100%;
            inset-inline-start: 0;
            width: 100%;
            padding: 0 18px 20px;
            border-radius: 0;
        }

        h2 {
            font-size: 18px;
            font-family: "Intro", Arial, sans-serif;
            margin: 16px 0 12px;
        }

        .continents {
            display: flex;
            flex-wrap: wrap;
            margin-block-start: 25px;

            &>div {
                flex: 0 0 46%;
                margin: 0 2%;
                font-family: "Intro", Arial, sans-serif;
                font-size: 16px;
                padding: 7px 12px;
                text-align: center;
                border-radius: 4px;
                cursor: pointer;
            }
        }

        .language-country-main {
            // height: calc(100vh - 390px);
            height: calc(100vh - 310px);
            overflow-y: auto;
            margin-block: 8px;
            padding: 0 8px;

            &>div>div {
                padding: 4px 0 2px 9px;
            }

            a {
                color: var(--text-dark-gray-color);
            }

            .countries {
                margin-block-start: 8px;
            }
        }

        .language-popup-footer {
            border-top: 1px #CCC solid;
            padding: 10px 30px;
            background-color: #e9e9e9;
            text-align: center;
            height: 95px;

            @include mobile {
                padding-block-start: 8px;
                height: 90px;
            }

            .language-popup-region {
                font-size: 13px;
                margin-block-start: 10px;
                color: #444;
                padding-block-end: 2px;
            }
        }

        @include desktop {
            .store-switcher-wrap {
                display: flex;

                .continents {
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding: 0 15px;
                    display: block;
                    height: calc(100vh - 190px);
                    max-height: 450px;
                    overflow-y: auto;

                    > div {
                        flex: 0 0 100%;
                        align-items: center;
                        display: flex;
                        padding: 12px;
                    }
                }

                .language-country-main {
                    flex: 0 0 75%;
                    max-width: 75%;
                    padding: 0 15px;
                    height: calc(100vh - 190px);
                    max-height: 450px;
                    margin-block-end: 0;

                    .countries, .pinnedCountries {
                        display: flex;
                        flex-wrap: wrap;

                        >div {
                            width: 50%;
                            border-left: 1px solid lightgrey;
                            padding-left: 12px;

                            @media (min-width: 992px) {
                                width: 33%;
                            }
                        }
                    }
                }
            }
        }
    }
}
